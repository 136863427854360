import { t } from '@lingui/macro';
import { keyBy } from 'lodash-es';

import { Menu, PictureClassification } from '@/api/gateway-click-collect/restaurants/types';

import { sortPictureClassificationsByWidth } from '../menu.helpers';

const getCategoryPictureFromItems = (
  menu: Menu,
  menuItemUuids: string[],
  excludedItems: Set<string>
): { menuItemUuid: string; picture: PictureClassification } | null => {
  const categoryItemsPictures = menuItemUuids
    .map((menuItemUuid) => {
      const pictures = menu?.menuItemPicturesUrls?.[menuItemUuid] ?? [];

      const validPicture = sortPictureClassificationsByWidth(pictures).find(({ width }) => width === 768);

      return {
        menuItemUuid,
        picture: validPicture,
      };
    })
    .filter(
      (categoryItemPicture): categoryItemPicture is { menuItemUuid: string; picture: PictureClassification } =>
        !excludedItems.has(categoryItemPicture.menuItemUuid) && !!categoryItemPicture.picture
    );

  return categoryItemsPictures.length ? categoryItemsPictures[0] : null;
};

export const getCategoriesWithMenuItemPictures = (outOfStocksMenuElementUuids: (string | undefined)[], menu: Menu) => {
  const excludedItems = new Set<string>([]);
  const itemsMapByMenuElementUuids = keyBy(menu?.items, 'menuElementUuid');

  return (
    menu?.categories
      ?.filter(
        ({ menuElementUuids }) =>
          !!menuElementUuids?.some((menuElementUuid) => !outOfStocksMenuElementUuids.includes(menuElementUuid))
      )
      .map((currentCategory) => {
        const { menuElementUuids = [] } = currentCategory;
        const categoryMenuItemUuids = menuElementUuids
          .map((menuElementUuid) => itemsMapByMenuElementUuids[menuElementUuid]?.menuItemUuid)
          .filter(Boolean);

        const categoryPictureInfo = getCategoryPictureFromItems(menu, categoryMenuItemUuids, excludedItems);

        if (categoryPictureInfo) {
          excludedItems.add(categoryPictureInfo.menuItemUuid);
        }

        return {
          ...currentCategory,
          picture: {
            url: categoryPictureInfo?.picture?.url ?? t`Unknown`,
            width: 340,
          },
        };
      }) ?? []
  );
};

export const getResponsivePictureClassification = ({
  windowWidth,
  pictureClassifications,
}: {
  windowWidth: number;
  pictureClassifications?: PictureClassification[];
}) => {
  if (!pictureClassifications?.length) return { width: 0, url: t`Unknown` };

  const currentLargestImage = sortPictureClassificationsByWidth(pictureClassifications)[0];

  const sortedLargerThanScreenImages = sortPictureClassificationsByWidth(
    pictureClassifications.filter(({ width }) => width >= windowWidth)
  );

  return sortedLargerThanScreenImages.length
    ? sortedLargerThanScreenImages[sortedLargerThanScreenImages.length - 1]
    : currentLargestImage;
};
