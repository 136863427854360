export const launchIntercom = () => {
  let intercomLauncher = document?.querySelector('div.intercom-launcher') as HTMLElement | null;

  if (!intercomLauncher) {
    intercomLauncher = (
      document.querySelector('iframe.intercom-launcher-frame') as HTMLIFrameElement | null
    )?.contentWindow?.document?.querySelector('div.intercom-launcher') as HTMLElement | null;
  }

  if (intercomLauncher) {
    intercomLauncher.click();
  }
};
