import { Cart as CartV2 } from '@/api/gateway-click-collect/orders';
import { CartModifierGroupRequest, CartRequest } from '@/api/types';
import type { Cart, ItemCart, ModifierGroupCart } from '@/types';

export const formatCartModifierGroup = (modifierGroup: ModifierGroupCart, item: ItemCart): CartModifierGroupRequest => {
  const modifiers = modifierGroup?.selectedModifiers?.map((modifier) => {
    return {
      menu_item_uuid: modifier.menuItemUuid,
      quantity: modifier.quantity,
      modifier_uuid: modifier.modifierUuid,
      modifier_groups: modifier.selectedModifierGroups
        ? modifier.selectedModifierGroups.map((mg) => formatCartModifierGroup(mg, item))
        : [],
    };
  });

  return {
    modifier_group_uuid: modifierGroup.modifierGroupUuid,
    menu_item_uuid: item.menuItemUuid,
    modifiers: modifiers ?? [],
  };
};

export const formatCart = (cart: Cart): CartRequest => {
  const items = cart.items.map((item) => {
    return {
      menu_item_uuid: item.menuItemUuid,
      menu_element_uuid: item.menuElementUuid,
      quantity: item.quantity,
      customer_instructions: '',
      modifier_groups: item?.selectedModifierGroups?.map((mg) => formatCartModifierGroup(mg, item)) ?? [],
    };
  });
  return {
    restaurant_platform_id: cart.restaurantPlatformId,
    items,
    coupon_code: cart.couponCode,
    cutlery: true,
    customer_instructions: '',
    pickup_time: cart.pickupTime ?? undefined,
  };
};

export const formatCartForValidation = (cart: Cart, terminalUuid?: string): CartV2 => {
  const { restaurantPlatformId, items, pickupTime, couponCode } = cart;

  return {
    terminalUuid,
    restaurantPlatformId,
    couponCode,
    items: items.map(({ menuItemUuid, quantity, selectedModifierGroups = [] }) => {
      return {
        menuItemUuid,
        modifierGroups: selectedModifierGroups
          .filter(({ selectedModifiers }) => !!selectedModifiers?.length)
          .map(({ modifierGroupUuid, selectedModifiers = [] }) => {
            return {
              modifierGroupUuid,
              modifiers: selectedModifiers.map(
                ({ menuItemUuid, modifierUuid, quantity, selectedModifierGroups = [] }) => {
                  return {
                    menuItemUuid,
                    modifierUuid,
                    quantity,
                    modifierGroups: selectedModifierGroups
                      .filter(({ selectedModifiers }) => !!selectedModifiers?.length)
                      .map(({ modifierGroupUuid, selectedModifiers = [] }) => {
                        return {
                          modifierGroupUuid,
                          modifiers: selectedModifiers.map(({ menuItemUuid, modifierUuid, quantity }) => {
                            return {
                              menuItemUuid,
                              modifierUuid,
                              quantity,
                            };
                          }),
                        };
                      }),
                  };
                }
              ),
            };
          }),
        quantity,
      };
    }),
    pickupTime: pickupTime ? new Date(pickupTime) : undefined,
  };
};

export const getCartPrice = (items: ItemCart[] = []) => {
  return items.reduce((cartPriceAcc, item) => {
    const modifiersPrice = (item.selectedModifierGroups ?? [])
      .flatMap(({ selectedModifiers }) => selectedModifiers)
      .reduce((modifiersPriceAcc, modifier) => {
        if (modifier) {
          const subModifiersPrice = (modifier.selectedModifierGroups ?? [])
            .flatMap(({ selectedModifiers }) => selectedModifiers)
            .reduce((subModifiersPriceAcc, subModifier) => {
              if (subModifier) {
                const subModifierPrice = subModifier.quantity * subModifier.sellingPrice;

                return subModifiersPriceAcc + subModifierPrice;
              }

              return subModifiersPriceAcc;
            }, 0);
          const modifierPrice = modifier.quantity * (modifier.sellingPrice + subModifiersPrice);

          return modifiersPriceAcc + modifierPrice;
        }

        return modifiersPriceAcc;
      }, 0);
    const itemPrice = item.quantity * (item.sellingPrice + modifiersPrice);

    return cartPriceAcc + itemPrice;
  }, 0);
};
