import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import ApiError from '@/api/ApiError';

import { getOrder } from './api';
import { queryKey } from './queryKeyFactory';
import { Order } from './types';

interface HookProps<TData> {
  orderUuid?: string;
  options?: Omit<
    UseQueryOptions<Order, ApiError, TData, ReturnType<(typeof queryKey)['order']>>,
    'queryFn' | 'queryKey'
  >;
}

export const useOrderQuery = <TData = Order>({ orderUuid, options }: HookProps<TData>) =>
  useQuery(
    queryKey.order(orderUuid),
    () => (orderUuid ? getOrder(orderUuid) : Promise.reject(new Error('Invalid query params'))),
    options
  );
