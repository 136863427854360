class ApiError extends Error {
  readonly body: any;
  readonly detail: string;
  readonly reason: string;
  readonly status: number;
  readonly title: string;
  readonly type: string;

  constructor({
    body,
    detail,
    reason,
    status,
    title,
    type,
  }: {
    body: any;
    detail: string;
    reason: string;
    status: number;
    title: string;
    type: string;
  }) {
    const message = type != 'about:blank' ? type : detail;

    super(message);

    this.body = body;
    this.detail = detail;
    this.name = title;
    this.reason = reason;
    this.status = status;
    this.title = title;
    this.type = type;
  }
}

export default ApiError;
