import { Button, Image, Stack, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { ReactNode } from 'react';
import { Link, To } from 'react-router-dom';

import NotFound from '@/assets/anim/not-found.json';
import TasterLogo from '@/assets/img/taster-logo-black.svg';
import { LottieAnimation } from '@/components/LottieAnimation';

export interface NotFoundPageProps {
  to?: To;
  text?: ReactNode;
}

const NotFoundPage = ({ to = '/', text }: NotFoundPageProps) => {
  return (
    <Stack height="100%" alignItems="center" gap={8} padding={4}>
      <LottieAnimation animation={NotFound} sx={{ maxWidth: 500, width: '100%', flexGrow: 2 }} />
      <Stack alignItems="center" gap={8} sx={{ flex: 2 }}>
        <Text textAlign="center" fontSize={{ base: 'xl', lg: '3xl' }} fontWeight={700}>
          {text ?? <Trans>Sorry, we can’t seem to find the page you’re looking for.</Trans>}
        </Text>
        <Button as={Link} colorScheme="black" to={to} textTransform="uppercase" size="lg">
          <Trans>Back to home</Trans>
        </Button>
      </Stack>
      <Image src={TasterLogo} width="400px" sx={{ flex: 1 }} />
    </Stack>
  );
};

export default NotFoundPage;
