import { cloneDeep } from 'lodash-es';
import { useRef } from 'react';
import { Outlet, useOutletContext, useParams } from 'react-router';

import { ConceptInformation } from '@/api/gateway-click-collect/configurations';
import { Item } from '@/api/gateway-click-collect/restaurants/types';
import { KioskOutOfStockContent } from '@/components/ItemOutOfStockContent';
import { useMenuDataContext } from '@/contexts/MenuDataProvider';
import { getAvailableItems, getMenuPicturesMap } from '@/helpers/menu.helpers';

export interface AddItemDataLayoutContext {
  conceptInformation: ConceptInformation;
  currentItem: Item;
  currencyCode: string;
  pictures: Record<string, string | undefined>;
  restaurantLabel: string;
  restaurantAddress: string;
  kitchenLabel: string;
  kitchenInstructions: string;
}

export const useItemKioskDataLayout = () => useOutletContext<AddItemDataLayoutContext>();

export const AddItemDataLayout = () => {
  const {
    menu,
    outOfStocks,
    conceptInformation,
    restaurant: {
      currencyCode,
      restaurantPlatformId,
      restaurantLabel,
      address: restaurantAddress,
      kitchenLabel,
      kitchenInstructions,
    },
  } = useMenuDataContext();

  const { menuElementUuid } = useParams();

  const getCurrentAvailableItem = () => {
    const searchedItem = menu?.items?.find((item) => item.menuElementUuid === menuElementUuid);

    if (!searchedItem) return;

    return getAvailableItems([searchedItem], outOfStocks)[0];
  };

  const { current: currentItem } = useRef(cloneDeep(getCurrentAvailableItem()));

  if (!currentItem) {
    return <KioskOutOfStockContent redirectUrl={`/onSite/menu/${restaurantPlatformId}`} />;
  }

  const pictures = getMenuPicturesMap(menu);

  return (
    <Outlet
      context={
        {
          conceptInformation,
          currencyCode,
          currentItem,
          pictures,
          restaurantLabel,
          kitchenLabel,
          kitchenInstructions,
          restaurantAddress,
        } satisfies AddItemDataLayoutContext
      }
    />
  );
};
