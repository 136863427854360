import { Divider, Modal, ModalContent, ModalOverlay, Stack } from '@chakra-ui/react';
import { useRef, useState } from 'react';

import { CartItemSubModifierGroup } from '@/api/gateway-click-collect/orders/types';
import { SubModifierGroup } from '@/api/gateway-click-collect/restaurants/types';

import { AddSubModifierGroup } from '../AddSubModifierGroup';
import { ModifierGroupModalHeader } from './ModifierGroupModalHeader';

export interface ModifierGroupModalProps {
  modifierLabel: string;
  modifierGroups: SubModifierGroup[];
  isOpen: boolean;
  onClose: () => void;
  onSubModifierSelection: (subModifiersSelection: CartItemSubModifierGroup[]) => void;
  modifierPicture: string;
  currencyCode: string;
  pictures: Record<string, string | undefined>;
}

export const ModifierGroupModal = ({
  isOpen,
  modifierLabel,
  currencyCode,
  pictures,
  modifierGroups,
  modifierPicture,
  onClose,
  onSubModifierSelection,
}: ModifierGroupModalProps) => {
  const [activeModifierGroupIndex, setActiveModifierGroupIndex] = useState(0);

  const initialModifierGroups = useRef(modifierGroups);

  const isOnLastModifierGroup = activeModifierGroupIndex === initialModifierGroups.current.length - 1;

  const draftCartItemSubModifierGroupsSelection = useRef<CartItemSubModifierGroup[]>([]);

  const currentModifierGroup = initialModifierGroups.current[activeModifierGroupIndex];

  const handleClose = () => {
    setActiveModifierGroupIndex(0);
    draftCartItemSubModifierGroupsSelection.current = [];
    onClose();
  };

  const handleItemSelect = (newCartItemSubModifierGroup?: CartItemSubModifierGroup) => {
    if (newCartItemSubModifierGroup) {
      draftCartItemSubModifierGroupsSelection.current = [
        ...draftCartItemSubModifierGroupsSelection.current,
        newCartItemSubModifierGroup,
      ];
    }

    if (isOnLastModifierGroup) {
      onSubModifierSelection(draftCartItemSubModifierGroupsSelection.current);
      handleClose();
    } else {
      setActiveModifierGroupIndex(activeModifierGroupIndex + 1);
    }
  };

  const handleBackButtonClick = () => {
    if (activeModifierGroupIndex) {
      setActiveModifierGroupIndex(activeModifierGroupIndex - 1);

      const activeModifierGroupUuid = initialModifierGroups.current[activeModifierGroupIndex - 1].modifierGroupUuid;

      draftCartItemSubModifierGroupsSelection.current = draftCartItemSubModifierGroupsSelection.current.filter(
        ({ modifierGroupUuid }) => modifierGroupUuid !== activeModifierGroupUuid
      );
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size={{ kiosk: '2xl', '2xl': '5xl' }}>
      <ModalOverlay />
      <ModalContent px={0} pt={2} overflowY="auto" sx={{ maxH: '85vh' }}>
        <Stack>
          <ModifierGroupModalHeader
            modifierGroupLabel={modifierLabel}
            modifierGroupPicture={modifierPicture}
            onClose={handleClose}
          />
          <Divider mt={0} />
          <AddSubModifierGroup
            key={currentModifierGroup.modifierGroupUuid}
            onSkip={handleItemSelect}
            onSelect={handleItemSelect}
            currencyCode={currencyCode}
            pictures={pictures}
            modifierGroup={currentModifierGroup}
            hasBackButton={!!activeModifierGroupIndex}
            onBackButtonClick={handleBackButtonClick}
          />
        </Stack>
      </ModalContent>
    </Modal>
  );
};
