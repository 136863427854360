import { CloseIcon } from '@chakra-ui/icons';
import { IconButton, Image, Stack, Text } from '@chakra-ui/react';
import { t } from '@lingui/macro';

export interface ModifierGroupModalHeaderProps {
  modifierGroupLabel: string;
  modifierGroupPicture: string;
  onClose: () => void;
}

export const ModifierGroupModalHeader = ({
  onClose,
  modifierGroupLabel,
  modifierGroupPicture,
}: ModifierGroupModalHeaderProps) => {
  return (
    <Stack gap={4}>
      <Stack flexDirection="row" flexWrap="nowrap" gap={2} justifyContent="space-between" alignItems="center" px={3}>
        <Stack flexDirection="row" flexWrap="nowrap" gap={2} alignItems="center">
          <Image borderRadius="16px" src={modifierGroupPicture} alt={modifierGroupLabel} width={185} height={104} />
          <Text fontSize={32} fontWeight={700}>
            {modifierGroupLabel}
          </Text>
        </Stack>
        <IconButton
          aria-label={t`Close`}
          color="gray.700"
          textTransform="uppercase"
          variant="outlines"
          height="104px"
          width="104px"
          border="1px solid"
          borderColor="gray.border"
          padding={4}
          boxShadow="0px 1px 2px 0px #1018280D;"
          icon={<CloseIcon />}
          fontSize={{ base: 16, kiosk: 28 }}
          onClick={onClose}
        />
      </Stack>
    </Stack>
  );
};
