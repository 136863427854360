import { Stack } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

export const RestaurantCardOverlay = ({ children, isOpen = false }: PropsWithChildren<{ isOpen?: boolean }>) => {
  return (
    <Stack
      alignItems="center"
      background={
        isOpen ? 'linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%)' : 'rgba(0, 0, 0, 0.6)'
      }
      bottom="0"
      color="white"
      display="flex"
      justifyContent="center"
      left="0"
      pos="absolute"
      px={2}
      py={1}
      right="0"
      top="0"
    >
      {children}
    </Stack>
  );
};
