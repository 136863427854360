import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import ApiError from '@/api/ApiError';
import { useTasterApis } from '@/api/TasterApiContext';

import { restaurantsKeys } from './restaurants.helpers';
import { ListRestaurantsRequest, Restaurant } from './types';

export const useRestaurantsQueryOptions = {
  queryKey: (requestParams?: ListRestaurantsRequest) => [...restaurantsKeys.all, 'list', requestParams],
};

export interface UseRestaurantsQueryProps<TData> {
  options?: Omit<
    UseQueryOptions<Restaurant[], ApiError, TData, ReturnType<typeof useRestaurantsQueryOptions.queryKey>>,
    'queryFn' | 'queryKey'
  >;
  requestParams: ListRestaurantsRequest;
}

export const useRestaurantsQuery = <TData = Restaurant[]>({
  options,
  requestParams,
}: UseRestaurantsQueryProps<TData>) => {
  const {
    gatewayClickCollect: { restaurantsApi },
  } = useTasterApis();

  return useQuery({
    ...options,
    queryFn: () => restaurantsApi.listRestaurants(requestParams),
    queryKey: useRestaurantsQueryOptions.queryKey(requestParams),
  });
};
