import { Tag, TagLabel } from '@chakra-ui/react';

import useFormatPrice from '@/hooks/useFormatPrice';

export interface MenuItemPriceChipProps {
  sellingPrice: number;
  currencyCode: string;
  priceOptions?: Intl.NumberFormatOptions;
}

export const MenuItemPriceChip = ({ sellingPrice, currencyCode, priceOptions }: MenuItemPriceChipProps) => {
  const { formatPrice } = useFormatPrice();

  return (
    <Tag
      sx={{
        border: '3px solid',
        borderColor: 'gray.border',
        color: 'gray.700',
        background: 'white',
        borderRadius: 76,
        padding: '4px 12px',
      }}
    >
      <TagLabel fontWeight={700} fontSize={{ base: '24px' }}>
        {formatPrice(sellingPrice, currencyCode, priceOptions)}
      </TagLabel>
    </Tag>
  );
};
