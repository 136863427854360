import { keyBy } from 'lodash-es';

import { Item, Menu, PictureClassification } from '@/api/gateway-click-collect/restaurants';
import { getAvailableItems } from '@/helpers/menu.helpers';

export const getAvailableMenuElements = (items: Item[] = [], outOfStocks: Set<string>) => {
  const availableMenuElements = getAvailableItems(items, outOfStocks).map(({ menuElementUuid }) => menuElementUuid);

  return new Set(availableMenuElements);
};

const getCategoryPicture = (
  categoryItems: Item[] = [],
  menuItemPicturesUrls: {
    [key: string]: PictureClassification[] | undefined;
  },
  excludedItems: Set<string>
): { menuItemUuid: string; picture: string } | undefined => {
  const itemsWithPictures = categoryItems
    .map(({ menuItemUuid }) => {
      const pictures = menuItemPicturesUrls?.[menuItemUuid] ?? [];

      const validPicture = [...pictures]
        .sort((imageA, imageB) => (imageA.width > imageB.width ? 1 : -1))
        .find(({ width }) => width >= 768);

      return {
        menuItemUuid,
        picture: validPicture?.url,
      };
    })
    .filter(
      (categoryItemPicture): categoryItemPicture is { menuItemUuid: string; picture: string } =>
        !!categoryItemPicture.picture
    );

  if (itemsWithPictures.length > 1) {
    return itemsWithPictures.find(({ menuItemUuid }) => !excludedItems.has(menuItemUuid));
  }

  return itemsWithPictures[0];
};

export const formatCategories = (menu: Menu, outOfStocks: Set<string>) => {
  const { categories, items, menuItemPicturesUrls } = menu;
  const availableMenuElements = getAvailableMenuElements(items, outOfStocks);
  const itemsMap = keyBy(items, 'menuElementUuid');
  const excludedItems = new Set<string>();

  return categories
    ?.map((category) => {
      const { menuElementUuids } = category;
      const availableMenuElementUuids = menuElementUuids?.filter((menuElementUuid) =>
        availableMenuElements.has(menuElementUuid)
      );
      const categoryItems = menuElementUuids?.map((menuElementUuid) => itemsMap[menuElementUuid]);
      const categoryPicture = getCategoryPicture(categoryItems, menuItemPicturesUrls, excludedItems);

      if (categoryPicture) {
        excludedItems.add(categoryPicture.menuItemUuid);
      }

      return { ...category, menuElementUuids: availableMenuElementUuids, picture: categoryPicture?.picture };
    })
    .filter(({ menuElementUuids }) => !!menuElementUuids?.length);
};
