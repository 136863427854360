import { keyBy } from 'lodash-es';

import { MenuElement } from '@/api/types';
import { ItemCart, ModifierGroupCart } from '@/types';

export const isCartItemModifierGroupValid = (cartItemModifierGroup: ModifierGroupCart): boolean => {
  const { included, max, selectedModifiers = [] } = cartItemModifierGroup;

  const selectedModifiersQuantity = selectedModifiers.reduce((quantityAcc, selectedModifier) => {
    return quantityAcc + selectedModifier.quantity;
  }, 0);
  const isQuantityValid = selectedModifiersQuantity >= included && selectedModifiersQuantity <= max;

  const hasValidSubModifierGroups = selectedModifiers.every((selectedModifier) => {
    const { selectedModifierGroups = [] } = selectedModifier;

    return selectedModifierGroups.every(isCartItemModifierGroupValid);
  });

  return isQuantityValid && hasValidSubModifierGroups;
};

export const isCartItemValid = (cartItem: ItemCart, item: MenuElement) => {
  const { selectedModifierGroups = [] } = cartItem;
  const selectedModifierGroupsByUuid = keyBy(selectedModifierGroups, 'modifierGroupUuid');
  const hasMandatoryModifierGroups = item.modifierGroups
    .filter((modifierGroup) => modifierGroup.included > 0)
    .every(({ modifierGroupUuid }) => {
      return !!selectedModifierGroupsByUuid[modifierGroupUuid];
    });

  return hasMandatoryModifierGroups && selectedModifierGroups.every(isCartItemModifierGroupValid);
};
