import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { useTasterApis } from '@/api/TasterApiContext';

import { configurations } from './configurations.helpers';
import { ConceptsConfiguration, GetConceptsConfigurationRequest } from './types';

export interface ConceptsConfigurationQueryProps<TData> {
  requestParams?: GetConceptsConfigurationRequest;
  options?: Omit<
    UseQueryOptions<
      ConceptsConfiguration,
      Error,
      TData,
      ReturnType<(typeof configurations)['getConceptsConfiguration']>
    >,
    'queryFn' | 'queryKey'
  >;
}

export const useConceptsConfigurationQuery = <TData = ConceptsConfiguration>({
  requestParams,
  options = {},
}: ConceptsConfigurationQueryProps<TData>) => {
  const {
    gatewayClickCollect: { configurationsApi },
  } = useTasterApis();

  return useQuery(
    configurations.getConceptsConfiguration(requestParams),
    () => configurationsApi.getConceptsConfiguration(requestParams),
    options
  );
};
