import { List, ListItem } from '@chakra-ui/react';

import { MenuElement } from '@/api/types';
import { MenuCategory } from '@/routes/MenuPage/MenuCategory';
import { Menu } from '@/routes/MenuPage/types';

export interface CategoriesListProps {
  currencyCode: string;
  menu: Menu;
  onItemClick: (item: MenuElement) => void;
}

export const CategoriesList = ({ currencyCode, menu, onItemClick }: CategoriesListProps) => {
  const { categories } = menu;

  if (categories?.length) {
    return (
      <List>
        {categories.map((category) => (
          <ListItem mb={6} key={category.uuid}>
            <MenuCategory category={category} currencyCode={currencyCode} onItemClick={onItemClick} />
          </ListItem>
        ))}
      </List>
    );
  }

  return null;
};
