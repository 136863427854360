import { Stack, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import helpAnimation from '@/assets/anim/lottie_help.json';

import { LottieAnimation } from '../LottieAnimation';

export const PairingTerminalDisconnectedContent = () => {
  return (
    <Stack gap={4} alignItems="center" textAlign="center" px={2}>
      <LottieAnimation animation={helpAnimation} sx={{ width: 350 }} />
      <Text fontWeight={700} fontSize={32}>
        <Trans>{"Oh no! The card machine isn't connected"}</Trans>
      </Text>
      <Text fontSize={32}>
        <Trans>Ask a staff member for help to pair the card machine.</Trans>
      </Text>
    </Stack>
  );
};
