import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import camelize from 'camelize-ts';

import ApiError from '@/api/ApiError';
import { useTasterApis } from '@/api/TasterApiContext';

import { ordersQueryKeys } from './orders.helpers';
import { ResponseError, ValidateOrderRequest, ValidOrder } from './types';

export const useValidateOrderQueryOptions = {
  queryKey: (requestParams?: ValidateOrderRequest) => [...ordersQueryKeys.all, 'validate', requestParams] as const,
};

export interface UseValidateOrderQueryProps<TData = ValidOrder> {
  options?: Omit<
    UseQueryOptions<ValidOrder, ApiError, TData, ReturnType<typeof useValidateOrderQueryOptions.queryKey>>,
    'queryFn' | 'queryKey' | 'cacheTime' | 'staleTime'
  >;
  requestParams?: ValidateOrderRequest;
}

export const useValidateOrderQuery = <TData = ValidOrder>({
  options,
  requestParams,
}: UseValidateOrderQueryProps<TData>) => {
  const {
    gatewayClickCollect: { ordersApi },
  } = useTasterApis();

  return useQuery({
    queryKey: useValidateOrderQueryOptions.queryKey(requestParams),
    queryFn: async () => {
      if (!requestParams) {
        return Promise.reject('Invalid query params');
      }

      try {
        return await ordersApi.validateOrder(requestParams);
      } catch (error) {
        if (error instanceof ResponseError) {
          const errorResponse = (await error.response.json()) as ApiError;
          throw new ApiError(camelize(errorResponse));
        }

        throw error;
      }
    },
    cacheTime: 0,
    staleTime: 0,
    ...options,
  });
};
