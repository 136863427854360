import { t } from '@lingui/macro';

import { CouponInfoErrorTypeEnum } from '@/api/customer-order/orders';
import { CouponError } from '@/api/types';
import useFormatPrice from '@/hooks/useFormatPrice';

interface UseCouponErrorMessageParams {
  currencyCode: string;
  couponError?: CouponError;
}

export const useCouponErrorMessage = ({ currencyCode, couponError }: UseCouponErrorMessageParams) => {
  const { formatPrice } = useFormatPrice();

  if (!couponError) return;

  if (couponError.missingAmount && couponError.errorType === CouponInfoErrorTypeEnum.BELOW_ACTIVATION_PRICE) {
    const missingAmount = formatPrice(couponError.missingAmount, currencyCode);
    return t`Add ${missingAmount} to your cart to use this promo code.`;
  }

  if (couponError.errorType === CouponInfoErrorTypeEnum.INVALID_COUPON) return t`Invalid promo code`;

  if (couponError.errorType === CouponInfoErrorTypeEnum.BACKEND_ERROR)
    return t`Sorry, we’ve encountered a problem. Please try again.`;
};
