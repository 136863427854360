import { CheckCircleIcon, CloseIcon } from '@chakra-ui/icons';
import { Button, Grid, GridItem, HStack, Icon, Spinner, Stack, Text } from '@chakra-ui/react';
import { Plural, t, Trans } from '@lingui/macro';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { AiOutlineWarning } from 'react-icons/ai';
import { FiRefreshCcw } from 'react-icons/fi';
import { MdOutlineCloudOff } from 'react-icons/md';

import { TerminalStatusEnum } from '@/api/types';
import { useDeleteOrderPaymentMutation } from '@/api/useDeleteOrderPaymentMutation';
import Card from '@/components/Card';
import { TerminalIssue, TerminalStatusWithIssues } from '@/types';

import { PairTerminalButton } from './PairTerminalButton';

interface TerminalIssuesActionCardProps {
  terminalStatus: TerminalIssue;
  locationUuid?: string;
  terminalUuid?: string;
  onPaymentCancelation: () => void;
}

const TerminalIssuesActionCard = ({
  terminalStatus,
  locationUuid,
  terminalUuid,
  onPaymentCancelation,
}: TerminalIssuesActionCardProps) => {
  const { mutate: deleteOngoingPayment, isLoading } = useDeleteOrderPaymentMutation({
    onSuccess: () => {
      onPaymentCancelation();
    },
  });

  if ([TerminalStatusEnum.OFFLINE, TerminalStatusEnum.UNAVAILABLE].includes(terminalStatus)) {
    const isTerminalUnavailable = terminalStatus === TerminalStatusEnum.UNAVAILABLE;

    return (
      <Card sx={{ background: 'red.warning.25', borderColor: 'red.warning.300', color: 'red.warning.700' }}>
        <Grid templateColumns="30px 1fr">
          <GridItem>
            <Icon as={AiOutlineWarning} width={5} height={5} color={'red.warning.600'} />
          </GridItem>
          <Stack>
            <Text>
              {isTerminalUnavailable ? <Trans>Card machine unavailable</Trans> : <Trans>Card machine offline</Trans>}
            </Text>
            <Text fontWeight={300}>
              {isTerminalUnavailable ? (
                <Trans>To process payments, pair the card machine again.</Trans>
              ) : (
                <Trans>To continue, turn the card machine on.</Trans>
              )}
            </Text>
            {isTerminalUnavailable && <PairTerminalButton variant="outline" sx={{ width: 'fit-content' }} />}
          </Stack>
        </Grid>
      </Card>
    );
  }

  if (terminalUuid && locationUuid)
    return (
      <Card sx={{ background: 'transparent', boxShadow: 'unset', borderColor: 'primary.300' }}>
        <Grid templateColumns="30px 1fr">
          <GridItem>
            <Spinner size="xs" />
          </GridItem>
          <Stack gap={1}>
            <Text>
              <Trans>Payment request in progress</Trans>
            </Text>
            <Text fontWeight={300}>
              <Trans>To make the card machine available again, cancel the payment request.</Trans>
            </Text>
            <Button
              onClick={() => deleteOngoingPayment({ locationUuid, terminalUuid })}
              sx={{ background: 'red.error.600', color: 'white', marginTop: '8px', width: 'fit-content' }}
              leftIcon={<CloseIcon />}
              isLoading={isLoading}
              loadingText={t`Canceling payment`}
            >
              <Trans>Cancel payment request</Trans>
            </Button>
          </Stack>
        </Grid>
      </Card>
    );

  return null;
};

const TerminalSatusRenderingContent = {
  [TerminalStatusEnum.READY]: {
    content: (
      <>
        <CheckCircleIcon color={'green.success.icon'} width={5} height={5} />
        <Text color={'green.600'} fontSize={20}>
          <Trans>Ready</Trans>
        </Text>
      </>
    ),
  },
  [TerminalStatusEnum.OFFLINE]: {
    content: (
      <>
        <Icon as={MdOutlineCloudOff} width={5} height={5} />
        <Text color={'primary.700'} fontSize={20}>
          <Trans>Offline</Trans>
        </Text>
      </>
    ),
  },
  [TerminalStatusEnum.BUSY]: {
    content: (
      <>
        <Spinner size="xs" />
        <Text fontSize={20}>
          <Trans>Payment request in progress</Trans>
        </Text>
      </>
    ),
  },
  [TerminalStatusEnum.UNAVAILABLE]: {
    content: (
      <>
        <Icon as={AiOutlineWarning} color={'red.warning.600'} width={5} height={5} />
        <Text color={'red.warning.600'} fontSize={20}>
          <Trans>Unavailable</Trans>
        </Text>
      </>
    ),
  },
};

interface TerminalStatusCardProps {
  locationUuid?: string;
  terminalUuid?: string;
  terminalStatus: TerminalStatusEnum;
  onRefreshClick: (locationUuid?: string, terminalUuid?: string) => void;
}

const isTerminalIssue = (terminalStatus: TerminalStatusEnum | TerminalIssue) => {
  return TerminalStatusWithIssues.includes(terminalStatus as TerminalIssue);
};

export const TerminalStatusCard = ({
  terminalStatus,
  onRefreshClick,
  locationUuid,
  terminalUuid,
}: TerminalStatusCardProps) => {
  const [fetchStatusState, setFetchStatusState] = useState<Record<string, Dayjs>>({
    lastFetched: dayjs(),
    now: dayjs(),
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setFetchStatusState((old) => ({ ...old, now: dayjs() }));
    }, 60000);

    return () => clearInterval(interval);
  }, [fetchStatusState]);

  const lastFetchedDelayText = useMemo(() => {
    const delay = fetchStatusState.now.diff(fetchStatusState.lastFetched, 'minute');

    if (delay < 1) return t`Updated a few seconds ago`;

    return <Plural one="Updated # minute ago" other="Updated # minutes ago" value={delay} />;
  }, [fetchStatusState]);

  const handleRefreshClick = () => {
    setFetchStatusState({ lastFetched: dayjs(), now: dayjs() });
    onRefreshClick(locationUuid, terminalUuid);
  };

  return (
    <>
      {isTerminalIssue(terminalStatus) && (
        <TerminalIssuesActionCard
          terminalStatus={terminalStatus as TerminalIssue}
          terminalUuid={terminalUuid}
          locationUuid={locationUuid}
          onPaymentCancelation={handleRefreshClick}
        />
      )}
      <Card>
        <Stack gap={1}>
          <HStack justifyContent="space-between">
            <Text fontSize={20}>
              <Trans>Status</Trans>
            </Text>
            <HStack gap={1}>{TerminalSatusRenderingContent[terminalStatus].content}</HStack>
          </HStack>
          <HStack>
            <Button
              ml="auto"
              alignSelf="end"
              width="fit-content"
              color={'primary.700'}
              leftIcon={<Icon as={FiRefreshCcw} />}
              onClick={handleRefreshClick}
              variant="text"
            >
              <Trans>Refresh</Trans>
            </Button>
            <Text fontWeight={300}>{lastFetchedDelayText}</Text>
          </HStack>
        </Stack>
      </Card>
    </>
  );
};
