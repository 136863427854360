import { Avatar, Card, CardBody, CardFooter, Image, LinkBox, LinkOverlay, Stack, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

import { RestaurantTag } from '@/routes/RestaurantsPage/RestaurantCard/RestaurantTag';

export interface RestaurantCardProps {
  conceptName: string;
  heroPicture?: string;
  logoPicture?: string;
  onClick: (restaurantPlatformId: string) => void;
  restaurantPlatformId: string;
  tags: { [languageCode: string]: string[] | undefined };
}

export const RestaurantCard = ({
  conceptName,
  heroPicture,
  logoPicture,
  onClick,
  restaurantPlatformId,
  tags,
}: RestaurantCardProps) => {
  const { i18n } = useLingui();
  const i18nTags = tags[i18n.locale];

  return (
    <LinkBox as={motion.div} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
      <Card
        aspectRatio="16 / 9"
        borderRadius="2xl"
        sx={{
          '&:active': {
            outline: '4px solid #333333',
          },
        }}
      >
        <CardBody padding={0} flex={1} borderTopRadius="2xl" overflow="hidden" position="relative">
          <Image height="100%" width="100%" objectFit="cover" src={heroPicture} />
          <Stack
            bottom={0}
            justifyContent="space-between"
            left={0}
            paddingBottom={4}
            paddingTop={2}
            paddingX={2}
            position="absolute"
            right={0}
            top={0}
            sx={{
              background:
                'linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.4) 24.83%, rgba(0, 0, 0, 0) 37.2%)',
            }}
          >
            <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="flex-end">
              {i18nTags?.map((tag) => (
                <RestaurantTag key={tag} tag={tag} />
              ))}
            </Stack>
            <Text color="white" fontWeight={700} fontSize={{ base: 'xl', lg: '2xl', xl: '4xl' }} paddingLeft={2}>
              {conceptName}
            </Text>
          </Stack>
        </CardBody>
        <LinkOverlay
          as={Link}
          to={`/onSite/menu/${restaurantPlatformId}`}
          onClick={() => onClick(restaurantPlatformId)}
          onContextMenu={(e) => e.preventDefault()}
        >
          <CardFooter
            alignItems="center"
            bgColor="black"
            borderBottomRadius="2xl"
            justifyContent="center"
            padding={{ base: 2, lg: 4 }}
            position="relative"
          >
            {logoPicture && (
              <Avatar
                border="1px solid #EDF2F7"
                left="-3px"
                position="absolute"
                size={{ base: 'md', lg: 'xl' }}
                src={logoPicture}
                top="50%"
                transform="translate(-25%, -50%)"
                backgroundColor="white"
                objectFit="cover"
              />
            )}
            <Text
              color="white"
              fontWeight={700}
              fontSize={{ base: 'xl', lg: '2xl', xl: '4xl' }}
              textTransform="uppercase"
            >
              <Trans>Order</Trans>
            </Text>
          </CardFooter>
        </LinkOverlay>
      </Card>
    </LinkBox>
  );
};
