import { Badge, Button, CardHeader as ChakraCardHeader, Heading, HStack, Spacer, VStack } from '@chakra-ui/react';
import { FaLocationArrow } from 'react-icons/fa';

import { logDirectionsClick } from '@/helpers/dataLayer.helpers';
import { formatDistance } from '@/helpers/intl.helpers';

type CardHeaderProps = {
  address: string;
  addressUrl: string;
  distance?: number;
  kitchenLabel: string;
};

export const CardHeader = ({ address, addressUrl, distance, kitchenLabel }: CardHeaderProps) => {
  return (
    <ChakraCardHeader>
      <VStack align="stretch" gap={5}>
        <HStack spacing={4}>
          {distance && (
            <Badge
              bgColor="gray.100"
              borderRadius={4}
              color="black"
              fontSize="md"
              fontWeight="500"
              paddingBlock={1}
              paddingX={2}
              variant="solid"
              textTransform="lowercase"
            >
              {formatDistance(distance)}
            </Badge>
          )}
          <Spacer />
          <Button
            as="a"
            colorScheme="gray"
            href={addressUrl}
            leftIcon={<FaLocationArrow />}
            target="_blank"
            variant="link"
            onClick={() => logDirectionsClick(kitchenLabel)}
          >
            Directions
          </Button>
        </HStack>
        <Heading size="sm">{address}</Heading>
      </VStack>
    </ChakraCardHeader>
  );
};
