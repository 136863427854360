import { BackboneCustomerOrder, initializeBackboneCustomerOrder } from './backboneCustomerOrderApiClient';
import { GatewayClickCollect, initializeGatewayClickCollect } from './gatewayClickCollectApiClient';

export interface TasterApis {
  gatewayClickCollect: GatewayClickCollect;
  backboneCustomerOrder: BackboneCustomerOrder;
}

export const initialize = (getAccessToken: () => string): TasterApis => {
  const gatewayClickCollect = initializeGatewayClickCollect(getAccessToken);
  const backboneCustomerOrder = initializeBackboneCustomerOrder(getAccessToken);

  return { backboneCustomerOrder, gatewayClickCollect };
};
