import { Flex, Icon, Stack, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import React, { useMemo } from 'react';
import { FaClock, FaMapMarkerAlt } from 'react-icons/fa';

import Card from '@/components/Card';
import { getLocalCalendarFormat } from '@/dayjs';

interface Props {
  kitchenName: string;
  kitchenAddress: string;
  kitchenAccessInstructions: string;
  pickupTime: string | undefined;
  orderPickupMap?: JSX.Element;
}

const Title = ({ children }: { children: React.ReactNode }) => (
  <Text fontWeight={500} noOfLines={2} maxW="90%">
    {children}
  </Text>
);

const OrderInfosCard = ({
  kitchenName,
  kitchenAddress,
  kitchenAccessInstructions,
  pickupTime,
  orderPickupMap,
}: Props) => {
  const formattedPickupTime = useMemo<string | null>(() => {
    if (pickupTime) {
      return getLocalCalendarFormat(pickupTime);
    } else {
      return null;
    }
  }, [pickupTime]);

  const orderInfos = [
    {
      icon: FaMapMarkerAlt,
      Title: () => <Title>{kitchenName}</Title>,
      Subtitle: () => (
        <Stack fontWeight={400} color="gray.disabled" maxW="90%">
          {kitchenAddress && <Text>{kitchenAddress}</Text>}
          {kitchenAccessInstructions && <Text>{kitchenAccessInstructions}</Text>}
        </Stack>
      ),
    },
    {
      icon: FaClock,
      Title: () => <Title>{formattedPickupTime ? formattedPickupTime : <Trans>ASAP (10min)</Trans>}</Title>,
      Subtitle: () => <></>,
    },
  ];

  return (
    <Card>
      <Flex flexDirection="column" gap={6}>
        <Text fontWeight={700} fontSize={18}>
          <Trans>Order details</Trans>
        </Text>
        {orderPickupMap}
        {orderInfos.map(({ icon, Title, Subtitle }, index) => {
          return (
            <Flex gap={4} key={index} alignItems="start">
              <Icon as={icon} w={6} h={6} color="gray.icons" />
              <Flex flexDirection="column" flexWrap="nowrap" w="100%">
                <>
                  <Title />
                  <Subtitle />
                </>
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    </Card>
  );
};

export default OrderInfosCard;
