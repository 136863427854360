import { useLingui } from '@lingui/react';
import { useCallback } from 'react';

const useFormatPrice = () => {
  const { i18n } = useLingui();

  const formatPrice = useCallback(
    (price: number, currency: string, options?: Intl.NumberFormatOptions) => {
      return i18n.number(price, { style: 'currency', currency, currencyDisplay: 'narrowSymbol', ...options });
    },
    [i18n]
  );

  return { formatPrice };
};

export default useFormatPrice;
