import '@fontsource-variable/dm-sans';
import './index.css';

import { ChakraProvider, Spinner } from '@chakra-ui/react';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createRoot } from 'react-dom/client';

import ApiError from '@/api/ApiError';
import { initSentry } from '@/sentry';

import { TasterApisProvider } from './api/TasterApiContext';
import AppRouter from './AppRouter';
import { authClient, initOptions, onEvent } from './auth/keycloack';
import { CartProvider } from './contexts/cart';
import { OnSiteSessionProvider } from './contexts/onSiteSession';
import { OrderProvider } from './contexts/order';
import { activateI18n } from './i18n';
import theme from './theming/Theme';

if (import.meta.env.PROD) {
  initSentry();
}

activateI18n();

const captureSentryError = (error: unknown) => {
  if (error instanceof Error) {
    Sentry.captureException(error);
  } else {
    console.error(error);
  }
};

export const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError: captureSentryError,
    },
    queries: {
      onError: captureSentryError,
      refetchOnWindowFocus: false,
      retry(failureCount, error) {
        if (failureCount >= 3) return false;

        if (error instanceof ApiError) {
          if (error.status === 401) return true;
          if (error.status < 500) return false;
        }

        return true;
      },
    },
  },
});

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <ReactKeycloakProvider
    LoadingComponent={<Spinner size="xl" />}
    authClient={authClient}
    initOptions={initOptions}
    onEvent={onEvent}
  >
    <I18nProvider i18n={i18n}>
      <ChakraProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <TasterApisProvider>
            <OrderProvider>
              <CartProvider>
                <OnSiteSessionProvider>
                  <AppRouter />
                </OnSiteSessionProvider>
              </CartProvider>
            </OrderProvider>
          </TasterApisProvider>
        </QueryClientProvider>
      </ChakraProvider>
    </I18nProvider>
  </ReactKeycloakProvider>
);
