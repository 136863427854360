import { TextProps } from '@chakra-ui/layout/dist/text';
import { Box, Text } from '@chakra-ui/react';

type OpeningHoursLabelProps = TextProps & {
  isOpenNow: boolean;
  openingLabel?: string | null;
};

export const OpeningHoursLabel = ({ isOpenNow, openingLabel, ...props }: OpeningHoursLabelProps) => {
  if (!openingLabel) {
    return null;
  }

  return (
    <Text fontSize="sm" fontWeight={500} {...props}>
      <Box
        as="span"
        backgroundColor={isOpenNow ? 'green.500' : 'gray.500'}
        borderRadius="full"
        display="inline-block"
        height="6px"
        marginRight={1}
        width="6px"
      />
      {openingLabel}
    </Text>
  );
};
