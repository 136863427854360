import { useState } from 'react';

import { CartItemModifier, CartItemSubModifierGroup } from '@/api/gateway-click-collect/orders';
import { Modifier } from '@/api/gateway-click-collect/restaurants';
import itemPlaceholderImgSrc from '@/assets/img/item_placeholder.png';
import { KioskMenuCard } from '@/components/Kiosk/KioskMenuCard';
import { MenuItemPriceChip } from '@/components/MenuItemPriceChip';
import { useModifierGroupQuantity } from '@/contexts/ModifierGroupQuantityContext';

import { ModifierGroupModal } from '../ModifierGroupModal';
import { ItemQuantityCounter } from './ItemQuantityCounter';

export const ModifierCard = ({
  modifier,
  pictureUrl,
  currencyCode,
  pictures,
  onClick,
}: {
  onClick: (cartItemModifier: CartItemModifier) => void;
  modifier: Modifier;
  pictures: Record<string, string | undefined>;
  pictureUrl?: string;
  currencyCode: string;
}) => {
  const [isSubModifierModalOpen, setIsModifierModalOpen] = useState(false);

  const { max, canIncrease, decreaseModifierQuantity, increaseModifierQuantity, getModifierCurrentQuantity } =
    useModifierGroupQuantity();

  const { menuItemUuid, modifierUuid } = modifier;

  const handleSubModifierSelect = (subModifierGroupSelection: CartItemSubModifierGroup[]) => {
    if (max === 1) onClick({ menuItemUuid, modifierUuid, quantity: 1, modifierGroups: subModifierGroupSelection });
    else
      increaseModifierQuantity({
        menuItemUuid,
        modifierGroups: subModifierGroupSelection,
        modifierUuid,
        quantity: 1,
      });
  };

  const hasModifierGroups = !!modifier.modifierGroups?.length;

  const handleCardClick = () => {
    if (hasModifierGroups) setIsModifierModalOpen(true);
    else onClick({ menuItemUuid, modifierUuid, quantity: 1, modifierGroups: [] });
  };

  return (
    <KioskMenuCard label={modifier.label} pictureUrl={pictureUrl} onClick={max === 1 ? handleCardClick : undefined}>
      {!!modifier.sellingPrice && (
        <MenuItemPriceChip
          sellingPrice={modifier.sellingPrice}
          currencyCode={currencyCode}
          priceOptions={{ signDisplay: 'exceptZero' }}
        />
      )}
      {max > 1 && (
        <ItemQuantityCounter
          quantity={getModifierCurrentQuantity(modifierUuid)}
          canIncrease={canIncrease}
          onIncrease={() => {
            if (hasModifierGroups) setIsModifierModalOpen(true);
            else
              increaseModifierQuantity({
                menuItemUuid,
                modifierGroups: [],
                modifierUuid,
                quantity: 1,
              });
          }}
          onDecrease={() =>
            decreaseModifierQuantity({
              menuItemUuid,
              modifierGroups: [],
              modifierUuid,
              quantity: 1,
            })
          }
        />
      )}
      {!!modifier.modifierGroups?.length && (
        <ModifierGroupModal
          currencyCode={currencyCode}
          pictures={pictures}
          isOpen={isSubModifierModalOpen}
          modifierLabel={modifier.label}
          modifierGroups={modifier.modifierGroups}
          modifierPicture={pictureUrl ?? itemPlaceholderImgSrc}
          onClose={() => setIsModifierModalOpen(false)}
          onSubModifierSelection={handleSubModifierSelect}
        />
      )}
    </KioskMenuCard>
  );
};
