import dayjs, { Dayjs } from 'dayjs';

import { OnlineStatus, Restaurant, RestaurantShift } from '@/api/gateway-click-collect/restaurants';

const PRE_ORDER_INTERVAL_MINS = 15;

export const sortRestaurantShifts = (shifts: RestaurantShift[]) => {
  return shifts.sort((shiftA, shiftB) => {
    if (shiftA.startTime && shiftB.startTime) {
      return dayjs(shiftA.startTime, 'HH:mm:ss').isSameOrBefore(dayjs(shiftB.startTime, 'HH:mm:ss')) ? -1 : 1;
    }

    return 0;
  });
};

export const isRestaurantOpen = (status?: OnlineStatus) => status === OnlineStatus.Open;

export const getCurrentWeekdayShifts = ({ openingHours, timezone }: Pick<Restaurant, 'openingHours' | 'timezone'>) => {
  const restaurantTimezone = dayjs().tz(timezone);
  const isoWeekday = restaurantTimezone.isoWeekday() - 1;

  return sortRestaurantShifts(openingHours?.weekdays[isoWeekday]?.shifts ?? []);
};

export const getNextWeekdayShifts = ({ openingHours, timezone }: Pick<Restaurant, 'openingHours' | 'timezone'>) => {
  const restaurantTimezone = dayjs().tz(timezone);

  return getCurrentWeekdayShifts({ openingHours, timezone }).filter(({ startTime }) => {
    if (startTime) {
      return getDateAtTime(restaurantTimezone, startTime).isAfter(restaurantTimezone, 'second');
    }

    return false;
  });
};

export const getNextShift = ({ openingHours, timezone }: Pick<Restaurant, 'openingHours' | 'timezone'>) => {
  const restaurantTimezone = dayjs().tz(timezone);

  return getCurrentWeekdayShifts({ openingHours, timezone }).find(({ startTime }) => {
    if (startTime) {
      return getDateAtTime(restaurantTimezone, startTime).isAfter(restaurantTimezone, 'second');
    }

    return false;
  });
};

export const roundDateToNearestMinutesInterval = (
  date: Dayjs,
  minutesInterval: number,
  roundingCallback = Math.round
) => {
  const roundedMinute = roundingCallback(date.minute() / minutesInterval) * minutesInterval;
  return date.startOf('hour').add(roundedMinute, 'minute');
};

export const getMinutesDiff = (date1: Dayjs, date2: Dayjs) => {
  return Math.abs(Math.floor(dayjs.duration(date2.diff(date1)).asMinutes()));
};

export const getDateAtTime = (date: Dayjs, time: string) => {
  const [hour, minute, second] = time.split(':').map(Number);

  return date.set({ hour, minute, second }).startOf('second');
};

export const getShiftDates = (shift: RestaurantShift) => {
  const { endTime, startTime } = shift;
  const startTimeDate = startTime ? dayjs(startTime, 'HH:mm:ss') : null;
  let endTimeDate = null;

  if (endTime) {
    const bubbleUpToNextDay = dayjs(endTime, 'HH:mm:ss').hour() === 0;
    endTimeDate = bubbleUpToNextDay ? dayjs(endTime, 'HH:mm:ss').add(1, 'day') : dayjs(endTime, 'HH:mm:ss');
  }

  return { endTime: endTimeDate, startTime: startTimeDate };
};

export const getShiftTimesInterval = (shift: RestaurantShift, minutesInterval: number): string[] => {
  const { endTime, startTime } = shift;

  if (!endTime || !startTime) return [];

  const startTimeDate = dayjs(startTime, 'HH:mm:ss').add(1, 'minute');
  const bubbleUpToNextDay = dayjs(endTime, 'HH:mm:ss').hour() === 0;
  const endTimeDate = bubbleUpToNextDay
    ? dayjs('23:59:00', 'HH:mm:ss')
    : dayjs(endTime, 'HH:mm:ss').subtract(1, 'minute');

  const roundedStartTime = roundDateToNearestMinutesInterval(startTimeDate, minutesInterval, Math.ceil);
  const roundedEndTime = roundDateToNearestMinutesInterval(endTimeDate, minutesInterval, Math.floor);
  const maxInterval = getMinutesDiff(roundedStartTime, roundedEndTime) / minutesInterval + 1;

  return Array.from({ length: maxInterval }, (_, index) => {
    return roundedStartTime.add(minutesInterval * index, 'minute').format('HH:mm:ss');
  });
};

export const getPreOrderTimes = ({
  openingHours,
  timezone,
  status,
}: Pick<Restaurant, 'openingHours' | 'timezone' | 'status'>): string[] => {
  const restaurantTimezone = dayjs().tz(timezone);
  const preOrderStartDate = roundDateToNearestMinutesInterval(
    restaurantTimezone.add(PRE_ORDER_INTERVAL_MINS, 'minute'),
    PRE_ORDER_INTERVAL_MINS
  );

  const bubbleUpToNextDay = restaurantTimezone.isBefore(preOrderStartDate, 'day');
  if (bubbleUpToNextDay) {
    return [];
  }

  const weekdayShifts = isRestaurantOpen(status)
    ? getCurrentWeekdayShifts({ timezone, openingHours })
    : getNextWeekdayShifts({ timezone, openingHours });
  const baseTime = preOrderStartDate.tz(timezone).format('HH:mm:ss');

  return weekdayShifts
    .flatMap((shift) => getShiftTimesInterval(shift, PRE_ORDER_INTERVAL_MINS))
    .filter((time) => dayjs(baseTime, 'HH:mm:ss').isSameOrBefore(dayjs(time, 'HH:mm:ss'), 'minute'));
};

export const canPreOrder = ({
  openingHours,
  status,
  timezone,
}: Pick<Restaurant, 'openingHours' | 'timezone' | 'status'>) => {
  return !!getPreOrderTimes({ openingHours, status, timezone }).length;
};
