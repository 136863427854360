import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import ApiError from '@/api/ApiError';
import { useTasterApis } from '@/api/TasterApiContext';

import { restaurantsKeys } from './restaurants.helpers';
import { GetRestaurantMenuRequest, Menu } from './types';

export const restaurantMenuQueryOptions = {
  queryKey: (requestParams: GetRestaurantMenuRequest) => [...restaurantsKeys.all, 'menu', requestParams] as const,
};

export interface UseRestaurantMenuQueryProps<TData> {
  options?: Omit<
    UseQueryOptions<Menu, ApiError, TData, ReturnType<typeof restaurantMenuQueryOptions.queryKey>>,
    'queryKey' | 'queryFn'
  >;
  requestParams: GetRestaurantMenuRequest;
}

export const useRestaurantMenuQuery = <TData = Menu>({
  options,
  requestParams,
}: UseRestaurantMenuQueryProps<TData>) => {
  const {
    gatewayClickCollect: { restaurantsApi },
  } = useTasterApis();

  return useQuery({
    ...options,
    queryFn: () => restaurantsApi.getRestaurantMenu(requestParams),
    queryKey: restaurantMenuQueryOptions.queryKey(requestParams),
  });
};
