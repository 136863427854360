import { Box, Flex, Image, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import type { OrderItem } from '@/api/types';
import itemPlaceholderImgSrc from '@/assets/img/item_placeholder.png';
import Card from '@/components/Card';

type Props = {
  items: (OrderItem & { imageUrl: string | undefined })[];
};

const OrderDetailsCard = ({ items }: Props) => {
  return (
    <Card>
      <Text fontWeight={700}>
        <Trans>Your order</Trans>
      </Text>
      <Box mt={2}>
        <Flex fontSize="xs" justifyContent="space-between">
          <Text>
            <Trans>Products</Trans>
          </Text>
          <Text>
            <Trans>Qty</Trans>
          </Text>
        </Flex>
        {items.map((item, index) => (
          <Flex key={index} justifyContent="space-between" mt={2} mb={2}>
            <Flex gap={4}>
              <Image
                fallbackSrc={itemPlaceholderImgSrc}
                boxSize="64px"
                borderRadius="6px"
                src={item.imageUrl}
                objectFit="cover"
              />
              <Box>
                <Text fontSize="sm">{item.label}</Text>
                <UnorderedList color="gray.600" size="xs">
                  {item.modifiers.map((item, index) => (
                    <ListItem key={index}>
                      <Text fontSize="xs">{item.label}</Text>
                    </ListItem>
                  ))}
                </UnorderedList>
              </Box>
            </Flex>
            <Text minW="32px" textAlign="right">
              {item.quantity}
            </Text>
          </Flex>
        ))}
      </Box>
    </Card>
  );
};

export default OrderDetailsCard;
