import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Avatar, Button, Flex } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import { logOrderDelivery } from '@/helpers/dataLayer.helpers';

interface DeliveryPlatformInfoProps {
  platformLabel: string;
  platformLogo: string;
  platformUrl: string;
  kitchenLabel: string;
}

export const DeliveryPlatformInfo = ({
  platformLabel,
  platformLogo,
  platformUrl,
  kitchenLabel,
}: DeliveryPlatformInfoProps) => {
  return (
    <Flex align="center" gap={4}>
      <Avatar borderColor="gray.100" borderWidth={1} name={platformLabel} showBorder src={platformLogo} />
      <Button
        as="a"
        colorScheme="gray"
        flex={1}
        href={platformUrl}
        onClick={() => logOrderDelivery({ kitchenLabel, platformLabel })}
        rightIcon={<ExternalLinkIcon />}
        target="_blank"
      >
        <Trans>Order on {platformLabel}</Trans>
      </Button>
    </Flex>
  );
};
