import { keyBy, mapValues } from 'lodash-es';

import {
  Category,
  Menu,
  ModifierGroup as ModifierGroupV2,
  PictureClassification,
} from '@/api/gateway-click-collect/restaurants';
import { MenuElement, ModifierGroup } from '@/api/types';
import { getAvailableItems } from '@/helpers/menu.helpers';

export const getMenuItemPictures = (menuItemPicturesUrls: { [key: string]: PictureClassification[] | undefined }) => {
  return mapValues(menuItemPicturesUrls, (menuItemPicture) => menuItemPicture?.find(({ width }) => width === 768)?.url);
};

export const formatModifierGroups = (
  modifierGroups: ModifierGroupV2[] = [],
  outOfStocks: Set<string>
): ModifierGroup[] => {
  return modifierGroups.map((modifierGroup) => {
    return {
      ...modifierGroup,
      available: true,
      modifiers: modifierGroup.modifiers.map(
        ({
          label,
          menuItemUuid,
          modifierUuid,
          nutritionalInfo,
          sellingPrice,
          modifierGroups: nestedModifierGroups,
        }) => {
          return {
            available: !outOfStocks.has(menuItemUuid),
            label,
            menuItemUuid,
            modifierGroups: nestedModifierGroups?.length
              ? formatModifierGroups(nestedModifierGroups as ModifierGroupV2[], outOfStocks)
              : [],
            modifierUuid,
            nutritionalInfo,
            sellingPrice,
          };
        }
      ),
    };
  });
};

export const getCategoryMenuElements = ({
  category,
  menu,
  outOfStocks,
}: {
  category?: Category;
  menu: Menu;
  outOfStocks: Set<string>;
}): MenuElement[] | undefined => {
  const { items, menuItemPicturesUrls } = menu;

  if (!items?.length) {
    return;
  }

  const availableItems = getAvailableItems(items, outOfStocks);
  const itemsMap = keyBy(availableItems, 'menuElementUuid');
  const menuItemPictures = getMenuItemPictures(menuItemPicturesUrls);

  return category?.menuElementUuids
    ?.map((menuElementUuid) => {
      const item = itemsMap[menuElementUuid];

      if (item) {
        const { description, label, menuElementUuid, menuItemUuid, modifierGroups, nutritionalInfo, sellingPrice } =
          item;

        return {
          available: !outOfStocks.has(menuItemUuid),
          description,
          imageUrl: menuItemPictures[menuItemUuid],
          label,
          menuElementUuid,
          menuItemUuid,
          modifierGroups: formatModifierGroups(modifierGroups, outOfStocks),
          nutritionalInfo,
          sellingPrice,
        };
      }
    })
    .filter((menuElement): menuElement is MenuElement => !!menuElement?.available);
};
