import { useQueries } from '@tanstack/react-query';

import { Restaurant, restaurantMenuQueryOptions } from '@/api/gateway-click-collect/restaurants';
import { restaurantOutOfStocksQueryOptions } from '@/api/gateway-click-collect/restaurants/useRestaurantOutOfStockQuery';
import { useTasterApis } from '@/api/TasterApiContext';
import { MINUTE_MS } from '@/constants';

export interface UseBackgroundQueriesProps {
  restaurants?: Restaurant[];
}

const baseQueryOptions = { cacheTime: Infinity, refetchInterval: MINUTE_MS * 5 };

export const useBackgroundQueries = ({ restaurants = [] }: UseBackgroundQueriesProps) => {
  const {
    gatewayClickCollect: { restaurantsApi },
  } = useTasterApis();

  useQueries({
    queries: restaurants.flatMap(({ restaurantPlatformId }) => {
      const menuQuery = {
        ...baseQueryOptions,
        queryKey: restaurantMenuQueryOptions.queryKey({ restaurantPlatformId }),
        queryFn: () => restaurantsApi.getRestaurantMenu({ restaurantPlatformId }),
      };

      const outOfStocksQuery = {
        ...baseQueryOptions,
        queryKey: restaurantOutOfStocksQueryOptions.queryKey({ restaurantPlatformId }),
        queryFn: () => restaurantsApi.getRestaurantOutOfStocks({ restaurantPlatformId }),
      };

      return [menuQuery, outOfStocksQuery];
    }),
  });
};
