import { chakra, IconButton, Stack, Text } from '@chakra-ui/react';
import { t } from '@lingui/macro';
import { IoIosAdd, IoIosRemove } from 'react-icons/io';

const StyledIncreaseButton = chakra(IconButton, {
  baseStyle: {
    borderWidth: 2,
    color: 'white',
    width: '64px',
    height: '64px',
    borderRadius: '50%',
    background: 'black',
    borderColor: 'black',
    '&:disabled': {
      opacity: 1,
      background: '#D0D5DD',
      borderColor: 'transparent',
    },
    '&:focus-visible': {
      boxShadow: 'unset',
    },
  },
});

export const ItemQuantityCounter = ({
  quantity = 0,
  canIncrease,
  onDecrease,
  onIncrease,
}: {
  quantity?: number;
  canIncrease: boolean;
  onIncrease: () => void;
  onDecrease: () => void;
}) => {
  return (
    <Stack direction="row" gap={3} alignItems="center">
      {quantity && (
        <>
          <IconButton
            aria-label={t`Decrease quantity`}
            isRound
            borderWidth={2}
            variant="ghost"
            borderRadius="50%"
            height={'64px'}
            sx={{
              '&:focus-visible': {
                boxShadow: 'unset',
              },
            }}
            borderColor={'black'}
            icon={<IoIosRemove size="64px" />}
            onClick={(e) => {
              e.stopPropagation();
              onDecrease();
            }}
          />
          <Text fontSize={42} fontWeight={700}>
            {quantity}
          </Text>
        </>
      )}
      <StyledIncreaseButton
        aria-label={t`Increase quantity`}
        isRound
        icon={<IoIosAdd size="64px" />}
        onClick={(e) => {
          e.stopPropagation();
          onIncrease();
        }}
        isDisabled={!canIncrease}
      />
    </Stack>
  );
};
