import { ButtonProps } from '@chakra-ui/button/dist/button';
import { Button } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

type OrderButtonProps = ButtonProps & {
  hasPlatforms?: boolean;
  isDelivery?: boolean;
  isOpen: boolean;
  isOnSite?: boolean;
  isOpenToday?: boolean;
  onClick?: () => void;
};

export const OrderButton = ({
  hasPlatforms,
  isDelivery,
  isOpen,
  isOpenToday = false,
  isOnSite = false,
  onClick,
  ...buttonProps
}: OrderButtonProps) => {
  if (isOnSite) {
    return (
      <Button {...buttonProps} onClick={onClick}>
        <Trans>Start Order</Trans>
      </Button>
    );
  }
  if (isDelivery) {
    return (
      <Button {...buttonProps} isDisabled={!hasPlatforms} onClick={onClick}>
        {hasPlatforms ? <Trans>Order delivery</Trans> : <Trans>Delivery unavailable</Trans>}
      </Button>
    );
  }
  if (isOpen) {
    return (
      <Button {...buttonProps} onClick={onClick}>
        <Trans>Order</Trans>
      </Button>
    );
  } else if (isOpenToday) {
    return (
      <Button {...buttonProps} onClick={onClick}>
        <Trans>Preorder</Trans>
      </Button>
    );
  }

  return (
    <Button {...buttonProps} colorScheme="gray" onClick={onClick} variant="outline">
      <Trans>See menu</Trans>
    </Button>
  );
};
