import { chakra, Grid } from '@chakra-ui/react';

export const MenuItemGridLayout = chakra(Grid, {
  baseStyle: {
    gap: 3,
    gridTemplateColumns: { base: '1fr', sm: 'repeat(2, minmax(200px, 360px))' },
    justifyContent: 'center',
    p: 3,
    pb: 125,
    width: '100%',
    '@media screen and (min-width: 1366px) and (max-height: 1024px)': {
      gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 432px));',
    },
  },
});
