export type {
  CouponInfo,
  ValidateOrderRequest,
  ValidOrder,
} from '@tasterkitchens/client-ts-backbone-customer-order-v2';

export enum CouponInfoErrorTypeEnum {
  INVALID_COUPON = 'invalid_coupon',
  BACKEND_ERROR = 'backend_error',
  BELOW_ACTIVATION_PRICE = 'below_activation_price',
}
