import { keyBy } from 'lodash-es';

import {
  CartItemModifier,
  CartItemModifierGroup,
  CartItemSubModifier,
  CartItemSubModifierGroup,
} from '@/api/gateway-click-collect/orders';
import { Item, Modifier, ModifierGroup, SubModifierGroup } from '@/api/gateway-click-collect/restaurants';
import { ItemCart, ModifierCart, ModifierGroupCart } from '@/types';

const getEnrichedModifierGroup = ({
  cartItemModifierGroup,
  searchedModifierGroup,
}: {
  cartItemModifierGroup: CartItemSubModifierGroup | CartItemModifierGroup;
  searchedModifierGroup: ModifierGroup | SubModifierGroup;
}): ModifierGroupCart => {
  const { modifierGroupUuid } = cartItemModifierGroup;

  const modifiersMap = keyBy(searchedModifierGroup.modifiers, 'modifierUuid');

  return {
    modifierGroupUuid,
    description: searchedModifierGroup.description,
    available: true,
    included: searchedModifierGroup.included,
    label: searchedModifierGroup.label,
    max: searchedModifierGroup.max,
    modifiers: [],
    selectedModifiers: cartItemModifierGroup.modifiers
      .map((cartItemModifier) => {
        const searchedModifier = modifiersMap[cartItemModifier.modifierUuid];

        return getEnrichedModifier({
          cartItemModifier,
          searchedModifier,
        });
      })
      .filter(Boolean),
  };
};

const getEnrichedModifier = ({
  cartItemModifier,
  searchedModifier,
}: {
  cartItemModifier: CartItemModifier | CartItemSubModifier;
  searchedModifier: Modifier;
}): ModifierCart => {
  const { label, nutritionalInfo, sellingPrice, modifierUuid, menuItemUuid, modifierGroups } = searchedModifier;

  const { quantity } = cartItemModifier;

  const modifierGroupsMap = keyBy(modifierGroups, 'modifierGroupUuid');

  return {
    quantity,
    // @ts-ignore
    selectedModifierGroups: cartItemModifier.modifierGroups?.length
      ? // @ts-ignore
        cartItemModifier.modifierGroups
          // @ts-ignore
          .map((cartItemSubModifierGroup) =>
            getEnrichedModifierGroup({
              cartItemModifierGroup: cartItemSubModifierGroup,
              searchedModifierGroup: modifierGroupsMap[cartItemSubModifierGroup.modifierGroupUuid],
            })
          )
          .filter(Boolean)
      : undefined,
    modifierGroups: [],
    label,
    available: true,
    nutritionalInfo,
    sellingPrice,
    modifierUuid,
    menuItemUuid,
  };
};

export const getDeprecatedItemCart = ({
  cartItemModifierGroups,
  item,
  picture,
}: {
  cartItemModifierGroups: CartItemModifierGroup[];
  item: Item;
  picture?: string;
}): ItemCart => {
  const modifierGroupsMap = keyBy(item.modifierGroups, 'modifierGroupUuid');

  return {
    quantity: 1,
    available: true,
    description: item.description ?? '',
    imageUrl: picture ?? '',
    label: item.label,
    menuElementUuid: item.menuElementUuid,
    menuItemUuid: item.menuItemUuid,
    modifierGroups:
      item.modifierGroups?.map(({ description, included, label, max, modifierGroupUuid, modifiers }) => {
        return {
          available: true,
          description,
          included,
          label,
          max,
          modifierGroupUuid,
          modifiers: modifiers.map(
            ({ modifierUuid, label, menuItemUuid, nutritionalInfo, sellingPrice, modifierGroups }) => {
              return {
                available: true,
                modifierUuid,
                label,
                menuItemUuid,
                nutritionalInfo,
                sellingPrice,
                modifierGroups:
                  modifierGroups?.map((modifierGroup) => {
                    return {
                      available: true,
                      description: modifierGroup.description,
                      included: modifierGroup.included,
                      label: modifierGroup.label,
                      max: modifierGroup.max,
                      modifierGroupUuid: modifierGroup.modifierGroupUuid,
                      modifiers: modifierGroup.modifiers.map((subModifier) => {
                        return {
                          available: true,
                          modifierUuid: subModifier.modifierUuid,
                          label: subModifier.label,
                          menuItemUuid: subModifier.menuItemUuid,
                          nutritionalInfo: subModifier.nutritionalInfo,
                          sellingPrice: subModifier.sellingPrice,
                          modifierGroups: [],
                        };
                      }),
                    };
                  }) ?? [],
              };
            }
          ),
        };
      }) ?? [],
    sellingPrice: item.sellingPrice,
    nutritionalInfo: item.nutritionalInfo,
    selectedModifierGroups: cartItemModifierGroups
      .map((cartItemModifierGroup) => {
        const searchedModifierGroup = modifierGroupsMap[cartItemModifierGroup.modifierGroupUuid];

        return getEnrichedModifierGroup({ cartItemModifierGroup, searchedModifierGroup });
      })
      .filter(Boolean),
  };
};
