import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import ApiError from '@/api/ApiError';
import { useTasterApis } from '@/api/TasterApiContext';

import { restaurantsKeys } from './restaurants.helpers';
import { GetRestaurantOutOfStocksRequest } from './types';

export const restaurantOutOfStocksQueryOptions = {
  queryKey: (requestParams: GetRestaurantOutOfStocksRequest) =>
    [...restaurantsKeys.all, 'outOfStocks', requestParams] as const,
};

export interface UseRestaurantOutOfStocksQueryProps<TData> {
  options?: Omit<
    UseQueryOptions<string[], ApiError, TData, ReturnType<typeof restaurantOutOfStocksQueryOptions.queryKey>>,
    'queryFn' | 'queryKey'
  >;
  requestParams: GetRestaurantOutOfStocksRequest;
}

export const useRestaurantOutOfStocksQuery = <TData = string[]>({
  requestParams,
  options,
}: UseRestaurantOutOfStocksQueryProps<TData>) => {
  const {
    gatewayClickCollect: { restaurantsApi },
  } = useTasterApis();

  return useQuery({
    ...options,
    queryFn: () => restaurantsApi.getRestaurantOutOfStocks(requestParams),
    queryKey: restaurantOutOfStocksQueryOptions.queryKey(requestParams),
  });
};
