import { ButtonProps } from '@chakra-ui/button/dist/button';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Button } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import dayjs from 'dayjs';

export const RequestLocationButton = (props: ButtonProps) => {
  const locale = dayjs.locale();
  const formUrlMap: Record<string, string> = {
    en: 'https://share.hsforms.com/1-fy2_WZBRuORnBUr1Mdk9Ac1e7i',
    es: 'https://share.hsforms.com/1T5heb78oQoWKJLUt55rOcQc1e7i',
    fr: 'https://share.hsforms.com/1ftZy-gV9TzuHT_FEj9CXPwc1e7i',
  };
  const url = formUrlMap[locale] ?? formUrlMap.en;

  return (
    <Button {...props} as={'a'} colorScheme="gray" rightIcon={<ExternalLinkIcon />} href={url} target="_blank">
      <Trans>Request location</Trans>
    </Button>
  );
};
