import { Stack, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useNavigate } from 'react-router-dom';

import taskDoneAnim from '@/assets/anim/lottie_done.json';
import { LottieAnimation } from '@/components/LottieAnimation';
export const PairingTerminalSuccessContent = () => {
  const navigate = useNavigate();

  const [locationUuid] = useLocalStorage('LOCATION_UUID', null);

  setTimeout(() => {
    navigate(`/onSite/${locationUuid}`);
  }, 5000);

  return (
    <Stack paddingTop={24} gap={24} justifyContent="center" alignItems="center">
      <LottieAnimation animation={taskDoneAnim} animationOptions={{ loop: false }} />
      <Text fontWeight={500} fontSize={32}>
        <Trans>Card machine paired</Trans>
      </Text>
      <Text fontWeight={300} fontSize={24}>
        <Trans>You will be redirected to the Click & Collect shortly</Trans>
      </Text>
    </Stack>
  );
};
