import { useKeycloak } from '@react-keycloak/web';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useCallback } from 'react';

type MutationFunction<TData, TVariables> = (variables: TVariables) => (token: string) => Promise<TData>;

const useAuthenticatedMutation = <TMutationFnData, TError, TVariables>(
  mutationFn: MutationFunction<TMutationFnData, TVariables>,
  options?: UseMutationOptions<TMutationFnData, TError, TVariables>
) => {
  const {
    keycloak: { isTokenExpired, updateToken, login, token },
  } = useKeycloak();

  const authMutationFn = useCallback(
    async (requestParams: TVariables) => {
      if (isTokenExpired()) {
        try {
          await updateToken(-1);
        } catch (e) {
          login();
        }
      }

      return mutationFn(requestParams)(token as string);
    },
    [isTokenExpired, login, mutationFn, token, updateToken]
  );

  return useMutation({ ...options, mutationFn: (requestParams) => authMutationFn(requestParams) });
};

export default useAuthenticatedMutation;
