import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  chakra,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Radio,
  RadioProps,
  Spinner,
  Stack,
  Text,
  useRadio,
  useRadioGroup,
} from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUserLocationsQuery } from '@/api/gateway-click-collect';
import { AsyncContent } from '@/components/AsyncContent';

import { PairDeviceLayout } from './PairDeviceLayout';

const KitchenRadioCard = (props: RadioProps) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const inputProps = getInputProps();

  const checkboxProps = getCheckboxProps();

  return (
    <Box as="label" height="80px">
      <input {...inputProps} />
      <Box
        {...checkboxProps}
        cursor="pointer"
        borderWidth="2px"
        height="100%"
        borderRadius="md"
        background={props.isChecked ? '#F5F5F5' : '#FFFF'}
        width="100%"
        px={4}
        py={2}
        _checked={{
          borderColor: 'black.500',
        }}
      >
        <Stack direction="row" gap={2} flexWrap="nowrap" height="100%" alignItems="center">
          <Radio isChecked={props.isChecked} />
          {props.children}
        </Stack>
      </Box>
    </Box>
  );
};

const StyledInput = chakra(Input, {
  baseStyle: {
    background: 'white',
    padding: '16px',
    paddingLeft: '36px',
    width: '100%',
  },
});

export const InternalLocationsPage = () => {
  const navigate = useNavigate();
  const [selectedLocationUuid, setSelectedLocationUuid] = useState('');
  const [, setLocalStorageLocationUuid] = useLocalStorage<string | null>('LOCATION_UUID', null);
  const [, setLocalStorageRestaurantAddress] = useLocalStorage<string | null>('RESTAURANT_ADDRESS', null);
  const [, setLocalStorageKitchenLabel] = useLocalStorage<string | null>('KITCHEN_LABEL', null);
  const [searchValue, setSearchValue] = useState('');

  const {
    data: locationsList = [],
    isLoading: areLocationsLoading,
    isError: areLocationsOnError,
    isSuccess,
  } = useUserLocationsQuery();

  const searchedLocations = locationsList.filter(({ kitchenLabel }) =>
    kitchenLabel.toLowerCase().includes(searchValue.trim().toLowerCase())
  );

  const handleNextStepClick = useCallback(() => {
    if (selectedLocationUuid) {
      const searchedLocation = searchedLocations.find(({ locationUuid }) => locationUuid === selectedLocationUuid);

      if (searchedLocation) {
        setLocalStorageKitchenLabel(searchedLocation.kitchenLabel);
        setLocalStorageRestaurantAddress(searchedLocation.address);
      }
      setLocalStorageLocationUuid(selectedLocationUuid);
    }

    navigate('/onSite/pairTerminal');
  }, [
    selectedLocationUuid,
    navigate,
    setLocalStorageLocationUuid,
    searchedLocations,
    setLocalStorageKitchenLabel,
    setLocalStorageRestaurantAddress,
  ]);

  useEffect(() => {
    if (isSuccess) {
      if (locationsList.length === 1) {
        setLocalStorageLocationUuid(locationsList[0].locationUuid);
        setLocalStorageKitchenLabel(locationsList[0].kitchenLabel);
        setLocalStorageRestaurantAddress(locationsList[0].address);
        handleNextStepClick();
      }
    }
  }, [
    handleNextStepClick,
    isSuccess,
    locationsList,
    setLocalStorageKitchenLabel,
    setLocalStorageLocationUuid,
    setLocalStorageRestaurantAddress,
  ]);

  const { getRootProps, getRadioProps } = useRadioGroup({
    onChange: setSelectedLocationUuid,
    value: selectedLocationUuid,
  });

  return (
    <PairDeviceLayout hasLogOutButton headerTitle={t`Pair a card machine`}>
      <Stack gap={12} alignItems="center" justifyContent="start">
        <Heading fontWeight={500} fontSize={32}>
          <Trans>Select kitchen</Trans>
        </Heading>
        <AsyncContent
          isLoading={areLocationsLoading}
          data={locationsList}
          hasData={!!locationsList?.length}
          noDataText={<Trans>You dont have access to any Taster kitchens</Trans>}
          LoadingContent={<Spinner size="xl" />}
          isError={areLocationsOnError}
          renderContent={(locations) => {
            const radioGroupProps = getRootProps();

            return (
              <Stack gap={2} {...radioGroupProps} width="100%" maxW={500} px={8}>
                {locations.length > 3 && (
                  <InputGroup mb={4}>
                    <InputLeftElement>
                      <SearchIcon color="gray.300" />
                    </InputLeftElement>
                    <StyledInput
                      value={searchValue}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
                    />
                  </InputGroup>
                )}
                <Stack gap={2} maxHeight="300px" overflow="auto">
                  {searchedLocations.map(({ kitchenLabel, locationUuid }) => {
                    const radioProps = getRadioProps({ value: locationUuid });

                    return (
                      <KitchenRadioCard {...radioProps} key={locationUuid}>
                        <Text fontSize={16} fontWeight={500}>
                          {kitchenLabel}
                        </Text>
                      </KitchenRadioCard>
                    );
                  })}
                </Stack>
              </Stack>
            );
          }}
        />
        <Button
          isDisabled={!selectedLocationUuid}
          width="100%"
          maxWidth={220}
          paddingY={8}
          fontSize={24}
          onClick={handleNextStepClick}
        >
          <Trans>Next</Trans>
        </Button>
      </Stack>
    </PairDeviceLayout>
  );
};
