import { AuthClientError, AuthClientEvent } from '@react-keycloak/core';
import Keycloak from 'keycloak-js';

import dataLayer from '@/helpers/dataLayer.helpers';

export const authClient = new Keycloak({
  clientId: import.meta.env.VITE_APP_IAM_CLIENT_ID,
  realm: import.meta.env.VITE_APP_IAM_REALM,
  url: import.meta.env.VITE_APP_IAM_AUTH_SERVER_URL,
});

export const initOptions = {
  checkLoginIframe: false,
  flow: 'standard',
};

export const onEvent = (eventType: AuthClientEvent, error?: AuthClientError) => {
  if (eventType === 'onAuthLogout' && !error) {
    authClient.clearToken();
  } else if (eventType === 'onAuthSuccess') {
    const isPwaMode = window.matchMedia('(display-mode: standalone)').matches;

    dataLayer.logLoginEvent(isPwaMode ? 'PWA' : 'BROWSER');
  }
};
