import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  RadioProps,
  Text,
  Textarea,
  useRadio,
  useRadioGroup,
} from '@chakra-ui/react';
import { defineMessage, Trans } from '@lingui/macro';
import { t } from '@lingui/macro';
import { useState } from 'react';

const PAYMENT_METHODS = {
  CASH: 'cash',
  BANK_CARD: 'onsite',
  MEAL_VOUCHER: 'ticket restaurant',
  OTHER: 'other',
};

const PAYMENT_METHOD_OPTIONS = [
  { label: defineMessage({ message: 'Cash' }), value: PAYMENT_METHODS.CASH },
  { label: defineMessage({ message: 'On site by credit card' }), value: PAYMENT_METHODS.BANK_CARD },
  { label: defineMessage({ message: 'Meal voucher' }), value: PAYMENT_METHODS.MEAL_VOUCHER },
  { label: defineMessage({ message: 'Other' }), value: PAYMENT_METHODS.OTHER },
];

type Props = {
  isOpen: boolean;
  onClose: () => unknown;
  onSelected: (paymentMethod: string, other?: string) => unknown;
};

const PaymentMethodModal = ({ isOpen, onClose, onSelected }: Props) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string | undefined>();
  const [otherPaymentMethod, setOtherPaymentMethod] = useState<string>('');
  const { getRadioProps } = useRadioGroup({
    value: selectedPaymentMethod,
    onChange: (paymentMethod) => {
      if (paymentMethod !== PAYMENT_METHODS.OTHER) {
        onSelected(paymentMethod);
      }

      setSelectedPaymentMethod(paymentMethod);
    },
  });

  const handleSubmit = () => {
    if (selectedPaymentMethod === PAYMENT_METHODS.OTHER) {
      onSelected(selectedPaymentMethod, otherPaymentMethod);
    }

    onClose();
  };

  const handleOtherPaymentMethodChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setOtherPaymentMethod(event.target.value);
  };

  return (
    <Modal id="webview-modal" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody py="12" px="14">
          <Text textAlign="center" fontWeight="500" pb="4">
            Which payment method would you like to use?
          </Text>

          {PAYMENT_METHOD_OPTIONS.map(({ label, value }) => {
            const radio = getRadioProps({ value });
            return (
              <PaymentMethodCard key={value} {...radio}>
                <Trans id={label.id} />
              </PaymentMethodCard>
            );
          })}
          {selectedPaymentMethod === PAYMENT_METHODS.OTHER && (
            <Textarea
              fontSize="xs"
              placeholder={t`Specify the desired method of payment...`}
              value={otherPaymentMethod}
              onChange={handleOtherPaymentMethodChange}
            />
          )}
          <PaymentMethodMessage paymentMethod={selectedPaymentMethod} />
          <Box mt="2" w="100%">
            <Button
              w="100%"
              onClick={handleSubmit}
              isDisabled={selectedPaymentMethod === PAYMENT_METHODS.OTHER && !otherPaymentMethod}
            >
              {selectedPaymentMethod === 'other' ? <Trans>Confirm</Trans> : <Trans>Go back to the payment page</Trans>}
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const PaymentMethodMessage = ({ paymentMethod }: { paymentMethod: string | undefined }) => {
  if (paymentMethod === PAYMENT_METHODS.CASH) {
    return (
      <Text fontSize="xs">
        <Trans>
          We do not accept this method of payment at the moment. You can return to the payment page to pay securely
          online with our partner Stripe.
        </Trans>
      </Text>
    );
  }

  if (paymentMethod === PAYMENT_METHODS.BANK_CARD) {
    return (
      <Text fontSize="xs">
        <Trans>
          It is not possible to pay on site by credit card at the moment. You can return to the payment page to pay
          securely online with our partner Stripe.
        </Trans>
      </Text>
    );
  }

  if (paymentMethod === PAYMENT_METHODS.MEAL_VOUCHER) {
    return (
      <Text fontSize="xs">
        <Trans>
          We only accept the Swile meal ticket card for the moment. You can use it on the payment page to pay securely
          online with our partner Stripe, just like with a regular bank card.
        </Trans>
      </Text>
    );
  }

  return <></>;
};

const PaymentMethodCard = (props: RadioProps) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        borderColor="#E6E6E6"
        color="#7C7D85"
        fontSize="sm"
        _checked={{
          color: 'black.500',
          borderColor: 'black.500',
          borderWidth: '2px',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        px={4}
        py={3}
        mb={2}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export default PaymentMethodModal;
