import { Flex, Image, Text } from '@chakra-ui/react';
import { t } from '@lingui/macro';
import { useMemo } from 'react';

import successIcon from '@/assets/img/check_icon.svg';
import errorIcon from '@/assets/img/cross_icon.svg';

interface Props {
  email: string;
  isReceiptSentOnSuccess: boolean;
}

const ReceiptStatus = ({ email, isReceiptSentOnSuccess }: Props) => {
  const statusMessage = useMemo(() => {
    if (isReceiptSentOnSuccess) return t({ message: `Your receipt have been sent to {email}`, values: { email } });
    else return t`Unable to send receipt.`;
  }, [email, isReceiptSentOnSuccess]);

  return (
    <Flex direction="row" gap={6} alignItems="center">
      <Image width="24px" src={isReceiptSentOnSuccess ? successIcon : errorIcon} />
      <Text color={isReceiptSentOnSuccess ? 'green' : 'red.required'}>{statusMessage}</Text>
    </Flex>
  );
};

export default ReceiptStatus;
