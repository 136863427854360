import dayjs from 'dayjs';

export const formatDistance = (distance: number, options?: Intl.NumberFormatOptions, locale = dayjs.locale()) =>
  Intl.NumberFormat(locale, {
    notation: 'compact',
    style: 'unit',
    unit: 'meter',
    unitDisplay: 'narrow',
    ...options,
  }).format(distance);
