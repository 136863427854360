import { Button, Checkbox, Flex, FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Trans } from '@lingui/macro';
import { Controller, useForm, UseFormReset } from 'react-hook-form';
import { boolean, object, string } from 'yup';

type ResetReceiptForm = UseFormReset<{
  email: string;
  isOptedIn: boolean;
}>;

export type SubmitReceiptEventHandler = (email: string, isOptedIn: boolean, options: ResetReceiptForm) => void;

interface ReceiptFormProps {
  isLoading: boolean;
  onSubmit: SubmitReceiptEventHandler;
}

const ReceiptForm = ({ isLoading, onSubmit }: ReceiptFormProps) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm({
    defaultValues: { email: '', isOptedIn: false },
    mode: 'all',
    resolver: yupResolver(
      object({
        email: string().required().email(),
        isOptedIn: boolean(),
      })
    ),
  });

  const sendReceipt = handleSubmit((formData) => {
    const { email, isOptedIn } = formData;

    onSubmit(email, isOptedIn, reset);
  });

  return (
    <form onSubmit={sendReceipt}>
      <Controller
        name="email"
        control={control}
        render={({ field, fieldState: { invalid } }) => (
          <FormControl isInvalid={invalid}>
            <FormLabel>
              <Trans>Email</Trans>
            </FormLabel>
            <Input {...field} />
            <FormErrorMessage>
              <Trans>Please enter a valid email address</Trans>
            </FormErrorMessage>
          </FormControl>
        )}
      />
      <Flex flexDirection="column" gap="64px">
        <Controller
          control={control}
          name="isOptedIn"
          render={({ field: { value, ...fieldRest } }) => (
            <Checkbox {...fieldRest} isChecked={value} spacing={6} mt={1}>
              <Trans>Yes, I would like to be the first to know about exclusive offers and discounts.</Trans>
            </Checkbox>
          )}
        />
        <Button size="lg" type="submit" w="100%" isDisabled={!isValid} isLoading={isLoading}>
          <Trans>Send receipt</Trans>
        </Button>
      </Flex>
    </form>
  );
};

export default ReceiptForm;
