import { Stack } from '@chakra-ui/react';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useNavigate, useParams } from 'react-router-dom';

import { MenuElement } from '@/api/types';
import { KioskOutOfStockContent } from '@/components/ItemOutOfStockContent';
import { MenuItemCard } from '@/components/Kiosk/MenuItemCard';
import { MenuItemsPageFooter } from '@/components/Kiosk/MenuItemsPageFooter';
import { MenuItemsPageHeader } from '@/components/Kiosk/MenuItemsPageHeader';
import { useCart } from '@/contexts/cart';
import dataLayer from '@/helpers/dataLayer.helpers';
import { updateCart } from '@/helpers/menu.helpers';
import { MenuItemGridLayout } from '@/layouts/MenuItemGridLayout';
import { useCategoryDataContext } from '@/routes/PayOnSite/MenuItemsPage/CategoryDataProvider';
import { ItemCart } from '@/types';

export const MenuItemsPage = () => {
  const navigate = useNavigate();
  const { categoryUuid, restaurantPlatformId } = useParams() as { categoryUuid: string; restaurantPlatformId: string };

  const { cart, setCart } = useCart();
  const [kitchenLabel] = useLocalStorage<string>('KITCHEN_LABEL', '');

  const { category, restaurant, conceptInformation, menuElements } = useCategoryDataContext();
  const { address, currencyCode, kitchenInstructions } = restaurant;

  const getCartItemQuantity = (itemUuid: string) => {
    return cart.items
      .filter(({ menuItemUuid }) => itemUuid === menuItemUuid)
      .reduce((quantityAcc, currentItem) => currentItem.quantity + quantityAcc, 0);
  };

  const onUpdateCart = (item: ItemCart) => {
    const items = cart.restaurantPlatformId === restaurantPlatformId ? updateCart({ item, cart }) : [item];

    dataLayer.logItemAdded(kitchenLabel, conceptInformation.conceptName, item, 'onsite');

    setCart({
      accessInstructions: kitchenInstructions,
      conceptLabel: conceptInformation.conceptName,
      items,
      kitchenLabel,
      restaurantAddress: address,
      restaurantName: restaurant.restaurantLabel,
      restaurantPlatformId,
    });
  };

  const onItemClick = (menuElement: MenuElement) => {
    if (menuElement.modifierGroups.length) {
      navigate(`/onSite/menu/${restaurantPlatformId}/categories/${categoryUuid}/item/${menuElement.menuElementUuid}`);
    } else {
      onUpdateCart({ ...menuElement, quantity: 1 });
    }
  };

  return (
    <Stack height="100%">
      <MenuItemsPageHeader
        title={category.label}
        onBackButtonClick={() => {
          window.scrollTo({ left: 0, top: 0, behavior: 'auto' });
          navigate(`/onSite/menu/${restaurantPlatformId}`);
        }}
      />

      {menuElements?.length ? (
        <MenuItemGridLayout>
          {menuElements.map((menuElement) => {
            const { description, label, menuItemUuid, sellingPrice, imageUrl } = menuElement;

            return (
              <MenuItemCard
                currencyCode={currencyCode}
                description={description}
                key={menuItemUuid}
                label={label}
                onClick={() => onItemClick(menuElement)}
                pictureUrl={imageUrl}
                quantity={getCartItemQuantity(menuItemUuid)}
                sellingPrice={sellingPrice}
              />
            );
          })}
        </MenuItemGridLayout>
      ) : (
        <KioskOutOfStockContent redirectUrl={`/onSite/menu/${restaurantPlatformId}`} />
      )}
      <MenuItemsPageFooter
        cart={cart}
        restaurantPlatformId={restaurantPlatformId}
        onCartClick={() => {
          dataLayer.clickCart({ brandLabel: conceptInformation.conceptName, kitchenLabel, orderChannel: 'onsite' });
        }}
      />
    </Stack>
  );
};
