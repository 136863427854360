import { Box, Grid, GridItem, Heading, Stack } from '@chakra-ui/react';
import { ReactNode } from 'react';

import { CartItemModifier } from '@/api/gateway-click-collect/orders/types';
import { ModifierGroup } from '@/api/gateway-click-collect/restaurants/types';

import { KioskBackButton } from '../Kiosk/KioskBackButton';
import { AddModifierGroupFooter } from './AddModifierGroupFooter';

const titleDescriptionStyle = {
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: '2',
  overflow: 'hidden',
};

export interface AddModifierGroupLayoutProps {
  children: ReactNode;
  modifierGroup: ModifierGroup;
  restaurantPlatformId: string;
  onBackButtonClick: () => void;
  onConfirmClick: (cartItemModifier?: CartItemModifier[]) => void;
  onSkip: () => void;
}

export const AddModifierGroupLayout = ({
  children,
  modifierGroup,
  onBackButtonClick,
  onConfirmClick,
  onSkip,
  restaurantPlatformId,
}: AddModifierGroupLayoutProps) => {
  return (
    <Stack height="100%" justifyContent="space-between" gap={0} width="100%">
      <Grid
        templateColumns="1fr auto 1fr"
        flexDirection="row"
        gap={2}
        backgroundColor="#FFFFFF"
        alignItems="center"
        p={4}
        boxShadow="0px 4px 8px rgba(0, 0, 0, 0.03)"
      >
        <GridItem justifySelf="start">
          <KioskBackButton onClick={onBackButtonClick} />
        </GridItem>
        <Stack gap={2} alignItems="center">
          <Heading fontSize="42px" size="md" fontWeight="700" textAlign="center" maxW="100%" sx={titleDescriptionStyle}>
            {modifierGroup.label}
          </Heading>
        </Stack>
      </Grid>
      <Box flex={1} overflow="auto">
        {children}
      </Box>
      <AddModifierGroupFooter
        onSkip={onSkip}
        onConfirmClick={onConfirmClick}
        restaurantPlatformId={restaurantPlatformId}
        sx={{
          bottom: 0,
          position: 'fixed',
        }}
      />
    </Stack>
  );
};
