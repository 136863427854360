import { CartItemModifier } from '@/api/gateway-click-collect/orders';

export const decrementCartItemModifier = (
  oldModifiers: CartItemModifier[],
  currentNewCartItemModifier: CartItemModifier
) => {
  return oldModifiers
    .reduceRight<Array<CartItemModifier | null>>(
      (newModifiers, currentModifier, index) => {
        if (currentModifier.modifierUuid === currentNewCartItemModifier.modifierUuid) {
          if (currentModifier.quantity > 1) {
            newModifiers[index] = { ...currentModifier, quantity: currentModifier.quantity - 1 };
          } else newModifiers[index] = null;
        }
        return newModifiers;
      },
      [...oldModifiers]
    )
    .filter((currentCartItemModifier): currentCartItemModifier is CartItemModifier => Boolean(currentCartItemModifier));
};

export const incrementCartItemModifier = (modifierList: CartItemModifier[], cartItemModifier: CartItemModifier) => {
  const searchedModifier = modifierList.find(({ modifierUuid }) => modifierUuid === cartItemModifier.modifierUuid);

  if (!searchedModifier || cartItemModifier.modifierGroups.length) {
    return [...modifierList, cartItemModifier];
  } else {
    return modifierList.map((currentCartItemModifier) =>
      cartItemModifier.modifierUuid === currentCartItemModifier.modifierUuid
        ? { ...currentCartItemModifier, quantity: currentCartItemModifier.quantity + 1 }
        : currentCartItemModifier
    );
  }
};
