import { UseMutationOptions } from '@tanstack/react-query';

import { useTerminalSessionMutation } from '@/hooks/useTerminalSessionMutation';

import { deleteOrderPayment } from './api';
import { DeletePaymentOrderMutation } from './types';

export const useDeleteOrderPaymentMutation = (
  options?: UseMutationOptions<DeletePaymentOrderMutation, Error, DeletePaymentOrderMutation>
) => {
  return useTerminalSessionMutation(
    (mutationParams: DeletePaymentOrderMutation) => (authenticationPassPhrase) =>
      deleteOrderPayment({ ...mutationParams, authenticationPassPhrase }),
    options
  );
};
