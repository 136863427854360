import { Button, useToast } from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';
import { useId } from 'react';
import { FaLocationArrow } from 'react-icons/fa';

type UserLocationButtonProps = {
  onGeolocation?: PositionCallback;
};

const geolocationOptions = {
  enableHighAccuracy: true,
  maximumAge: 0,
};

export const UserLocationButton = ({ onGeolocation = () => {} }: UserLocationButtonProps) => {
  const toast = useToast();
  const id = useId();

  if (!navigator.geolocation) return null;

  const openToast = () => {
    if (!toast.isActive(id)) {
      toast({
        description: t`We can't find your position. You can go to your browser's settings to activate your location.`,
        duration: 5000,
        id,
        isClosable: true,
        status: 'info',
      });
    }
  };

  const handleError: PositionErrorCallback = (error) => {
    console.warn(`ERROR(${error.code}): ${error.message}`);
    openToast();
  };

  const queryGeolocation = () => {
    if (navigator.permissions?.query) {
      navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
        if (permissionStatus.state === 'granted') {
          navigator.geolocation.getCurrentPosition(onGeolocation);
        } else if (permissionStatus.state === 'prompt') {
          navigator.geolocation.getCurrentPosition(onGeolocation, handleError, geolocationOptions);
        } else if (permissionStatus.state === 'denied') {
          openToast();
        }
      });
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(onGeolocation, handleError, geolocationOptions);
    } else {
      openToast();
    }
  };

  return (
    <Button
      bgColor="white"
      borderRadius={8}
      color="gray.700"
      colorScheme="gray"
      leftIcon={<FaLocationArrow />}
      variant="outline"
      onClick={queryGeolocation}
    >
      <Trans>Use my current location</Trans>
    </Button>
  );
};
