import { Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import type { ReactNode } from 'react';

export enum ModeEnum {
  DEFAULT = 'default',
  OPTIMISTIC = 'optmistic',
}

type AsyncContentProps<Data = unknown> = {
  isError: boolean;
  renderContent: (data: NonNullable<Data>) => JSX.Element;
  ErrorContent?: ReactNode;
  LoadingContent?: ReactNode;
  isLoading?: boolean;
  hasData: boolean;
  data: Data;
  noDataText?: ReactNode;
  mode?: ModeEnum;
};

const renderDefaultContent = <Data,>({
  hasData,
  data,
  renderContent,
  isError,
  isLoading,
  ErrorContent,
  LoadingContent,
  noDataText,
}: AsyncContentProps<Data>) => {
  if (isError) {
    return <>{ErrorContent}</>;
  }

  if (isLoading) {
    return <>{LoadingContent}</>;
  }

  if (hasData && !!data) {
    return renderContent(data);
  }

  return (
    <Text variant="body1" textAlign="center">
      {noDataText ?? <Trans>No data to display.</Trans>}
    </Text>
  );
};

const renderOptimisticContent = <Data,>({
  hasData,
  data,
  renderContent,
  isError,
  isLoading,
  ErrorContent,
  LoadingContent,
  noDataText,
}: AsyncContentProps<Data>) => {
  if (hasData && !!data) {
    return renderContent(data);
  }

  if (isError) {
    return <>{ErrorContent}</>;
  }

  if (isLoading) {
    return <>{LoadingContent}</>;
  }

  return (
    <Text variant="body1" textAlign="center">
      {noDataText ?? <Trans>No data to display.</Trans>}
    </Text>
  );
};

export const AsyncContent = <Data,>({
  ErrorContent,
  LoadingContent,
  data,
  hasData,
  isError,
  isLoading = false,
  noDataText,
  renderContent,
  mode = ModeEnum.OPTIMISTIC,
}: AsyncContentProps<Data>) => {
  const renderMode = {
    [ModeEnum.OPTIMISTIC]: renderOptimisticContent,
    [ModeEnum.DEFAULT]: renderDefaultContent,
  };

  return renderMode[mode]({
    ErrorContent,
    LoadingContent,
    data,
    hasData,
    isError,
    isLoading,
    noDataText,
    renderContent,
  });
};
