import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import ApiError from '@/api/ApiError';
import { useTasterApis } from '@/api/TasterApiContext';

import { restaurantsKeys } from './restaurants.helpers';
import { GetRestaurantRequest, Restaurant } from './types';

export const useRestaurantQueryOptions = {
  queryKey: (requestParams?: GetRestaurantRequest) => [...restaurantsKeys.all, 'list', requestParams],
};

export interface UseRestaurantQueryProps<TData> {
  options?: Omit<
    UseQueryOptions<Restaurant, ApiError, TData, ReturnType<typeof useRestaurantQueryOptions.queryKey>>,
    'queryFn' | 'queryKey'
  >;
  requestParams: GetRestaurantRequest;
}

export const useRestaurantQuery = <TData = Restaurant>({ options, requestParams }: UseRestaurantQueryProps<TData>) => {
  const {
    gatewayClickCollect: { restaurantsApi },
  } = useTasterApis();

  return useQuery({
    ...options,
    queryFn: () => restaurantsApi.getRestaurant(requestParams),
    queryKey: useRestaurantQueryOptions.queryKey(requestParams),
  });
};
