import { Dispatch, SetStateAction, useCallback, useState } from 'react';

const parseJSON = <T>(value: string | null): T | undefined => {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value ?? '');
  } catch {
    console.warn('parsing error on', { value });
    return undefined;
  }
};

type SetValue<T> = Dispatch<SetStateAction<T>>;

export const useSessionStorage = <T>(key: string, initialState: T) => {
  const readValue = useCallback((): T => {
    try {
      const item = window.sessionStorage.getItem(key);
      return item ? (parseJSON(item) as T) : initialState;
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error);
      return initialState;
    }
  }, [initialState, key]);
  const [storedValue, setStoredValue] = useState<T>(readValue);

  const setValue: SetValue<T> = useCallback(
    (value) => {
      try {
        const newValue = value instanceof Function ? value(storedValue) : value;
        window.sessionStorage.setItem(key, JSON.stringify(newValue));
        setStoredValue(newValue);
      } catch (error) {
        console.warn(`Error setting sessionStorage key “${key}”:`, error);
      }
    },
    [storedValue, key]
  );

  return [storedValue, setValue] as const;
};
