import { Box, Stack, Tag, Text } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';

import cartDone from '@/assets/anim/lottie_cart_done.json';
import { KioskMenuCard } from '@/components/Kiosk/KioskMenuCard';
import { MenuItemDetailsButton } from '@/components/Kiosk/MenuItemDetailsButton';
import { LottieAnimation } from '@/components/LottieAnimation';
import useFormatPrice from '@/hooks/useFormatPrice';

export interface CartLottieProps {
  quantity: number;
}

export const CartLottie = ({ quantity }: CartLottieProps) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const [isLottieDisplayed, setIsLottieDisplayed] = useState(false);
  const [prevQuantity, setPrevQuantity] = useState(quantity);

  useEffect(() => {
    if (quantity > prevQuantity) {
      clearTimeout(timeoutRef.current);
      setIsLottieDisplayed(true);
      timeoutRef.current = setTimeout(() => setIsLottieDisplayed(false), 2000);
    }

    setPrevQuantity(quantity);
  }, [prevQuantity, quantity]);

  return isLottieDisplayed ? (
    <LottieAnimation animation={cartDone} sx={{ width: '3rem' }} animationOptions={{ loop: true }} />
  ) : null;
};

export interface MenuItemCardProps {
  currencyCode: string;
  description: string;
  label: string;
  onClick: () => void;
  pictureUrl?: string;
  quantity: number;
  sellingPrice: number;
}

export const MenuItemCard = ({
  currencyCode,
  description,
  label,
  onClick,
  pictureUrl,
  quantity,
  sellingPrice,
}: MenuItemCardProps) => {
  const { formatPrice } = useFormatPrice();

  return (
    <Box position="relative">
      <KioskMenuCard label={label} pictureUrl={pictureUrl} onClick={onClick}>
        <Stack position="relative" flexDirection="row" width="100%" justifyContent="center">
          <Tag
            sx={{
              border: '2px solid #D9D9D9',
              borderRadius: 76,
              padding: '4px 12px',
            }}
          >
            <Text fontSize={{ base: '20px', xl: '24px' }}>{formatPrice(sellingPrice, currencyCode)}</Text>
          </Tag>
          <Box position="absolute" left={0} bottom="-7px">
            <CartLottie quantity={quantity} />
          </Box>
        </Stack>
      </KioskMenuCard>
      <MenuItemDetailsButton
        bottom={5}
        currencyCode={currencyCode}
        description={description}
        label={label}
        onAddItem={onClick}
        pictureUrl={pictureUrl}
        position="absolute"
        price={sellingPrice}
        right={5}
        size="sm"
      />
    </Box>
  );
};
