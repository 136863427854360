export const getWebViewType = () => {
  let webviewType = 'none';

  const phoneHtml = document.getElementById('phone')?.innerHTML;
  const userAgent = navigator.userAgent;

  if (
    navigator.platform.substr(0, 2) === 'iP' &&
    phoneHtml &&
    phoneHtml.indexOf('tel:') == -1 &&
    !/(CriOS|FxiOS|OPiOS|mercury)/i.test(userAgent)
  ) {
    webviewType = 'webview_ios';
  }

  if (userAgent.includes('wv')) {
    webviewType = 'webview_android';
  }

  return webviewType;
};
