import { UseMutationOptions } from '@tanstack/react-query';

import useAuthenticatedMutation from '@/hooks/useAuthenticatedMutation';

import { pairTerminal } from './api';
import { TerminalAuthInfo } from './types';

export interface PairTerminalRequestParams {
  locationUuid: string;
  terminalPairingCode: string;
  previousTerminalUuid?: string;
}

export const usePairTerminalMutation = (
  options: UseMutationOptions<TerminalAuthInfo, Error, PairTerminalRequestParams>
) => {
  return useAuthenticatedMutation(
    (mutationParams: PairTerminalRequestParams) => (token) => pairTerminal({ ...mutationParams, token }),
    options
  );
};
