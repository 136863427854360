import { Button, chakra, Modal, ModalContent, useDisclosure } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { useEffect } from 'react';

import OnsiteWaitingScreen from '@/assets/img/onsite_waiting_screen_background.jpg';

const StyledModalContent = chakra(ModalContent, {
  baseStyle: {
    backgroundImage: OnsiteWaitingScreen,
    justifyContent: 'center',
    backgroundPosition: 'center',
    backgroundRepeat: 'repeat',
    display: 'flex',
    backgroundSize: '100%',
    alignItems: 'center',
  },
});

const eventTypes = ['click', 'keypress', 'mousemove', 'mousedown', 'scroll', 'touchmove', 'pointermove'];

const addEventListeners = (listener: EventListener) => {
  eventTypes.forEach((type) => {
    window.addEventListener(type, listener, true);
  });
};

const removeEventListeners = (listener: EventListener) => {
  eventTypes.forEach((type) => {
    window.removeEventListener(type, listener, true);
  });
};

export interface TouchToStartModalProps {
  defaultIsOpen?: boolean;
  id?: string;
  isOpen?: boolean;
  onClose?(): void;
  onOpen?(): void;
}

export const TouchToStartModal = (props: TouchToStartModalProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure(props);

  useEffect(() => {
    const startTimeOut = () =>
      setTimeout(() => {
        onOpen();
      }, 60000);

    const resetOpeningTimeout = () => {
      if (!isOpen) {
        clearTimeout(openModalTimeOut);
        openModalTimeOut = startTimeOut();
      }
    };

    let openModalTimeOut = startTimeOut();
    addEventListeners(resetOpeningTimeout);

    return () => {
      removeEventListeners(resetOpeningTimeout);
      clearTimeout(openModalTimeOut);
    };
  }, [isOpen, onOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <StyledModalContent onClick={onClose}>
        <Button padding={12} fontSize={32} background="#900E08" color="white" maxWidth={530} marginTop={40}>
          <Trans>Touch to start</Trans>
        </Button>
      </StyledModalContent>
    </Modal>
  );
};
