import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { validateOrder } from '@/api/api';
import ApiError from '@/api/ApiError';
import { CartRequest, ValidOrder } from '@/api/types';

export type UseValidateWebOrderMutationMutationOptions = Omit<
  UseMutationOptions<ValidOrder, ApiError, CartRequest>,
  'mutationFn'
>;

export const useValidateOrderMutation = (options?: UseValidateWebOrderMutationMutationOptions) => {
  return useMutation({
    ...options,
    mutationFn: validateOrder,
  });
};
