import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import ApiError from '@/api/ApiError';
import { useOnSiteInfo } from '@/hooks/useOnSiteInfo';

type MutationFunction<TData, TVariables> = (variables: TVariables) => (token: string) => Promise<TData>;

export const useTerminalSessionMutation = <TMutationFnData, TError, TVariables>(
  mutationFn: MutationFunction<TMutationFnData, TVariables>,
  options?: UseMutationOptions<TMutationFnData, TError, TVariables>
) => {
  const { authenticationPassPhrase } = useOnSiteInfo();
  const navigate = useNavigate();

  return useMutation({
    ...options,
    mutationFn: (requestParams) => mutationFn(requestParams)(authenticationPassPhrase),
    onError: (error) => {
      if (error instanceof ApiError && error.status === 401) navigate('/onSite/terminalDisconnected');
    },
  });
};
