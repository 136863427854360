import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { Box, IconButton, Text } from '@chakra-ui/react';
import { ReactNode, useState } from 'react';

interface Props {
  defaultValue: number;
  fontSize?: string;
  isDisabled?: boolean;
  label?: ReactNode;
  min?: number;
  onChange?: (number: number) => void;
  size?: string;
}

export const NumberInput = ({
  defaultValue,
  fontSize = 'md',
  isDisabled = false,
  label,
  min,
  onChange = () => {},
  size = 'xs',
}: Props) => {
  const [number, setNumber] = useState(defaultValue);

  const decrementValue = () => {
    const newNumber = number - 1;
    setNumber(newNumber);
    onChange(newNumber);
  };

  const incrementValue = () => {
    const newNumber = number + 1;
    setNumber(newNumber);
    onChange(newNumber);
  };

  return (
    <Box display="flex" m="auto" alignItems="center" flexDirection="column" maxW="320">
      {label && (
        <Text fontSize="xl" fontWeight={500}>
          {label}
        </Text>
      )}
      <Box display="flex" alignItems="center" justifyContent="center" width="100%" mt={2}>
        <IconButton
          aria-label="Moins 1"
          borderRadius="50%"
          borderWidth={2}
          icon={<MinusIcon />}
          variant="outline"
          size={size}
          isDisabled={number === min}
          onClick={decrementValue}
        />
        <Text fontSize={fontSize} width="3ch" textAlign="center">
          {number}
        </Text>
        <IconButton
          aria-label="Plus 1"
          borderRadius="50%"
          borderWidth={2}
          icon={<AddIcon />}
          isDisabled={isDisabled}
          onClick={incrementValue}
          size={size}
          variant="outline"
        />
      </Box>
    </Box>
  );
};
