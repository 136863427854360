import { useKeycloak } from '@react-keycloak/web';
import { QueryFunctionContext, QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useCallback } from 'react';

type QueryFunction<T = unknown> = (context: QueryFunctionContext) => (token: string) => T | Promise<T>;

const useAuthenticatedQuery = <TQueryFnData, TError, TData, TQueryKey extends QueryKey = QueryKey>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData>,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>
) => {
  const {
    keycloak: { isTokenExpired, updateToken, login, token },
  } = useKeycloak();

  const authQueryFn = useCallback(
    async (context: QueryFunctionContext) => {
      if (isTokenExpired()) {
        try {
          await updateToken(-1);
        } catch (e) {
          login();
        }
      }

      return queryFn(context)(token as string);
    },
    [isTokenExpired, login, queryFn, token, updateToken]
  );

  return useQuery(queryKey, (context) => authQueryFn(context), options);
};

export default useAuthenticatedQuery;
