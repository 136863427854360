import { useMemo } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';

import { ConceptInformation } from '@/api/gateway-click-collect/configurations';
import { useMenuDataContext } from '@/contexts/MenuDataProvider';

import { formatCategories } from './CategoriesDataProvider.helpers';

export interface CategoriesDataContext {
  categories?: ReturnType<typeof formatCategories>;
  conceptInformation: ConceptInformation;
  currencyCode: string;
}

export const useCategoriesDataContext = () => useOutletContext<CategoriesDataContext>();

export const CategoriesDataProvider = () => {
  const { conceptInformation, menu, outOfStocks, restaurant } = useMenuDataContext();

  const contextValue = useMemo((): CategoriesDataContext => {
    const categories = formatCategories(menu, outOfStocks);

    return {
      categories,
      conceptInformation,
      currencyCode: restaurant.currencyCode,
    };
  }, [conceptInformation, menu, outOfStocks, restaurant]);

  return <Outlet context={contextValue satisfies CategoriesDataContext} />;
};
