import { Box, Flex, Image } from '@chakra-ui/react';
import { ReactNode } from 'react';

import footerLogo from '@/assets/img/taster_footer.png';

import { Header } from './Header';

export interface AppLayoutProps {
  children: ReactNode;
  hasArrowBack?: boolean;
  hasSettingsButton?: boolean;
  maxWidthBreakpoints?: Record<string, string>;
  showLogo?: boolean;
  showWebviewWarning?: boolean;
  title: ReactNode;
  url?: string;
}

export const AppLayout = ({
  children,
  hasArrowBack = false,
  hasSettingsButton = false,
  maxWidthBreakpoints,
  showLogo = false,
  showWebviewWarning,
  title,
  url,
}: AppLayoutProps) => {
  return (
    <Box minHeight="100%" position="relative" sx={{ display: 'flex', flexDirection: 'column' }}>
      <Header
        hasArrowBack={hasArrowBack}
        hasSettingsButton={hasSettingsButton}
        showWebviewWarning={showWebviewWarning}
        sx={{
          zIndex: 1,
          position: 'sticky',
          top: 0,
          left: 0,
        }}
        title={title}
        url={url}
      />
      <Box maxW={{ base: '100%', md: '640px', ...maxWidthBreakpoints }} sx={{ marginX: 'auto', flex: 1 }}>
        {children}
      </Box>
      {showLogo && (
        <Flex color="gray.disabled" justifyContent="center" alignItems="center" paddingY={4} justifySelf="flex-end">
          <Image src={footerLogo} alt="Taster" width={250} />
        </Flex>
      )}
    </Box>
  );
};
