import {
  GetRestaurantMenuRequest,
  GetRestaurantOpeningHoursRequest,
  GetRestaurantOutOfStocksRequest,
  ListRestaurantsRequest,
} from './types';

export const restaurantsKeys = {
  all: ['restaurants'] as const,
  getMenu: (requestParams?: GetRestaurantMenuRequest) => [...restaurantsKeys.all, 'getMenu', requestParams] as const,
  list: (requestParams?: ListRestaurantsRequest) => [...restaurantsKeys.all, 'list', requestParams],
  getOutOfStocks: (requestParams?: GetRestaurantOutOfStocksRequest) => [
    ...restaurantsKeys.all,
    'getOutOfStocks',
    requestParams,
  ],
  getOpeningHours: (requestParams?: GetRestaurantOpeningHoursRequest) => [
    ...restaurantsKeys.all,
    'getOpeningHours',
    requestParams,
  ],
  getOpenStatus: (requestParams?: GetRestaurantOpeningHoursRequest) => [
    ...restaurantsKeys.all,
    'getOpenStatus',
    requestParams,
  ],
};
