import { useCallback, useEffect } from 'react';

export interface UseUpdateMetadataProps {
  description?: string | null;
  title?: string | null;
}

const defaultValue = {
  description: 'Taster click and collect app to order delicious food',
  title: 'Taster click and collect',
};

export const useUpdateMetadata = ({ description, title }: UseUpdateMetadataProps) => {
  const setTitle = useCallback((newTitle?: string | null) => {
    if (!newTitle) return;

    document.title = newTitle;
  }, []);

  const setDescription = useCallback((newDescription?: string | null) => {
    if (!newDescription) return;

    document.querySelector('meta[name="description"]')?.setAttribute('content', newDescription);
  }, []);

  useEffect(() => {
    setTitle(title);
    setDescription(description);

    return () => {
      setTitle(defaultValue.title);
      setDescription(defaultValue.description);
    };
  });
};
