import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { useTasterApis } from '@/api/TasterApiContext';

import { configurations } from './configurations.helpers';
import { GetPaymentProviderConfigurationRequest, PaymentProviderInformation } from './types';

export interface PaymentProviderConfigurationQueryProps<TData> {
  requestParams: GetPaymentProviderConfigurationRequest;
  options?: Omit<
    UseQueryOptions<
      PaymentProviderInformation,
      Error,
      TData,
      ReturnType<(typeof configurations)['getPaymentProviderConfiguration']>
    >,
    'queryFn' | 'queryKey'
  >;
}

export const usePaymentProviderConfigurationQuery = <TData = PaymentProviderInformation>({
  requestParams,
  options = {},
}: PaymentProviderConfigurationQueryProps<TData>) => {
  const {
    gatewayClickCollect: { configurationsApi },
  } = useTasterApis();

  return useQuery(
    configurations.getPaymentProviderConfiguration(requestParams),
    () => configurationsApi.getPaymentProviderConfiguration(requestParams),
    options
  );
};
