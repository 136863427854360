import { Button, Divider, Flex, Heading, List, ListItem } from '@chakra-ui/react';

import { MenuElement } from '@/api/types';
import { MenuItem } from '@/routes/MenuPage/MenuItem';

import { Category } from './types';

interface Props {
  category: Category;
  currencyCode: string;
  onItemClick: (item: MenuElement) => void;
}

export const MenuCategory = ({ category, currencyCode, onItemClick }: Props) => {
  const { menuElements, label } = category;

  return (
    <Flex justifyContent="space-between" w="100%" direction="column" gap={4}>
      <Heading as="h2" fontSize="xl" fontWeight="600">
        {label}
      </Heading>
      <Divider borderColor="#cacfd8" opacity={1} />
      <List>
        {menuElements?.map((menuElement, index) => {
          const { available, menuElementUuid } = menuElement;

          return (
            <ListItem
              key={menuElementUuid}
              py={3}
              borderBottom={index < menuElements.length - 1 ? '0.5px solid #cacfd8' : ''}
            >
              <Flex align="center">
                <Button
                  _active={{ all: 'unset', flex: '1' }}
                  _disabled={{ all: 'unset', _hover: { cursor: 'not-allowed' } }}
                  _hover={{ all: 'unset', cursor: 'pointer', flex: '1' }}
                  isDisabled={!available}
                  onClick={() => onItemClick(menuElement)}
                  sx={{ all: 'unset', flex: '1' }}
                >
                  <MenuItem menuItem={menuElement} currencyCode={currencyCode} />
                </Button>
              </Flex>
            </ListItem>
          );
        })}
      </List>
    </Flex>
  );
};
