import { Box, Spinner } from '@chakra-ui/react';

const PageLoader = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      pos="absolute"
      top="0"
      left="0"
      right="0"
      bottom="0"
    >
      <Spinner size="xl" />
    </Box>
  );
};

export default PageLoader;
