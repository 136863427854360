import { Box, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import safariBar from '@/assets/img/safari_bar.png';

const WebviewModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => unknown }) => {
  return (
    <Modal id="webview-modal" isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody maxW={412} m="auto">
          <Box display="flex" height="90vh" justifyContent="space-between" flexDirection="column" pt={31}>
            <Box>
              <Text fontSize={82} textAlign="center">
                👏🏽
              </Text>
              <Text fontSize={18} textAlign="center" fontWeight={500} color="#757575">
                <Trans>Great choice!</Trans>
              </Text>
            </Box>
            <Box>
              <Text fontSize={18} color="#757575" fontWeight={500} textAlign="center">
                <Trans>Continue in a browser or you won’t be able to complete your payment.</Trans>
              </Text>
            </Box>
            <Box>
              <Box bg="#FAEED4" borderRadius={8} py={4} px={3}>
                <Text fontWeight={500} fontSize={18} textAlign="center">
                  <Trans>Click on the Safari icon to complete your order</Trans>
                </Text>
              </Box>
              <Box mt={2}>
                <Image src={safariBar} />
              </Box>
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default WebviewModal;
