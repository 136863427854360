import { Button, Progress, SystemStyleObject, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCart } from '@/contexts/cart';
import { useOrder } from '@/contexts/order';

export const NextCustomerProgressBar = ({
  locationUuid,
  onNextCustomer = () => {},
  sx = {},
}: {
  locationUuid: string;
  onNextCustomer?: () => void;
  sx?: SystemStyleObject;
}) => {
  const [nextCustomerCountdown, setNextCustomerCountdown] = useState(0);
  const navigate = useNavigate();
  const { cart, resetCart } = useCart();
  const { order, setOrder } = useOrder();
  const [, setPiSecret] = useLocalStorage('PI_SECRET', null);

  const goToHomePage = useCallback(() => {
    onNextCustomer();
    if (cart) resetCart();
    if (order) setOrder(null);
    setPiSecret(null);
    navigate(`/onSite/${locationUuid}`, { state: { startSession: true } });
  }, [onNextCustomer, cart, resetCart, order, setOrder, setPiSecret, navigate, locationUuid]);

  useEffect(() => {
    const nextCustomerInterval = setInterval(() => {
      setNextCustomerCountdown((prevTime) => {
        if (prevTime >= 6000) {
          goToHomePage();
        }

        return prevTime + 1;
      });
    }, 10);

    return () => clearInterval(nextCustomerInterval);
  }, [goToHomePage]);

  return (
    <Button
      sx={sx}
      position="relative"
      padding={0}
      background="white"
      marginTop={16}
      width="300px"
      borderRadius={8}
      border="1px solid #D0D5DD"
      height="80px"
      onClick={goToHomePage}
    >
      <Text zIndex={2} cursor="pointer" color="black" position="absolute" paddingX={6} fontSize={32}>
        <Trans>Next customer</Trans>
      </Text>
      <Progress
        height="100%"
        value={nextCustomerCountdown / 60}
        width="100%"
        backgroundColor="white"
        colorScheme="gray.300"
        borderRadius={8}
      />
    </Button>
  );
};
