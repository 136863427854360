import { Box, RadioProps, useRadio } from '@chakra-ui/react';

function PickupTypeCard(props: RadioProps) {
  // TODO remove deprecated
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const inputProps = getInputProps();

  const checkboxProps = getCheckboxProps();

  return (
    <Box as="label">
      <input {...inputProps} />
      <Box
        {...checkboxProps}
        cursor="pointer"
        borderWidth="2px"
        borderRadius="md"
        _checked={{
          borderColor: 'black.500',
        }}
        px={4}
        py={2}
      >
        {props.children}
      </Box>
    </Box>
  );
}

export default PickupTypeCard;
