import { useLocalStorage } from '@uidotdev/usehooks';
import dayjs from 'dayjs';
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo } from 'react';

import type { Order } from '@/api/types';
import { OrderStatus } from '@/types';

type OrderContextValue = {
  order: Order | null;
  isPreOrder: boolean;
  orderStatus?: OrderStatus;
  setOrder: Dispatch<SetStateAction<Order | null>>;
};

const OrderContext = createContext<OrderContextValue | undefined>(undefined);

const useOrder = () => {
  const context = useContext(OrderContext);

  if (!context) throw new Error('Make sure you are using useOrder within a OrderProvider');

  return context;
};

const statusMapping: Record<string, OrderStatus> = {
  processable: OrderStatus.ACCEPTED,
  initiated: OrderStatus.ACCEPTED,
  processing_payment: OrderStatus.ACCEPTED,
  payment_succeeded: OrderStatus.ACCEPTED,
  created: OrderStatus.ACCEPTED,
  accepted: OrderStatus.ACCEPTED,
  ready_for_dispatch: OrderStatus.READY,
  delivered: OrderStatus.DELIVERED,
  payment_failed: OrderStatus.REJECTED,
  creation_failed: OrderStatus.REJECTED,
};

type OrderProviderProps = { children: ReactNode };

const OrderProvider = ({ children }: OrderProviderProps) => {
  const [order, setOrder] = useLocalStorage<Order | null>('TASTER_ORDER', null);

  const value = useMemo(
    () => ({
      order,
      setOrder,
      isPreOrder: dayjs(order?.ready_at).diff(dayjs(order?.placed_at), 'minute') > 7,
      orderStatus: order ? statusMapping[order.status] : undefined,
    }),
    [order, setOrder]
  );

  return <OrderContext.Provider value={value}>{children}</OrderContext.Provider>;
};

export { OrderProvider, useOrder };
