import { DeleteIcon } from '@chakra-ui/icons';
import { Card, Grid, IconButton, Image, Stack } from '@chakra-ui/react';
import { t } from '@lingui/macro';

import { ModifiersList } from '@/routes/CartPage/ModifiersList';
import { ItemCart } from '@/types';

import { CartItemDetails } from './CartItemDetails';
import { OutOfStocks } from './CartPage.helpers';

export interface CartItemCardProps {
  cartItem: ItemCart;
  currencyCode: string;
  onDelete: (cartItem: ItemCart) => void;
  outOfStocks?: OutOfStocks | null;
}

export const CartItemCard = ({ cartItem, currencyCode, onDelete, outOfStocks }: CartItemCardProps) => {
  const { imageUrl, selectedModifierGroups, label, sellingPrice, quantity, menuItemUuid } = cartItem;
  const isOos = outOfStocks?.errorType === 'item-oos' && outOfStocks.menuItemUuid === menuItemUuid;

  return (
    <Card boxShadow="md" borderRadius="2xl" padding={4}>
      <Grid
        gridTemplateColumns="auto 1fr"
        gridTemplateRows="1fr auto"
        templateAreas={`'picture details'
                        'picture modifiers'
                        '. delete'`}
        columnGap={4}
      >
        <Image
          aspectRatio="16 / 9"
          borderRadius={8}
          maxWidth="104px"
          width="100%"
          aria-hidden
          src={imageUrl}
          gridArea="picture"
        />
        <CartItemDetails
          currencyCode={currencyCode}
          isOos={isOos}
          label={label}
          price={sellingPrice}
          quantity={quantity}
          showQuantity={quantity > 1}
          sx={{ gridArea: 'details' }}
        />
        <Stack paddingLeft={6} gridArea="modifiers" gap={0}>
          {selectedModifierGroups?.map(({ modifierGroupUuid, selectedModifiers }) => {
            if (selectedModifiers?.length) {
              return (
                <ModifiersList
                  currencyCode={currencyCode}
                  key={modifierGroupUuid}
                  modifiers={selectedModifiers}
                  outOfStocks={outOfStocks}
                />
              );
            }
          })}
        </Stack>
        <IconButton
          colorScheme="gray"
          aria-label={t`Delete ${label}`}
          icon={<DeleteIcon width="24px" height="24px" />}
          gridArea="delete"
          variant="ghost"
          size="lg"
          justifySelf="end"
          isRound
          marginTop={4}
          onClick={() => onDelete(cartItem)}
        />
      </Grid>
    </Card>
  );
};
