import { UseQueryOptions } from '@tanstack/react-query';

import ApiError from '@/api/ApiError';
import { useTasterApis } from '@/api/TasterApiContext';
import useAuthenticatedQuery from '@/hooks/useAuthenticatedQuery';

import { locationsKeys } from './locations.helpers';
import { UserLocation } from './types';

interface HookProps<TData> {
  options?: Omit<
    UseQueryOptions<UserLocation[], ApiError, TData, ReturnType<(typeof locationsKeys)['listUserLocations']>>,
    'queryFn' | 'queryKey'
  >;
}

export const useUserLocationsQuery = <TData = UserLocation[]>({ options }: HookProps<TData> = {}) => {
  const {
    gatewayClickCollect: { locationsApi },
  } = useTasterApis();

  return useAuthenticatedQuery(
    locationsKeys.listUserLocations(),
    (token) => () => locationsApi.getUserLocations(token),
    options
  );
};
