import { HStack, Stack, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import dayjs from 'dayjs';

import { RestaurantShift } from '@/api/gateway-click-collect/restaurants';
import { sortRestaurantShifts } from '@/helpers/openingHours.helpers';

export interface ShiftHoursProps {
  weekday: number;
  weekdayShifts?: RestaurantShift[] | null;
}

export const RestaurantInfoShift = ({ weekday, weekdayShifts }: ShiftHoursProps) => {
  const sortedShifts = weekdayShifts ? sortRestaurantShifts(weekdayShifts) : weekdayShifts;

  return (
    <HStack align="baseline" maxWidth={600}>
      <Text fontWeight={700} flex={1} textAlign={'left'}>
        {dayjs().isoWeekday(weekday).format('dddd')}
      </Text>
      <Stack spacing={0} flex={3} textAlign={'right'}>
        {sortedShifts?.length ? (
          sortedShifts.map((shift, index) => {
            return (
              <Text key={index}>
                {dayjs(shift.startTime, 'HH:mm:ss').format('LT')} - {dayjs(shift.endTime, 'HH:mm:ss').format('LT')}
              </Text>
            );
          })
        ) : (
          <Text>
            <Trans>Closed</Trans>
          </Text>
        )}
      </Stack>
    </HStack>
  );
};
