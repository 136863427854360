import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { useTasterApis } from '@/api/TasterApiContext';

import { ResponseError, ValidateOrderRequest, ValidOrder } from './types';

export const useValidateOrderMutation = <TContext = unknown>(
  options?: Omit<UseMutationOptions<ValidOrder, ResponseError, ValidateOrderRequest, TContext>, 'mutationFn'>
) => {
  const {
    gatewayClickCollect: { ordersApi },
  } = useTasterApis();

  return useMutation({
    ...options,
    mutationFn: (requestParams) => {
      return ordersApi.validateOrder(requestParams);
    },
  });
};
