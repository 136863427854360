import { useEffect, useRef } from 'react';

import dataLayer from '@/helpers/dataLayer.helpers';

import { useOnSiteInfo } from './useOnSiteInfo';

const getPageName = (): string | undefined => {
  const pathname = window.location.pathname;
  const homeRegExp = new RegExp('^/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$');

  if (pathname === '/') {
    return 'location_finder';
  } else if (pathname.includes('/menu')) {
    return 'menu_displayed';
  } else if (pathname.includes('/checkout')) {
    return 'order_checkout';
  } else if (pathname.includes('/confirmPayment')) {
    return 'payment_confirmation';
  } else if (pathname.includes('/orderStatus')) {
    return 'order_finalized';
  } else if (homeRegExp.test(pathname)) {
    return 'restaurant_page';
  }
};

const usePageViewTracker = (kitchenLabel?: string, conceptLabel?: string) => {
  const done = useRef(false);

  const { hasPairedTerminal } = useOnSiteInfo();

  useEffect(() => {
    const pageName = getPageName();

    if (pageName && !done.current) {
      dataLayer.logPageView(pageName, kitchenLabel, conceptLabel, hasPairedTerminal ? 'onsite' : 'online');

      done.current = true;
    }
  }, [kitchenLabel, conceptLabel, hasPairedTerminal]);
};

export default usePageViewTracker;
