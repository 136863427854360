import { Box, Button, RadioProps, Stack, useRadio, useRadioGroup } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import dayjs from 'dayjs';
import { useState } from 'react';

export interface PickupTimeSelectorProps {
  onSelect: (time: string) => void;
  preOrderTimes: string[];
}

const TimeOption = (props: RadioProps) => {
  // TODO remove deprecated
  const { getCheckboxProps, getInputProps } = useRadio(props);
  const inputProps = getInputProps();
  const checkboxProps = getCheckboxProps();

  return (
    <Box as="label" cursor="pointer">
      <input {...inputProps} />
      <Box
        {...checkboxProps}
        textAlign="center"
        _checked={{
          backgroundColor: '#EEEEEE',
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export const PickupTimeSelector = ({ onSelect, preOrderTimes }: PickupTimeSelectorProps) => {
  const [pickupTime, setPickupTime] = useState<string | undefined>();

  const { getRadioProps, getRootProps, value } = useRadioGroup({
    onChange: setPickupTime,
    value: pickupTime,
  });

  const handleConfirm = () => {
    if (value) {
      onSelect(value.toString());
    }
  };

  return (
    <Stack gap={2}>
      <Box borderWidth="2px" borderRadius="md" maxHeight={176} overflowY="auto" py={2}>
        <Stack {...getRootProps()} gap={2} mb={4}>
          {preOrderTimes.map((preOrderTime) => {
            const radioProps = getRadioProps({ value: preOrderTime });
            const formattedTime = dayjs(preOrderTime, 'HH:mm:ss').format('LT');

            return (
              <TimeOption {...radioProps} key={preOrderTime}>
                {formattedTime}
              </TimeOption>
            );
          })}
        </Stack>
      </Box>
      <Button width="100%" isDisabled={!value} onClick={handleConfirm}>
        <Trans>Confirm</Trans>
      </Button>
    </Stack>
  );
};
