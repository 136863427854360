import { RestaurantWithConcept } from '@/types';

export enum MostSelledBrandsLabelsEnum {
  PEPE_CHICKEN = 'Pepe Chicken',
  STAR_SMASH = 'StarSmash',
}

export const getSortedRestaurantsWithConcept = (restaurantWithConcepts: RestaurantWithConcept[]) => {
  const brandsOrder: string[] = [MostSelledBrandsLabelsEnum.PEPE_CHICKEN, MostSelledBrandsLabelsEnum.STAR_SMASH];

  return [...restaurantWithConcepts].sort(
    (
      { concept: { conceptName: conceptLabelA }, status: statusA },
      { concept: { conceptName: conceptLabelB }, status: statusB }
    ) => {
      if (statusA === 'open' && statusB !== 'open') return -1;
      if (statusA !== 'open' && statusB === 'open') return 1;

      const indexA = brandsOrder.indexOf(conceptLabelA);
      const indexB = brandsOrder.indexOf(conceptLabelB);

      if (indexA !== -1 && indexB !== -1) return indexA - indexB;

      if (indexA !== -1 || indexB !== -1) {
        if (indexA === -1) return 1;
        if (indexB === -1) return -1;
      }

      return conceptLabelA.localeCompare(conceptLabelB, undefined, { sensitivity: 'base', ignorePunctuation: true });
    }
  );
};
