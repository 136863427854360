import { useBoolean } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TerminalStatusEnum } from '@/api/types';
import { useTerminalStatusMutation } from '@/api/useTerminalStatusMutation';
import { useOnSiteInfo } from '@/hooks/useOnSiteInfo';

export interface UsePollingTerminalStatusProps {
  delay?: number;
  locationUuid?: string | null;
  maxTry?: number;
  terminalUuid?: string | null;
}

export const usePollingTerminalStatus = ({
  delay = 1000,
  locationUuid,
  maxTry = 3,
  terminalUuid,
}: UsePollingTerminalStatusProps) => {
  const navigate = useNavigate();
  const { hasPairedTerminal } = useOnSiteInfo();
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useBoolean(false);

  const { mutate, isError } = useTerminalStatusMutation({
    onSuccess: (response) => {
      if (response.status === TerminalStatusEnum.READY) {
        setIsLoading.off();
      } else if (count < maxTry) {
        if (locationUuid && terminalUuid) {
          setCount(count + 1);
          setTimeout(() => {
            mutate({ locationUuid, terminalUuid });
          }, delay);
        }
      } else {
        setIsLoading.off();
        navigate('/onSite/terminalDisconnected');
      }
    },
    onError: () => {
      setIsLoading.off();
      navigate('/onSite/terminalDisconnected');
    },
  });

  useEffect(() => {
    if (hasPairedTerminal && locationUuid && terminalUuid) {
      setIsLoading.on();
      mutate({ locationUuid, terminalUuid });
    }
  }, [hasPairedTerminal, locationUuid, mutate, setIsLoading, terminalUuid]);

  return { isLoading, isError };
};
