import { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { CartItemModifierGroup } from '@/api/gateway-click-collect/orders';
import { AddModifierGroup } from '@/components/AddModifierGroup';
import { useCart } from '@/contexts/cart';
import dataLayer from '@/helpers/dataLayer.helpers';
import { updateCart } from '@/helpers/menu.helpers';
import { useItemKioskDataLayout } from '@/layouts/AddItemDataLayout';

import { getDeprecatedItemCart } from './AddItemPage.helpers';

export const AddItemPage = () => {
  const {
    conceptInformation,
    currencyCode,
    currentItem,
    pictures,
    kitchenLabel,
    restaurantLabel,
    kitchenInstructions,
    restaurantAddress,
  } = useItemKioskDataLayout();

  const modifierGroups = currentItem.modifierGroups ?? [];

  const { restaurantPlatformId, categoryUuid } = useParams() as { categoryUuid: string; restaurantPlatformId: string };

  const [activeModifierGroupIndex, setActiveModifierGroupIndex] = useState(0);

  const isOnLastModifierGroup = activeModifierGroupIndex === modifierGroups.length - 1;

  const navigate = useNavigate();

  const { cart, setCart } = useCart();

  const draftCartItemModifierGroups = useRef<CartItemModifierGroup[]>([]);

  const handleItemSelect = (newCartItemModifierGroup?: CartItemModifierGroup) => {
    if (newCartItemModifierGroup) {
      draftCartItemModifierGroups.current = [...draftCartItemModifierGroups.current, newCartItemModifierGroup];
    }

    if (isOnLastModifierGroup) {
      const itemCart = getDeprecatedItemCart({
        cartItemModifierGroups: draftCartItemModifierGroups.current,
        item: currentItem,
        picture: pictures[currentItem.menuItemUuid],
      });

      const cartItems = updateCart({
        item: itemCart,
        cart,
      });

      setCart({
        items: cartItems,
        kitchenLabel,
        conceptLabel: conceptInformation.conceptName,
        accessInstructions: kitchenInstructions,
        restaurantAddress: restaurantAddress,
        restaurantName: restaurantLabel,
        restaurantPlatformId,
      });

      dataLayer.logItemAdded(kitchenLabel, conceptInformation.conceptName, itemCart, 'onsite');

      navigate(`/onSite/menu/${restaurantPlatformId}/categories/${categoryUuid}`);
    } else {
      setActiveModifierGroupIndex(activeModifierGroupIndex + 1);
    }
  };

  const activeModifierGroup = modifierGroups[activeModifierGroupIndex];

  const handleBackButtonClick = () => {
    if (activeModifierGroupIndex) {
      setActiveModifierGroupIndex(activeModifierGroupIndex - 1);

      const activeModifierGroupUuid = modifierGroups[activeModifierGroupIndex - 1].modifierGroupUuid;

      draftCartItemModifierGroups.current = draftCartItemModifierGroups.current.filter(
        ({ modifierGroupUuid }) => modifierGroupUuid !== activeModifierGroupUuid
      );
    } else navigate(`/onSite/menu/${restaurantPlatformId}/categories/${categoryUuid}`);
  };

  return (
    <AddModifierGroup
      key={activeModifierGroup.modifierGroupUuid}
      onSelect={handleItemSelect}
      onSkipModifier={handleItemSelect}
      onBackButtonClick={handleBackButtonClick}
      restaurantPlatformId={restaurantPlatformId}
      modifierGroup={activeModifierGroup}
      pictures={pictures}
      currencyCode={currencyCode}
    />
  );
};
