import { WarningTwoIcon } from '@chakra-ui/icons';
import { Grid, SystemStyleObject, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import useFormatPrice from '@/hooks/useFormatPrice';

export interface CartItemDetailsProps {
  currencyCode: string;
  fontWeight?: number;
  formatOptions?: Intl.NumberFormatOptions;
  isOos?: boolean;
  label: string;
  price: number;
  quantity: number;
  showQuantity?: boolean;
  sx?: SystemStyleObject;
}

export const CartItemDetails = ({
  currencyCode,
  fontWeight = 700,
  formatOptions,
  isOos = false,
  label,
  price,
  quantity,
  showQuantity = false,
  sx,
}: CartItemDetailsProps) => {
  const { formatPrice } = useFormatPrice();
  const formattedPrice = formatPrice(price * quantity, currencyCode, { maximumFractionDigits: 2, ...formatOptions });

  return (
    <Grid
      alignItems="center"
      color={isOos ? 'blackAlpha.500' : 'black'}
      columnGap={2}
      gridTemplateColumns="1fr auto"
      justifyContent="space-between"
      fontWeight={fontWeight}
      sx={sx}
    >
      <Text fontSize="2xl" wordBreak="break-all">
        {showQuantity && quantity ? `${quantity}x ${label}` : label}
      </Text>
      <Text visibility={price ? 'visible' : 'hidden'} fontSize="2xl">
        {formattedPrice}
      </Text>
      {isOos && (
        <Text color="gray.600" fontSize="md" gridColumn="1 / -1">
          <WarningTwoIcon fontSize="inherit" color="orange.300" marginBottom={1} /> <Trans>Item unavailable</Trans>
        </Text>
      )}
    </Grid>
  );
};
