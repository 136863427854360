import { Box, Flex, Heading, Stack, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { keyBy } from 'lodash-es';
import { useEffect } from 'react';
import { useImmer } from 'use-immer';

import { ModifierGroup } from '@/api/types';
import { ModifiersRadioGroup } from '@/components/ModifiersRadioGroup';
import ModifiersSelectGroup from '@/components/ModifiersSelectGroup';
import type { ModifierCart } from '@/types';

export interface ModifierGroupInputProps {
  currencyCode: string;
  modifierGroup: ModifierGroup;
  onUpdateSelectedModifierGroup: (modifierGroup: ModifierGroup, selectedModifiers: ModifierCart[]) => void;
}

interface SubHeadingProps {
  min: number;
}

const SubHeading = ({ min }: SubHeadingProps) => {
  if (min > 1) {
    return (
      <Text fontSize="sm" color="gray.500" fontWeight="400">
        <Trans>Choose at least {min}</Trans>
      </Text>
    );
  }

  if (min === 1) {
    return (
      <Text fontSize="sm" color="gray.500" fontWeight="400">
        <Trans>Mandatory</Trans>
      </Text>
    );
  }

  return (
    <Text fontSize="sm" color="gray.500" fontWeight="400">
      <Trans>Optional</Trans>
    </Text>
  );
};

export const ModifierGroupInput = ({
  currencyCode,
  modifierGroup,
  onUpdateSelectedModifierGroup,
}: ModifierGroupInputProps) => {
  const { label, included: min, max, modifiers } = modifierGroup;
  const isRequired = min > 0;
  const isRadio = isRequired && max === 1;

  const modifiersMap = keyBy(modifiers, 'modifierUuid');

  const [selectedModifiers, updateSelectedModifiers] = useImmer<ModifierCart[]>([]);

  useEffect(() => {
    if (onUpdateSelectedModifierGroup) {
      onUpdateSelectedModifierGroup(modifierGroup, selectedModifiers);
    }
  }, [selectedModifiers, modifierGroup, onUpdateSelectedModifierGroup]);

  const buildModifierCart = (modifierUuid: string, quantity: number) => {
    const modifier = modifiersMap[modifierUuid];

    return {
      ...modifier,
      quantity,
    };
  };

  const onRadioModifierChange = (modifierUuid: string) => {
    const modifierCart = buildModifierCart(modifierUuid, 1);
    updateSelectedModifiers([modifierCart]);
  };

  const onSelectModifierChange = (modifierUuid: string, quantity: number) => {
    if (!quantity) {
      updateSelectedModifiers((draft) => (draft = draft.filter((modifier) => modifier.modifierUuid !== modifierUuid)));
    } else {
      updateSelectedModifiers((draft) => {
        const foundModifier = draft.find((modifier) => modifier.modifierUuid === modifierUuid);

        if (foundModifier) {
          foundModifier.quantity = quantity;
        } else {
          const modifierCart = buildModifierCart(modifierUuid, quantity);
          draft.push(modifierCart);
        }
      });
    }
  };

  const onSelectNestedModifierChange = (
    modifierUuid: string,
    nestedModifierGroup: ModifierGroup,
    nestedSelectedModifiers: ModifierCart[]
  ) => {
    updateSelectedModifiers((draft) => {
      const foundModifier = draft.find((modifier) => modifier.modifierUuid === modifierUuid);

      if (!foundModifier) return;

      if (foundModifier.selectedModifierGroups) {
        const foundNestedModifierGroup = foundModifier.selectedModifierGroups?.find(
          ({ modifierGroupUuid }) => modifierGroupUuid === nestedModifierGroup.modifierGroupUuid
        );

        if (foundNestedModifierGroup) {
          foundNestedModifierGroup.selectedModifiers = nestedSelectedModifiers;
        } else {
          foundModifier.selectedModifierGroups = [
            ...foundModifier.selectedModifierGroups,
            { ...nestedModifierGroup, selectedModifiers: nestedSelectedModifiers },
          ];
        }
      } else {
        foundModifier.selectedModifierGroups = [{ ...nestedModifierGroup, selectedModifiers: nestedSelectedModifiers }];
      }
    });
  };

  return (
    <Stack>
      <Box mb={1}>
        <Flex backgroundColor="gray.100" flexDirection="column" align="left" justify="space-between" px={6} py={2}>
          <Heading as="h5" fontSize="lg" fontWeight="500">
            {label}
          </Heading>
          <SubHeading min={min} />
        </Flex>
      </Box>
      <Stack px={6} pt={2}>
        {isRadio ? (
          <ModifiersRadioGroup
            currencyCode={currencyCode}
            modifiers={modifiers}
            selectedModifier={selectedModifiers[0] ?? []}
            onChange={onRadioModifierChange}
            onNestedChange={onSelectNestedModifierChange}
            isDisabled={!modifierGroup.available}
          />
        ) : (
          <ModifiersSelectGroup
            currencyCode={currencyCode}
            isDisabled={!modifierGroup.available}
            max={max}
            min={min}
            modifiers={modifiers}
            onChange={onSelectModifierChange}
            onNestedChange={onSelectNestedModifierChange}
            selectedModifiers={selectedModifiers}
          />
        )}
      </Stack>
    </Stack>
  );
};
