import { keyBy } from 'lodash-es';

import * as Api from '@/api/gateway-click-collect/restaurants/types';
import { MenuElement, Modifier, ModifierGroup } from '@/api/types';
import { getMenuItemPicture } from '@/helpers/menu.helpers';

import { Category, Menu } from './types';

export const formatModifier = (modifier: Api.Modifier, menu: Api.Menu, outOfStocks: Set<string>): Modifier => {
  const { modifierGroups = [], menuItemUuid, label, modifierUuid, nutritionalInfo, sellingPrice } = modifier;

  return {
    available: !outOfStocks.has(menuItemUuid),
    label,
    menuItemUuid,
    modifierUuid,
    nutritionalInfo,
    sellingPrice,
    modifierGroups: modifierGroups.map((modifierGroup) => {
      return formatModifierGroup(modifierGroup, menu, outOfStocks);
    }),
  };
};

export const formatModifierGroup = (
  modifierGroup: Api.ModifierGroup,
  menu: Api.Menu,
  outOfStocks: Set<string>
): ModifierGroup => {
  const { description, included, label, max, modifierGroupUuid } = modifierGroup;
  const modifiers = modifierGroup.modifiers.map((modifier) => {
    return formatModifier(modifier, menu, outOfStocks);
  });
  const available = modifiers.some((modifier) => modifier.available);

  return {
    available,
    description,
    included,
    label,
    max,
    modifierGroupUuid,
    modifiers,
  };
};

export const formatCategoryItem = (item: Api.Item, menu: Api.Menu, outOfStocks: Set<string>): MenuElement => {
  const {
    menuItemUuid,
    label,
    menuElementUuid,
    nutritionalInfo,
    sellingPrice,
    description = '',
    modifierGroups = [],
  } = item;

  return {
    available: !outOfStocks.has(menuItemUuid),
    description,
    label,
    menuElementUuid,
    menuItemUuid,
    modifierGroups: modifierGroups.map((modifierGroup) => {
      return formatModifierGroup(modifierGroup, menu, outOfStocks);
    }),
    nutritionalInfo,
    imageUrl: getMenuItemPicture({ menu, menuItemUuid }) ?? '',
    sellingPrice,
  };
};

export const formatCategory = (category: Api.Category, menu: Api.Menu, outOfStocks: Set<string>): Category => {
  const { menuElementUuids } = category;
  const itemsMapByMenuElementUuids = keyBy(menu.items, 'menuElementUuid');

  return {
    ...category,
    menuElements: menuElementUuids
      ?.map((menuElementUuid) => {
        const item = itemsMapByMenuElementUuids[menuElementUuid];

        if (item) {
          return formatCategoryItem(item, menu, outOfStocks);
        }
      })
      .filter((item): item is MenuElement => !!item),
  };
};

export const formatMenu = (menu: Api.Menu, outOfStocks: Set<string>): Menu => {
  const { categories, description, label, restaurantPlatformId, conceptUuid } = menu;

  return {
    categories: categories?.map((category) => {
      return formatCategory(category, menu, outOfStocks);
    }),
    conceptUuid,
    description,
    label,
    restaurantPlatformId,
  };
};
