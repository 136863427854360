import { Button, chakra, Grid, GridItem, Image, Stack, SystemStyleObject } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';

import { CartItemModifier } from '@/api/gateway-click-collect/orders';
import HomeIcon from '@/assets/img/house.svg';
import { useModifierGroupQuantity } from '@/contexts/ModifierGroupQuantityContext';

//TODO use variant xl button after rebase
const StyledButton = chakra(Button, {
  baseStyle: {
    fontWeight: 700,
    textTransform: 'uppercase',
    height: { base: '40px', kiosk: '80px' },
    border: '1px solid',
    fontSize: { base: 16, kiosk: 28 },
    boxShadow: '0px 1px 2px 0px #1018280D',
  },
});

export interface AddModifierGroupFooterProps {
  sx?: SystemStyleObject;
  restaurantPlatformId?: string;
  onConfirmClick: (cartItemModifier?: CartItemModifier[]) => void;
  onSkip: () => void;
}

export const AddModifierGroupFooter = ({
  onConfirmClick,
  sx,
  onSkip,
  restaurantPlatformId,
}: AddModifierGroupFooterProps) => {
  const { isOptional, currentQuantity, min, max, selectedModifiers } = useModifierGroupQuantity();

  const isConfirmEnabled = !!currentQuantity && currentQuantity >= min && currentQuantity <= max;

  return (
    <Grid
      templateColumns="repeat(3, 1fr)"
      gap={2}
      sx={{
        width: '100%',
        alignItems: 'end',
        p: 4,
        background: 'white',
        boxShadow: '0px -4px 16px 0px #0000001A',
        ...sx,
      }}
    >
      {!!restaurantPlatformId && (
        <GridItem justifySelf="start">
          <Button
            color="gray.700"
            textTransform="uppercase"
            height={{ base: '40px', kiosk: '80px' }}
            as={Link}
            to={`/onSite/menu/${restaurantPlatformId}`}
            variant="outline"
            borderColor="#D0D5DD"
            boxShadow="0px 1px 2px 0px #1018280D"
            leftIcon={<Image src={HomeIcon} />}
            fontSize={{ base: 16, kiosk: 28 }}
            fontWeight={700}
          >
            <Trans>Home</Trans>
          </Button>
        </GridItem>
      )}
      <GridItem justifySelf="center" />
      <GridItem justifySelf="end">
        <Stack direction="row" gap={2} alignItems="end">
          {isOptional && (
            <StyledButton
              variant="outline"
              borderColor="gray.300"
              fontWeight={700}
              onClick={(e) => {
                e.stopPropagation();
                onSkip();
              }}
            >
              <Trans>No thanks</Trans>
            </StyledButton>
          )}
          {max > 1 && (
            <Stack gap={1}>
              {!isConfirmEnabled && !!min && <Trans>Choose at least {min}</Trans>}
              <StyledButton
                variant="solid"
                background="green.500"
                borderColor="#D0D5DD"
                isDisabled={!isConfirmEnabled}
                onClick={() => onConfirmClick(selectedModifiers)}
              >
                <Trans>Confirm</Trans>
              </StyledButton>
            </Stack>
          )}
        </Stack>
      </GridItem>
    </Grid>
  );
};
