import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { fetchTerminalStatus } from '@/api/api';
import ApiError from '@/api/ApiError';
import { GetTerminalStatusMutation, TerminalStatus } from '@/api/types';
import { useOnSiteInfo } from '@/hooks/useOnSiteInfo';

export type Options = Omit<UseMutationOptions<TerminalStatus, ApiError, GetTerminalStatusMutation>, 'mutationFn'>;

export const useTerminalStatusMutation = (options?: Options) => {
  const { authenticationPassPhrase } = useOnSiteInfo();

  return useMutation({
    mutationFn: async (mutationParams) => fetchTerminalStatus({ ...mutationParams, authenticationPassPhrase }),
    ...options,
  });
};
