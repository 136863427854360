import { Stack, SystemStyleObject } from '@chakra-ui/react';

import { ModifierCart } from '@/types';

import { CartItemDetails } from './CartItemDetails';
import { OutOfStocks } from './CartPage.helpers';

export interface ModifiersListProps {
  currencyCode: string;
  fontWeight?: number;
  modifiers: ModifierCart[];
  outOfStocks?: OutOfStocks | null;
  sx?: SystemStyleObject;
}

export const ModifiersList = ({ currencyCode, modifiers, outOfStocks, fontWeight, sx }: ModifiersListProps) => {
  return (
    <Stack gap={0} sx={sx}>
      {modifiers.map(({ modifierUuid, label, sellingPrice, quantity, selectedModifierGroups }) => {
        const isOos = outOfStocks?.errorType === 'modifier-oos' && outOfStocks.modifierUuid === modifierUuid;

        return (
          <div key={modifierUuid}>
            <CartItemDetails
              currencyCode={currencyCode}
              fontWeight={fontWeight}
              formatOptions={{ signDisplay: 'exceptZero' }}
              isOos={isOos}
              label={label}
              price={sellingPrice}
              quantity={quantity}
              showQuantity
            />
            {selectedModifierGroups?.map(({ modifierGroupUuid, selectedModifiers }) => {
              if (selectedModifiers) {
                return (
                  <ModifiersList
                    currencyCode={currencyCode}
                    fontWeight={500}
                    key={modifierGroupUuid}
                    modifiers={selectedModifiers}
                    outOfStocks={outOfStocks}
                    sx={{ paddingLeft: 6 }}
                  />
                );
              }
            })}
          </div>
        );
      })}
    </Stack>
  );
};
