import { Button, Collapse, Flex, Stack, SystemStyleObject, Text, useDisclosure } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import dayjs from 'dayjs';
import { FaChevronUp, FaClock } from 'react-icons/fa';

import { Restaurant } from '@/api/gateway-click-collect/restaurants';
import { RestaurantInfoShift } from '@/components/RestaurantInfo/RestaurantInfoShift';
import { getNextShift, isRestaurantOpen } from '@/helpers/openingHours.helpers';

export interface OpeningHoursInfoProps {
  restaurant: Restaurant;
  sx?: SystemStyleObject;
}

const ButtonLabel = ({ status, openingHours, timezone }: Pick<Restaurant, 'status' | 'openingHours' | 'timezone'>) => {
  const nextShift = getNextShift({ openingHours, timezone });
  const nextOpenTime = nextShift?.startTime ? dayjs(nextShift.startTime, 'HH:mm:ss').format('LT') : null;

  if (isRestaurantOpen(status)) {
    return <Trans>Open</Trans>;
  }

  if (nextOpenTime) {
    return <Trans>Opens at {nextOpenTime}</Trans>;
  }

  return <Trans>This restaurant is currently closed.</Trans>;
};

const WEEKDAYS_LIST = [0, 1, 2, 3, 4, 5, 6];

export const OpeningHoursInfo = ({ restaurant }: OpeningHoursInfoProps) => {
  const { isOpen, onToggle } = useDisclosure();

  const { status, openingHours, timezone } = restaurant;
  const hasOpeningHours = !!openingHours;

  return (
    <div>
      <Button onClick={onToggle} variant="ghost" sx={{ paddingLeft: 0, width: '100%' }}>
        <Flex alignItems="center" gap={2} marginRight="auto">
          <FaClock />
          <ButtonLabel timezone={timezone} status={status} openingHours={openingHours} />
        </Flex>
        {hasOpeningHours && (
          <FaChevronUp
            style={{
              transform: `rotate(${isOpen ? '0' : '180deg'})`,
              transition: 'transform .3s ease-in-out',
            }}
          />
        )}
      </Button>
      {hasOpeningHours && (
        <Collapse in={isOpen} animateOpacity>
          <Stack gap={2}>
            {WEEKDAYS_LIST.map((weekday) => {
              const weekdayShifts = openingHours?.weekdays?.[weekday]?.shifts;
              const isoWeekday = weekday + 1;

              return <RestaurantInfoShift key={weekday} weekday={isoWeekday} weekdayShifts={weekdayShifts} />;
            })}
          </Stack>
        </Collapse>
      )}
    </div>
  );
};
