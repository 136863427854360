import { createContext, ReactNode, useCallback, useMemo, useState } from 'react';

import { CartItemModifier } from '@/api/gateway-click-collect/orders';
import { ModifierGroup, SubModifierGroup } from '@/api/gateway-click-collect/restaurants';

import { decrementCartItemModifier, incrementCartItemModifier } from './ModifierGroupQuantityContext.helpers';

type ModifierQuantityContextValue = {
  currentQuantity: number;
  increaseModifierQuantity: (modifer: CartItemModifier) => void;
  decreaseModifierQuantity: (modifer: CartItemModifier) => void;
  getModifierCurrentQuantity: (modifierUuid: string) => number | undefined;
  isOptional: boolean;
  max: number;
  min: number;
  selectedModifiers: CartItemModifier[];
  canIncrease: boolean;
};

export const ModifierQuantityContext = createContext<ModifierQuantityContextValue | null>(null);

export interface ModifierGroupQuantityProviderProps {
  children: ReactNode;
  modifierGroup: ModifierGroup | SubModifierGroup;
}

export const ModifierGroupQuantityProvider = ({ children, modifierGroup }: ModifierGroupQuantityProviderProps) => {
  const { included: min, max } = modifierGroup;
  const [selectedModifiers, setSelectedModifiers] = useState<CartItemModifier[]>([]);

  const currentQuantity = useMemo(
    () => selectedModifiers.reduce((modifAcc, currentModif) => currentModif.quantity + modifAcc, 0),
    [selectedModifiers]
  );

  const increaseModifierQuantity = useCallback((cartItemModifier: CartItemModifier) => {
    setSelectedModifiers((oldModifiers) => incrementCartItemModifier(oldModifiers, cartItemModifier));
  }, []);

  const decreaseModifierQuantity = useCallback((cartItemModifier: CartItemModifier) => {
    setSelectedModifiers((oldModifiers) => decrementCartItemModifier(oldModifiers, cartItemModifier));
  }, []);

  const getModifierCurrentQuantity = useCallback(
    (modifierUuid: string) =>
      selectedModifiers
        .filter((modifier) => modifier.modifierUuid === modifierUuid)
        .reduce((modifAcc, currentModif) => currentModif.quantity + modifAcc, 0),
    [selectedModifiers]
  );

  const providerValue = useMemo((): ModifierQuantityContextValue => {
    const isOptional = !min;
    const canIncrease = currentQuantity < max;

    return {
      increaseModifierQuantity,
      decreaseModifierQuantity,
      getModifierCurrentQuantity,
      selectedModifiers,
      currentQuantity,
      min,
      max,
      canIncrease,
      isOptional,
    };
  }, [
    getModifierCurrentQuantity,
    increaseModifierQuantity,
    decreaseModifierQuantity,
    selectedModifiers,
    currentQuantity,
    min,
    max,
  ]);

  return <ModifierQuantityContext.Provider value={providerValue}>{children}</ModifierQuantityContext.Provider>;
};
