import { mapValues, sortBy } from 'lodash-es';

import { PictureClassification } from '@/api/gateway-click-collect/configurations';
import { Item, Menu, Modifier, ModifierGroup } from '@/api/gateway-click-collect/restaurants';
import { MenuElement, Modifier as DeprecatedModifier } from '@/api/types';
import { Cart, ItemCart } from '@/types';

export const hasMenuItemCalories = (menuItem: MenuElement | DeprecatedModifier): boolean =>
  typeof menuItem.nutritionalInfo.energyKcal === 'number';

export const sortPictureClassificationsByWidth = (pictureClassification: PictureClassification[]) =>
  pictureClassification.sort((imageA, imageB) => (imageA.width > imageB.width ? -1 : 1));

export const updateCart = ({ item, cart }: { item: ItemCart; cart: Cart }) => {
  const currentItem = cart.items.find(
    ({ menuItemUuid, modifierGroups }) => menuItemUuid === item.menuItemUuid && !modifierGroups?.length
  );

  if (currentItem && !item.modifierGroups.length) {
    return cart.items.map((cartItem) => {
      if (cartItem.menuItemUuid === currentItem.menuItemUuid) return { ...cartItem, quantity: cartItem.quantity + 1 };
      return cartItem;
    });
  }

  return [...cart.items, item];
};

export const getMenuItemPicture = ({ menuItemUuid, menu }: { menuItemUuid: string; menu: Menu }) => {
  return sortBy(menu.menuItemPicturesUrls[menuItemUuid], 'width').find(({ width }) => width >= 768)?.url;
};

export const getMenuPicturesMap = (menu: Menu) =>
  mapValues(
    menu.menuItemPicturesUrls,
    (pictureClassifications) => sortBy(pictureClassifications, 'width').find(({ width }) => width >= 768)?.url
  );

export const validateItemModifiers = (modifiers: Modifier[], outOfStocks: Set<string>): Modifier[] => {
  return modifiers.reduce<Modifier[]>((availableModifiers, modifier) => {
    const { menuItemUuid, modifierGroups } = modifier;
    const isItemOos = outOfStocks.has(menuItemUuid);

    if (isItemOos) {
      return availableModifiers;
    }

    if (modifierGroups?.length) {
      const modifierGroupsValidation = validateItemModifierGroups(modifierGroups, outOfStocks);

      return modifierGroupsValidation.isValid
        ? [
            ...availableModifiers,
            {
              ...modifier,
              modifierGroups: modifierGroupsValidation.availableModifierGroups,
            },
          ]
        : availableModifiers;
    }

    return [...availableModifiers, modifier];
  }, []);
};

type AvailableModifierGroupsSuccess = { availableModifierGroups?: ModifierGroup[]; isValid: true };
type AvailableModifierGroupsError = { isValid: false };

export const validateItemModifierGroups = (
  modifierGroups: ModifierGroup[],
  outOfStocks: Set<string>
): AvailableModifierGroupsSuccess | AvailableModifierGroupsError => {
  const availableModifierGroups: ModifierGroup[] = [];

  for (const modifierGroup of modifierGroups) {
    const { included, modifiers } = modifierGroup;
    const availableModifiers = validateItemModifiers(modifiers, outOfStocks);
    const isEmpty = !availableModifiers.length;
    const isInvalid = included >= 1 && isEmpty;

    if (isInvalid) {
      return { isValid: false };
    }

    if (!isEmpty) {
      availableModifierGroups.push({ ...modifierGroup, modifiers: availableModifiers });
    }
  }

  return {
    availableModifierGroups: availableModifierGroups.length ? availableModifierGroups : undefined,
    isValid: true,
  };
};

export const getAvailableItems = (items: Item[], outOfStocks: Set<string>): Item[] => {
  return items.reduce<Item[]>((availableItems, item) => {
    const { menuItemUuid, modifierGroups } = item;
    const isItemOos = outOfStocks.has(menuItemUuid);

    if (isItemOos) {
      return availableItems;
    }

    if (modifierGroups?.length) {
      const modifierGroupsValidation = validateItemModifierGroups(modifierGroups, outOfStocks);

      return modifierGroupsValidation.isValid
        ? [
            ...availableItems,
            {
              ...item,
              modifierGroups: modifierGroupsValidation.availableModifierGroups,
            },
          ]
        : availableItems;
    }

    return [...availableItems, item];
  }, []);
};
