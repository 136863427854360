import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Box, Button, chakra, Flex, FormControl, Heading, Image, Input, Stack, Text } from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';

import ApiError from '@/api/ApiError';
import { PairingTerminalErrorEnum } from '@/api/types';
import { usePairTerminalMutation } from '@/api/usePairTerminalMutation';
import PairingIndications from '@/assets/img/pairing_tutorial.gif';
import Terminal from '@/assets/img/terminal.png';
import Card from '@/components/Card';
import { PairingTerminalFailureContent } from '@/components/PairingTerminal/PairingTerminalFailureContent';
import { PairingTerminalLoadingContent } from '@/components/PairingTerminal/PairingTerminalLoadingContent';
import { PairingTerminalSuccessContent } from '@/components/PairingTerminal/PairingTerminalSuccessContent';

import { PairDeviceLayout } from './PairDeviceLayout';

const StyledInput = chakra(Input, {
  baseStyle: {
    background: 'white',
    maxWidth: '200px',
    padding: '16px',
    border: `1px solid `,
  },
});

const PairTerminalIndications = () => {
  return (
    <Flex position="relative" width={500} maxWidth="calc(100% - 16px)">
      <Image src={PairingIndications} alt="pairing-indications" maxWidth={260} maxHeight={425} objectFit="contain" />
      <Flex position="absolute" top={100} left={150}>
        <Image src={Terminal} alt={t`payment terminal`} width={260} height={420} objectFit="contain" />
        <Card
          sx={{ padding: '12px' }}
          width={200}
          position="absolute"
          left={150}
          top={200}
          height={200}
          display={{ base: 'none', sm: 'block' }}
        >
          <Stack gap={2}>
            <Box background={'gray.300'} height="15px" width={50} />
            <Heading fontWeight={500} fontSize={14}>
              <Trans>Register this terminal</Trans>
            </Heading>
            <Box background={'gray.300'} height="15px" />
            <Box background={'gray.300'} height="15px" width={120} />
          </Stack>
          <Flex
            marginTop={4}
            border={'1px solid gray.300'}
            justifyContent="space-between"
            borderRadius={8}
            gap={2}
            padding={2}
            alignItems="center"
          >
            <Box background={'gray.300'} height="15px" width={50} />-{' '}
            <Box background={'gray.300'} height="15px" width={50} />-
            <Box background={'gray.300'} height="15px" width={50} />
          </Flex>
        </Card>
      </Flex>
    </Flex>
  );
};

const PairTerminalProcess = () => {
  const [password, setPassword] = useState(['', '', '']);
  const [isCodePairWrong, setIsCodePairWrong] = useState(false);

  const [errorStatus, setErrorStatus] = useState<PairingTerminalErrorEnum | string>('');

  const [locationUuid] = useLocalStorage<string | null>('LOCATION_UUID', null);
  const [, setKeyAuthId] = useLocalStorage<string | null>('KEY_AUTH_ID', null);
  const [, setKeyAuth] = useLocalStorage<string | null>('KEY_AUTH', null);
  const [terminalUuid, setTerminalUuid] = useLocalStorage<string | null>('TERMINAL_UUID', null);

  const passPhrase = password.join('-');

  const handlePasswordUpdate = (value: string, index: number) => {
    setPassword((oldPassword) => {
      const previous = [...oldPassword];
      previous[index] = value.trim().toLowerCase();
      return previous;
    });
  };

  const {
    mutate: pairTerminal,
    isSuccess,
    isError,
    isLoading,
  } = usePairTerminalMutation({
    onError: (error) => {
      if (error instanceof ApiError) {
        setErrorStatus(error.reason);
        if (error.reason === PairingTerminalErrorEnum.invalidPairingCode) {
          setIsCodePairWrong(true);
        }
      }
    },
    onSuccess: (data) => {
      setTerminalUuid(data.terminalInfo.terminalUuid);
      setKeyAuth(data.authorization.key);
      setKeyAuthId(data.authorization.keyId);
    },
  });

  if (!locationUuid) {
    return <Navigate to="/onSite" />;
  }

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    pairTerminal({
      locationUuid,
      terminalPairingCode: passPhrase,
      ...(!!terminalUuid && { previousTerminalUuid: terminalUuid }),
    });
  };

  if (isLoading) {
    return <PairingTerminalLoadingContent />;
  }

  if (isSuccess) {
    return <PairingTerminalSuccessContent />;
  }

  if (isError && !isCodePairWrong) {
    return <PairingTerminalFailureContent handleRetryClick={handleSubmit} errorStatus={errorStatus} />;
  }

  return (
    <Stack justifyContent="start" alignItems="center" gap="100px">
      <PairTerminalIndications />
      <form onSubmit={handleSubmit}>
        <Stack gap={54} alignItems="center">
          <Heading fontWeight={700} fontSize={32}>
            <Trans>Enter code</Trans>
          </Heading>
          <Flex gap={2} flexWrap="nowrap" alignItems="center" justifyContent="center">
            {password.map((inputValue, index) => {
              return (
                <Flex alignItems="center" gap={2} key={index}>
                  <FormControl isRequired>
                    <StyledInput
                      value={inputValue}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePasswordUpdate(e.target.value, index)}
                      borderColor={isCodePairWrong ? 'red.400' : 'gray.300'}
                    />
                  </FormControl>
                  {index < 2 && (
                    <Text fontSize={18} fontWeight={750}>
                      -
                    </Text>
                  )}
                </Flex>
              );
            })}
          </Flex>
          {isCodePairWrong && (
            <Flex
              borderRadius={12}
              padding={8}
              background="red.error.25"
              gap={3}
              border="1px solid"
              borderColor="red.error.300"
            >
              <InfoOutlineIcon color="red.600" />
              <Stack>
                <Text fontSize={14} fontWeight={600} color="red.700">
                  <Trans>Wrong code</Trans>
                </Text>
                <Text fontSize={14} fontWeight={400} color="red.700">
                  <Trans>Please enter the code displayed on the card machine</Trans>
                </Text>
              </Stack>
            </Flex>
          )}
          <Button type="submit" width="100%" maxWidth={220} paddingY={8} fontSize={24}>
            <Trans>Pair card machine</Trans>
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};

export const PairTerminalPage = () => {
  return (
    <PairDeviceLayout hasLogOutButton headerTitle={t`Pair a card machine`}>
      <PairTerminalProcess />
    </PairDeviceLayout>
  );
};
