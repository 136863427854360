import { ArrowForwardIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { Button, chakra, Image, Text, VStack } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';

import type { Coords } from '@/api/types';
import simone from '@/assets/img/simone.png';
import TasterStretchGif from '@/assets/img/taster-stretch.gif';
import { KitchenGeolocationCard } from '@/components/KitchenGeolocationCard';
import { RequestLocationButton } from '@/components/RequestLocationButton';
import { getSortedRestaurantsWithConcept } from '@/helpers/brands.helpers';
import { logOrderDelivery } from '@/helpers/dataLayer.helpers';
import { RestaurantWithConcept } from '@/types';

const StyledText = chakra(Text, {
  baseStyle: {
    display: 'flex',
    textAlign: 'center',
    fontWeight: 500,
    '&::after': {
      backgroundColor: 'gray.200',
      content: '""',
      flex: '1 1',
      height: '1px',
      marginBlock: 'auto',
      marginX: 2,
    },
    '&::before': {
      backgroundColor: 'gray.200',
      content: '""',
      flex: '1 1',
      height: '1px',
      marginBlock: 'auto',
      marginX: 2,
    },
  },
});

export interface LocationFinderBodyProps {
  coords: Coords;
  isError?: boolean;
  isLoading?: boolean;
  kitchenRestaurantLists: Array<RestaurantWithConcept[]>;
}

export const LocationFinderBody = ({ coords, isError, isLoading, kitchenRestaurantLists }: LocationFinderBodyProps) => {
  if (kitchenRestaurantLists.length) {
    const [closestKitchenRestaurants, ...otherKitchenRestaurants] = kitchenRestaurantLists;

    const closestRestaurant = closestKitchenRestaurants[0];

    return (
      <VStack spacing={6} align="stretch" paddingX={3} paddingBlock={4}>
        <VStack align="stretch" spacing={6}>
          <KitchenGeolocationCard
            coords={coords}
            restaurantList={getSortedRestaurantsWithConcept(closestKitchenRestaurants)}
          />
          <VStack align="stretch" spacing={4}>
            <Text alignSelf="center" display="flex" fontSize="sm" fontWeight={500} pos="relative" textAlign="center">
              <Trans>Don’t want to get off the couch?</Trans>
            </Text>
            <Button
              alignSelf="center"
              as={Link}
              colorScheme="gray"
              rightIcon={<ArrowForwardIcon />}
              to={`/${closestRestaurant.locationUuid}?delivery=true`}
              onClick={() => logOrderDelivery({ kitchenLabel: closestRestaurant.kitchenLabel })}
            >
              <Trans>Order delivery</Trans>
            </Button>
          </VStack>
        </VStack>
        {!!otherKitchenRestaurants?.length && (
          <VStack align="stretch" spacing={4}>
            <StyledText fontSize="sm">
              <Trans>Other locations near you</Trans>
            </StyledText>
            {otherKitchenRestaurants.map((restaurantList) => {
              const sortedRestaurants = getSortedRestaurantsWithConcept(restaurantList);

              return (
                <KitchenGeolocationCard
                  coords={coords}
                  key={restaurantList[0].locationUuid}
                  restaurantList={sortedRestaurants}
                />
              );
            })}
          </VStack>
        )}
      </VStack>
    );
  }

  if (isError) {
    return (
      <VStack height="100%" justify="center" align="center" spacing={6} paddingX={3}>
        <Image height={200} alt="Simone" src={simone} />
        <Text fontSize="sm" fontWeight={300} textAlign="center">
          <Trans>Sorry, we’ve encountered an error. Please try again later or contact support. </Trans>
        </Text>
        <Button id="support" alignSelf="center" colorScheme="gray">
          <Trans>Contact support</Trans>
        </Button>
      </VStack>
    );
  }

  if (isLoading) {
    return (
      <VStack height="100%" justify="center" align="center">
        <Image src={TasterStretchGif} />
      </VStack>
    );
  }

  return (
    <VStack height="100%" justify="center" align="center" spacing={4} paddingX={3}>
      <Text fontSize="sm" fontWeight={300} textAlign="center">
        <Trans>Snap! We don’t have Taster brands here yet.</Trans>
      </Text>
      <Text fontSize="sm" fontWeight={300} textAlign="center">
        <Trans>
          Why not submit your location and we will look into getting one of our brands in your location soon!
        </Trans>
      </Text>
      <RequestLocationButton alignSelf="center" colorScheme="gray" rightIcon={<ExternalLinkIcon />} />
    </VStack>
  );
};
