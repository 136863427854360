import { Button, SystemStyleObject } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCart } from '@/contexts/cart';
import { SessionEndReasonEnum, useOnSiteSession } from '@/contexts/onSiteSession';

import { AbandonCartModal } from './AbandonCartModal';
export interface KioskAbandonCartButtonProps {
  sx?: SystemStyleObject;
}

export const KioskAbandonCartButton = ({ sx }: KioskAbandonCartButtonProps) => {
  const navigate = useNavigate();
  const [locationUuid] = useLocalStorage('LOCATION_UUID', null);

  const { endOnSiteSession } = useOnSiteSession();

  const { resetCart } = useCart();

  const handleAbandonClick = () => {
    endOnSiteSession({
      sessionEndReason: SessionEndReasonEnum.ABANDON,
    });

    resetCart();
    navigate(`/onSite/${locationUuid}`, { state: { startSession: true } });
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Button
        sx={sx}
        color="gray.700"
        textTransform="uppercase"
        height={{ base: '40px', kiosk: '80px' }}
        onClick={() => setIsModalOpen(true)}
        variant="outline"
        border="1px solid #D0D5DD"
        fontSize={{ base: 16, kiosk: 28 }}
        boxShadow="0px 1px 2px 0px #1018280D"
      >
        <Trans>Abandon cart</Trans>
      </Button>
      <AbandonCartModal isOpen={isModalOpen} onCancel={() => setIsModalOpen(false)} onConfirm={handleAbandonClick} />
    </>
  );
};
