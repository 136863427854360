import { useKeycloak } from '@react-keycloak/web';
import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

import * as tsClients from './tsClients';

export const TasterApisContext = createContext<tsClients.TasterApis>({} as tsClients.TasterApis);

export const TasterApisProvider = ({ children }: PropsWithChildren) => {
  const { keycloak } = useKeycloak();

  const tasterApis = useMemo(() => tsClients.initialize(() => `Bearer ${keycloak?.token}`), [keycloak?.token]);

  return <TasterApisContext.Provider value={tasterApis}>{children}</TasterApisContext.Provider>;
};

export const useTasterApis = () => useContext(TasterApisContext);
