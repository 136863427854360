import { Stack, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import dayjs from 'dayjs';

import { getNextShift } from '@/helpers/openingHours.helpers';

import { MenuHeroProps } from './MenuHero';

export const ClosingMessage = ({
  canPreOrder,
  openingHours,
  timezone,
}: Pick<MenuHeroProps, 'openingHours' | 'timezone' | 'canPreOrder'>) => {
  const nextShift = getNextShift({ openingHours, timezone });
  const nextOpenTime = nextShift?.startTime ? dayjs(nextShift.startTime, 'HH:mm:ss').format('LT') : null;

  return (
    <Stack alignItems="center">
      <Text fontSize="2xl" fontWeight={500}>
        {nextOpenTime ? <Trans>Opens at {nextOpenTime}</Trans> : <Trans>Closed</Trans>}
      </Text>
      {canPreOrder && (
        <Text>
          <Trans>Available for pre-order</Trans>
        </Text>
      )}
    </Stack>
  );
};
