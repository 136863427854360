import { Heading, Image, Stack, SystemStyleObject } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { ReactNode } from 'react';

import AttentionLottie from '@/assets/anim/attention-lottie.json';
import TasterLogo from '@/assets/img/taster-logo-black.svg';
import { LottieAnimation } from '@/components/LottieAnimation';

export interface ErrorScreenProps {
  button: ReactNode;
  sx?: SystemStyleObject;
  text?: ReactNode;
}

export const ErrorScreen = ({ button, sx, text }: ErrorScreenProps) => {
  return (
    <Stack alignItems="center" padding={4} gap={4} sx={sx}>
      <LottieAnimation animation={AttentionLottie} sx={{ flex: 3, width: '160px' }} />
      <Stack alignItems="center" flex={2} gap={10}>
        {text ?? (
          <Heading as="p" size="lg" fontWeight={700} textAlign="center">
            <Trans>Sorry, we’ve encountered an error.</Trans>
          </Heading>
        )}
        {button}
      </Stack>
      <Image alt="Taster logo" src={TasterLogo} width={{ base: 200, md: 400 }} flex={1} />
    </Stack>
  );
};
