import { LottieOptions, useLottie } from 'lottie-react';
import { CSSProperties } from 'react';

export const LottieAnimation = <T = unknown,>({
  animation,
  sx,
  animationOptions,
}: {
  animation: T;
  sx?: CSSProperties;
  animationOptions?: Omit<LottieOptions, 'animationData'>;
}) => {
  const options = {
    loop: true,
    autoplay: true,
    ...animationOptions,
  };

  const { View } = useLottie({ ...options, animationData: animation }, { width: '150px', ...sx });

  return View;
};
