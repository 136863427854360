import { Button, ButtonProps, Icon } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { BsLink } from 'react-icons/bs';
import { Link } from 'react-router-dom';

export const PairTerminalButton = (props: Omit<ButtonProps, 'leftIcon'>) => {
  return (
    <Button leftIcon={<Icon as={BsLink} sx={{ fontSize: 18 }} />} as={Link} to="/onSite/internalLocations" {...props}>
      <Trans>Pair card machine</Trans>
    </Button>
  );
};
