import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import type { ResponsivePropertyDrawer } from '@/types';

import ReceiptForm, { SubmitReceiptEventHandler } from './ReceiptForm';

interface ReceiptModalProps {
  isOpen: boolean;
  isReceiptSent: boolean;
  isLoading: boolean;
  onSubmit: SubmitReceiptEventHandler;
  onClose: () => void;
}

const ReceiptModal = ({ isLoading, isOpen, onSubmit, onClose }: ReceiptModalProps) => {
  const drawerSize = useBreakpointValue<'full' | 'md'>({ md: 'md' });
  const responsiveDrawerPlacement = useBreakpointValue<'bottom' | 'right'>({ base: 'bottom', md: 'right' });
  const responsivePropertiesDrawer = useBreakpointValue<ResponsivePropertyDrawer>({
    base: { borderTopRadius: '3xl' },
    md: { borderTopRadius: '3xl', borderTopRightRadius: '0px', top: '60vh !important' },
  });

  return (
    <Drawer isOpen={isOpen} placement={responsiveDrawerPlacement} onClose={onClose} size={drawerSize}>
      <DrawerOverlay />
      <DrawerContent {...responsivePropertiesDrawer}>
        <DrawerCloseButton top={4} />
        <DrawerHeader borderBottomWidth="2px" textAlign="center">
          <Trans>Get receipt</Trans>
        </DrawerHeader>
        <DrawerBody>
          <ReceiptForm onSubmit={onSubmit} isLoading={isLoading} />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default ReceiptModal;
