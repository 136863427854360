import { CloseIcon } from '@chakra-ui/icons';
import { Button, IconButton, Image, Modal, ModalContent, ModalOverlay, Stack, Text } from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';

import Warning from '@/assets/img/warning.svg';
import { useCart } from '@/contexts/cart';

export interface AbandonCartModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  isOpen: boolean;
}

export const AbandonCartModal = ({ isOpen, onConfirm, onCancel }: AbandonCartModalProps) => {
  const { resetCart } = useCart();

  return (
    <Modal isOpen={isOpen} onClose={onCancel} size="5xl">
      <ModalOverlay />
      <ModalContent p={2} width="100%" maxW="85vw" maxHeight="100%" my="auto">
        <Stack alignItems="center" height="100%" pb={12}>
          <IconButton
            aria-label={t`Close`}
            color="gray.700"
            textTransform="uppercase"
            alignSelf="end"
            variant="outlines"
            height="104px"
            width="104px"
            border="1px solid"
            borderColor="gray.border"
            padding={4}
            boxShadow="0px 1px 2px 0px #1018280D;"
            icon={<CloseIcon />}
            fontSize={{ base: 16, kiosk: 28 }}
            onClick={onCancel}
          />
          <Stack gap={3} alignItems="center" justifyContent="space-between" maxWidth={470} height="100%">
            <Stack gap={4} alignItems="center">
              <Image src={Warning} width={200} height={200} />
              <Text fontSize={42} fontWeight={700} textAlign="center">
                <Trans>Clear cart?</Trans>
              </Text>
            </Stack>
            <Stack gap={4}>
              <Button
                size="lg"
                textTransform="uppercase"
                background="#F04438"
                color="white"
                height={{ base: '40px', kiosk: '80px' }}
                fontSize={{ base: 16, kiosk: 28 }}
                width="100%"
                onClick={() => {
                  resetCart();
                  onConfirm();
                }}
              >
                <Trans>Clear cart</Trans>
              </Button>
              <Button
                size="lg"
                textTransform="uppercase"
                height={{ base: '40px', kiosk: '80px' }}
                fontSize={{ base: 16, kiosk: 28 }}
                onClick={onCancel}
              >
                <Trans>Continue ordering</Trans>
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </ModalContent>
    </Modal>
  );
};
