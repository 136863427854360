import { Box, Image } from '@chakra-ui/react';
import { sortBy } from 'lodash-es';

import { PictureClassification, Restaurant } from '@/api/gateway-click-collect/restaurants';

import { ClosingMessage } from './ClosingMessage';

export interface MenuHeroProps extends Pick<Restaurant, 'openingHours' | 'timezone'> {
  canPreOrder: boolean;
  heroPictures: PictureClassification[];
  isRestaurantOpen: boolean;
}

export const MenuHero = ({ heroPictures, timezone, openingHours, isRestaurantOpen, canPreOrder }: MenuHeroProps) => {
  const heroPicture = sortBy(heroPictures, 'width').find(({ width }) => width >= 768)?.url;

  return (
    <Box position="relative">
      <Image aria-hidden src={heroPicture} />
      {!isRestaurantOpen && (
        <Box
          alignItems="center"
          backdropBlur="2px"
          backdropFilter="auto"
          bg="blackAlpha.600"
          bottom="0"
          color="white"
          display="flex"
          flexDirection="column"
          gap={2}
          justifyContent="center"
          left="0"
          p={2}
          pos="absolute"
          right="0"
          top="0"
        >
          <ClosingMessage openingHours={openingHours} timezone={timezone} canPreOrder={canPreOrder} />
        </Box>
      )}
    </Box>
  );
};
