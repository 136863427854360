import { t } from '@lingui/macro';

import { PairingTerminalDisconnectedContent } from '@/components/PairingTerminal/PairingTerminalDisconnectedContent';

import { PairDeviceLayout } from './PayOnSite/PairDeviceLayout';

export const TerminalDisconnectedPage = () => {
  return (
    <PairDeviceLayout hasSettingsButton headerTitle={t`Checkout`}>
      <PairingTerminalDisconnectedContent />
    </PairDeviceLayout>
  );
};
