import { useKeycloak } from '@react-keycloak/web';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useCallback, useMemo } from 'react';
import { Navigate } from 'react-router-dom';

export const useOnSiteInfo = () => {
  const [keyAuthId] = useLocalStorage<string | null>('KEY_AUTH_ID', null);
  const [keyAuth] = useLocalStorage<string | null>('KEY_AUTH', null);
  const [locationUuid] = useLocalStorage('LOCATION_UUID', null);
  const [terminalUuid] = useLocalStorage('TERMINAL_UUID', null);

  const {
    keycloak: { realmAccess, authenticated },
  } = useKeycloak();

  const isKioskUser = useMemo(() => {
    return authenticated && realmAccess?.roles?.includes('Kiosk Kitchen User');
  }, [authenticated, realmAccess]);

  const hasPairedTerminal = useMemo(
    () => !!(keyAuth && keyAuthId && locationUuid && terminalUuid),
    [keyAuth, keyAuthId, locationUuid, terminalUuid]
  );

  const authenticationPassPhrase = useMemo(() => {
    const username = `${locationUuid}-${keyAuthId}`;

    return `${username}:${keyAuth}`;
  }, [keyAuth, keyAuthId, locationUuid]);

  const navigateAuthenticated = useCallback((url: string, isAuthenticated = false) => {
    const targetUrl = isAuthenticated ? `/onSite/${url}` : `/${url}`;
    return <Navigate to={targetUrl} />;
  }, []);

  const isUserAuthenticated = useMemo(() => authenticated, [authenticated]);

  return {
    authenticationPassPhrase,
    hasPairedTerminal,
    isKioskUser,
    isUserAuthenticated,
    navigateAuthenticated,
  };
};
