import * as backboneCustomerOrder from '@tasterkitchens/client-ts-backbone-customer-order-v2';

import ApiError from '@/api/ApiError';

export type BackboneCustomerOrder = {
  ordersApi: backboneCustomerOrder.OrdersApi;
};

//FIXME: https://github.com/OpenAPITools/openapi-generator/issues/7720
export class NoContentMiddleware implements backboneCustomerOrder.Middleware {
  public post?(context: backboneCustomerOrder.ResponseContext): Promise<Response | void> {
    if (context.response.status === 204) {
      throw new ApiError({
        body: context.response.body,
        detail: '',
        reason: '',
        status: context.response.status,
        title: '',
        type: context.response.type,
      });
    }

    return Promise.resolve(context.response);
  }
}

export const initializeBackboneCustomerOrder = (getAccessToken: () => string) => {
  const apiConfiguration = new backboneCustomerOrder.Configuration({
    accessToken: getAccessToken,
    basePath: import.meta.env.VITE_CUSTOMER_ORDERS_API,
    middleware: [new NoContentMiddleware()],
  });

  const ordersApi = new backboneCustomerOrder.OrdersApi(apiConfiguration);

  return {
    ordersApi,
  };
};
