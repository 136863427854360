import { VStack } from '@chakra-ui/react';

import { ConceptInformation } from '@/api/gateway-click-collect/configurations';
import { ConceptCheckboxGroup } from '@/components/ConceptCheckboxGroup';
import { Geolocation, GeolocationAutocomplete } from '@/components/GeolocationAutocomplete';

type LocationFinderHeaderProps = {
  defaultAddress?: string;
  concepts?: ConceptInformation[];
  onChange: (value: string) => void;
  value: string;
  onSelect: (value: Geolocation) => void;
};

export const LocationFinderHeader = ({
  defaultAddress,
  concepts,
  onChange,
  value,
  onSelect,
}: LocationFinderHeaderProps) => {
  return (
    <VStack align="stretch" spacing={4} bgColor="white" paddingX={3} paddingBlock={4} borderRadius={8}>
      <GeolocationAutocomplete defaultValue={defaultAddress} onSelect={onSelect} />
      {concepts?.length && <ConceptCheckboxGroup concepts={concepts} onChange={onChange} value={value} />}
    </VStack>
  );
};
