import { Box, Flex, Image, useCheckbox, useCheckboxGroup, UseCheckboxProps, VisuallyHidden } from '@chakra-ui/react';
import { sortBy } from 'lodash-es';

import { ConceptInformation } from '@/api/gateway-click-collect/configurations';
import { sortPictureClassificationsByWidth } from '@/helpers/menu.helpers';

// Recreate outline with border-radius manually to handle safari issue
// https://bugs.webkit.org/show_bug.cgi?id=20807
const baseStyle = {
  '&::before': {
    borderColor: 'gray.200',
    borderRadius: '13px',
    borderStyle: 'solid',
    borderWidth: '1px',
    bottom: '-2px',
    content: '""',
    left: '-2px',
    position: 'absolute',
    right: '-2px',
    top: '-2px',
  },
};

const checkedStyle = {
  '&::before': {
    borderColor: 'gray.600',
    borderRadius: '13px',
    borderStyle: 'solid',
    borderWidth: '2px',
    bottom: '-3px',
    content: '""',
    left: '-3px',
    position: 'absolute',
    right: '-3px',
    top: '-3px',
  },
};

interface ConceptCheckboxProps extends UseCheckboxProps {
  concept: ConceptInformation;
}

const ConceptCheckbox = ({ concept, ...props }: ConceptCheckboxProps) => {
  const { getCheckboxProps, getInputProps, getLabelProps, htmlProps, state } = useCheckbox(props);
  const style = state.isChecked ? checkedStyle : baseStyle;

  const sortedLogoPictures = sortPictureClassificationsByWidth(concept.logoPictures);

  return (
    <label {...htmlProps}>
      <input {...getInputProps()} hidden />
      <VisuallyHidden {...getLabelProps}>{concept.conceptName}</VisuallyHidden>
      <Box
        cursor="pointer"
        height={'56px'}
        justifyContent="center"
        pos="relative"
        sx={style}
        width={'56px'}
        {...getCheckboxProps()}
      >
        <Image
          objectFit="contain"
          alt={concept.conceptName}
          src={sortedLogoPictures[0].url}
          borderRadius="12px"
          boxSize="54px"
        />
      </Box>
    </label>
  );
};

type ConceptCheckboxGroupProps = {
  concepts: ConceptInformation[];
  defaultValue?: string;
  isDisabled?: boolean;
  onChange?: (value: string) => void;
  value?: string;
};

export const ConceptCheckboxGroup = ({
  concepts,
  value,
  defaultValue,
  onChange,
  ...props
}: ConceptCheckboxGroupProps) => {
  const {
    getCheckboxProps,
    setValue: setSelectedConcepts,
    value: selectedConcepts,
    isDisabled,
  } = useCheckboxGroup({
    ...props,
    value: value ? [value] : [],
    defaultValue: defaultValue ? [defaultValue] : [],
    onChange: (value) => {
      onChange?.(value?.length ? value[0].toString() : '');
    },
  });

  const handleChangeConcept = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedConcepts(event.target.value === selectedConcepts[0] ? [] : [event.target.value]);
  };

  return (
    <Flex align="center" gap={2} overflowY="auto" p={1}>
      {sortBy(concepts, 'label').map((concept) => (
        <ConceptCheckbox
          key={concept.uuid}
          concept={concept}
          {...getCheckboxProps({ value: concept.uuid, isDisabled })}
          onChange={handleChangeConcept}
        />
      ))}
    </Flex>
  );
};
