import ApiError from '@/api/ApiError';

interface ModifierOutOfStock {
  errorType: 'modifier-oos';
  modifierUuid: string;
}

interface ItemOutOfStock {
  errorType: 'item-oos';
  menuItemUuid: string;
}

export type OutOfStocks = ItemOutOfStock | ModifierOutOfStock;

export const getOutOfStocks = (error: ApiError | null): OutOfStocks | null => {
  if (!error?.body) return null;

  const { errorType } = error.body;

  switch (errorType) {
    case 'modifier-oos':
    case 'item-oos': {
      return error.body;
    }
    default: {
      return null;
    }
  }
};
