import { ButtonProps, IconButton, Image } from '@chakra-ui/react';
import { t } from '@lingui/macro';

import ArrowBack from '@/assets/img/arrow-left.svg';

export type KioskBackButtonProps = Pick<ButtonProps, 'onClick' | 'sx'>;

export const KioskBackButton = ({ onClick, sx }: KioskBackButtonProps) => {
  return (
    <IconButton
      aria-label={t`Back`}
      icon={<Image src={ArrowBack} />}
      variant="outline"
      borderColor="gray.300"
      color="gray.700"
      colorScheme="gray"
      height="64px"
      width="64px"
      sx={sx}
      onClick={onClick}
    />
  );
};
