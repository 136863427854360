import { Box, Stack, Text } from '@chakra-ui/react';

import { CartItemSubModifierGroup } from '@/api/gateway-click-collect/orders/types';
import { Modifier, SubModifierGroup } from '@/api/gateway-click-collect/restaurants/types';
import { ModifierGroupQuantityProvider } from '@/contexts/ModifierGroupQuantityContext';
import { MenuItemGridLayout } from '@/layouts/MenuItemGridLayout';

import { KioskBackButton } from '../Kiosk/KioskBackButton';
import { AddModifierGroupFooter } from './AddModifierGroupFooter';
import { ModifierCard } from './ModifierCard/ModifierCard';

export interface AddSubModifierGroupProps {
  modifierGroup: SubModifierGroup;
  pictures: Record<string, string | undefined>;
  currencyCode: string;
  hasBackButton: boolean;
  onSelect: (selectedModifierGroup: CartItemSubModifierGroup) => void;
  onSkip: () => void;
  onBackButtonClick: () => void;
}

export const AddSubModifierGroup = ({
  modifierGroup,
  pictures,
  currencyCode,
  onSkip,
  onSelect,
  hasBackButton,
  onBackButtonClick,
}: AddSubModifierGroupProps) => {
  const handleModifierClick = (selectedModifier: Modifier) => {
    const { menuItemUuid, modifierUuid } = selectedModifier;

    onSelect({
      modifierGroupUuid: modifierGroup.modifierGroupUuid,
      modifiers: [{ quantity: 1, menuItemUuid, modifierUuid }],
    });
  };

  return (
    <ModifierGroupQuantityProvider modifierGroup={modifierGroup}>
      <Stack gap={2} alignItems="center">
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: { kiosk: '589px 50px', '2xl': '678px 50px' },
            justifyContent: 'center',
          }}
        >
          <Stack
            flexDirection="row"
            gap={3}
            alignItems="center"
            justifyContent={hasBackButton ? 'start' : 'center'}
            pr={2}
          >
            {hasBackButton ? <KioskBackButton onClick={onBackButtonClick} /> : <div />}
            <Stack gap={2} textAlign="center" flexGrow={1}>
              <Text fontSize={42} fontWeight="700">
                {modifierGroup.label}
              </Text>
              {!!modifierGroup.description && (
                <Text fontSize={24} fontWeight="700">
                  {modifierGroup.description}
                </Text>
              )}
            </Stack>
          </Stack>
        </Box>
        <MenuItemGridLayout
          sx={{
            mt: 0,
            '@media screen and (min-width: 1366px) and (max-height: 1024px)': {
              gridTemplateColumns: 'repeat(2, minmax(200px, 360px))',
            },
          }}
        >
          {modifierGroup.modifiers?.map((modifier) => {
            return (
              <ModifierCard
                currencyCode={currencyCode}
                modifier={modifier}
                pictures={pictures}
                key={modifier.menuItemUuid}
                pictureUrl={pictures[modifier.menuItemUuid]}
                onClick={() => handleModifierClick(modifier)}
              />
            );
          })}
        </MenuItemGridLayout>
      </Stack>
      {(!modifierGroup.included || modifierGroup.max > 1) && (
        <AddModifierGroupFooter
          onConfirmClick={(newSelectedSubModifiers) => {
            onSelect({
              modifierGroupUuid: modifierGroup.modifierGroupUuid,
              modifiers: newSelectedSubModifiers ?? [],
            });
          }}
          onSkip={onSkip}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            position: 'sticky',
            bottom: 0,
          }}
        />
      )}
    </ModifierGroupQuantityProvider>
  );
};
