import { Flex, Image, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import { MenuElement } from '@/api/types';
import itemPlaceholderImgSrc from '@/assets/img/item_placeholder.png';
import useFormatPrice from '@/hooks/useFormatPrice';

export interface MenuItemProps {
  currencyCode: string;
  menuItem: MenuElement;
}

const itemDescriptionStyle = {
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: '2',
  overflow: 'hidden',
};

export const MenuItem = ({ menuItem, currencyCode }: MenuItemProps) => {
  const { formatPrice } = useFormatPrice();
  const { description, available, label, imageUrl, nutritionalInfo, sellingPrice } = menuItem;
  const hasKcal = typeof nutritionalInfo.energyKcal === 'number';

  return (
    <Flex gap={2} align="center">
      <Image
        alignSelf="baseline"
        borderRadius="md"
        boxSize="64px"
        minW="64px"
        loading="lazy"
        objectFit="cover"
        aria-hidden
        opacity={available ? '1' : '0.4'}
        src={imageUrl ?? itemPlaceholderImgSrc}
      />
      <Flex direction="column" justify="space-between" align="flex-start">
        <Text fontWeight="500" fontSize="md" color={available ? '#02091D' : '#74767B'} textAlign="start">
          {label}
        </Text>
        <Text fontWeight="400" fontSize="xs" color="#74767b" sx={itemDescriptionStyle}>
          {available ? description : <Trans>Item unavailable</Trans>}
        </Text>
        {hasKcal && (
          <Text color="gray.500" fontSize="xs" mt={2}>
            {nutritionalInfo.energyKcal}
            <Trans>kcal</Trans>
          </Text>
        )}
      </Flex>
      {available && (
        <Text ml="auto" fontWeight="500" fontSize="sm" wordBreak="keep-all" whiteSpace="nowrap">
          {formatPrice(sellingPrice, currencyCode)}
        </Text>
      )}
    </Flex>
  );
};
