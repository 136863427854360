import { Box, DrawerHeader, Flex, Image, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import { MenuElement } from '@/api/types';

export interface ItemDrawerHeaderProps {
  item: MenuElement;
}

export const ItemDrawerHeader = ({ item }: ItemDrawerHeaderProps) => {
  const {
    description,
    label,
    nutritionalInfo: { energyKcal },
    imageUrl,
  } = item;
  const hasKcal = typeof energyKcal === 'number';

  return (
    <DrawerHeader padding={0}>
      <Flex direction="column" gap="0.5">
        {imageUrl && <Image alt={label} objectFit="cover" src={imageUrl} width="100%" height="180px" />}
        <Box py={4} px={6}>
          <Text as="h2" mb={1} size="2xl" fontWeight="600">
            {label}
          </Text>
          <Text color="gray.500" fontSize="sm" fontWeight="400">
            {description}
            {hasKcal && (
              <Text as="span" ml={2}>
                <Trans>{energyKcal} kcal</Trans>
              </Text>
            )}
          </Text>
        </Box>
      </Flex>
    </DrawerHeader>
  );
};
