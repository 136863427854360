import { i18n } from '@lingui/core';
import { en, fr, nl } from 'make-plural/plurals';

import { switchDayJsLocale } from './dayjs';

const localeData = { en: { plurals: en }, fr: { plurals: fr }, nl: { plurals: nl } };

const defaultLocale = 'en';

i18n.loadLocaleData(localeData);
i18n.load(defaultLocale, {});
i18n.activate(defaultLocale);

export type SupportedLocales = keyof typeof localeData;

const isLocaleSupported = (locale: string): locale is SupportedLocales => {
  return Object.keys(localeData).includes(locale);
};

export async function activateI18n() {
  const navigatorLocale = window.navigator.language.split('-')[0];
  const supportedLocale = isLocaleSupported(navigatorLocale) ? navigatorLocale : defaultLocale;

  const { messages } = await import(`./locales/${supportedLocale}/messages.ts`);

  i18n.load(supportedLocale, messages);
  i18n.activate(supportedLocale);

  switchDayJsLocale(supportedLocale);
}
