import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  DrawerProps,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { useImmer } from 'use-immer';

import type { MenuElement, ModifierGroup } from '@/api/types';
import { ModifierGroupInput } from '@/components/ModifierGroupInput';
import { NumberInput } from '@/components/NumberInput';
import useFormatPrice from '@/hooks/useFormatPrice';
import { isCartItemValid } from '@/routes/MenuPage/ItemDrawer/ItemDrawer.helpers';
import { ItemDrawerHeader } from '@/routes/MenuPage/ItemDrawer/ItemDrawerHeader';
import type { ItemCart, ModifierCart, ModifierGroupCart } from '@/types';

export interface ItemDrawerProps {
  currencyCode: string;
  isOpen: boolean;
  isOrderAvailable: boolean;
  item: MenuElement | null;
  onAddToCart: (item: ItemCart) => void;
  onClose: () => void;
}

interface ItemDrawerBodyProps extends Pick<ItemDrawerProps, 'isOrderAvailable' | 'currencyCode' | 'onAddToCart'> {
  item: MenuElement;
}

const ItemDrawerContent = ({ item, isOrderAvailable, currencyCode, onAddToCart }: ItemDrawerBodyProps) => {
  const { formatPrice } = useFormatPrice();

  const [itemCart, setItemCart] = useImmer<ItemCart>({
    ...item,
    quantity: 1,
    price: item.sellingPrice,
  });

  const updateQuantity = (quantity: number) => {
    setItemCart((draft) => {
      draft.quantity = quantity;
    });
  };

  const getPrice = (modifierGroups: ModifierGroupCart[]): number => {
    return modifierGroups.reduce((price, modifierGroup): number => {
      if (!modifierGroup.selectedModifiers) return price;

      const modifiersPrice = modifierGroup.selectedModifiers.reduce((previous, modifier): number => {
        const modifierQuantity = modifier.quantity || 1;
        let nestedModifierGroupsPrice = 0;

        if (modifier.selectedModifierGroups) {
          nestedModifierGroupsPrice = modifier.selectedModifierGroups.reduce(
            (nestedModifierGroupsPrice, nestedModifierGroup): number => {
              let nestedModifiersPrice = nestedModifierGroupsPrice;

              if (nestedModifierGroup.selectedModifiers) {
                nestedModifiersPrice += nestedModifierGroup.selectedModifiers.reduce(
                  (tmpNestedModifierPrice, nestedModifier): number => {
                    if (nestedModifier.sellingPrice && nestedModifier.quantity) {
                      return (
                        tmpNestedModifierPrice +
                        nestedModifier.sellingPrice * nestedModifier.quantity * modifierQuantity
                      );
                    }

                    return tmpNestedModifierPrice;
                  },
                  0
                );
              }
              return nestedModifiersPrice;
            },
            0
          );
        }
        if (modifier.sellingPrice && modifier.quantity) {
          return previous + modifier.sellingPrice * modifier.quantity + nestedModifierGroupsPrice;
        }
        return previous + nestedModifierGroupsPrice;
      }, 0);
      return price + modifiersPrice;
    }, 0);
  };

  const updatePrice = () => {
    setItemCart((draft) => {
      if (!draft) {
        return;
      }
      if (draft.selectedModifierGroups && Array.isArray(draft.selectedModifierGroups)) {
        const selectedModifierPrice = getPrice(draft.selectedModifierGroups);
        draft.price = draft.sellingPrice * draft.quantity + selectedModifierPrice * draft.quantity;
      } else {
        draft.price = draft.sellingPrice * draft.quantity;
      }
    });
  };

  const onUpdateQuantity = (quantity: number) => {
    updateQuantity(quantity);
    updatePrice();
  };

  const onUpdateSelectedModifierGroup = (modifierGroup: ModifierGroup, selectedModifiers: ModifierCart[]) => {
    if (itemCart?.selectedModifierGroups) {
      setItemCart((draft) => {
        if (draft?.selectedModifierGroups) {
          const foundModifierGroup = draft.selectedModifierGroups.find(
            ({ modifierGroupUuid }) => modifierGroupUuid === modifierGroup.modifierGroupUuid
          );
          if (foundModifierGroup) {
            foundModifierGroup.selectedModifiers = selectedModifiers;
          } else {
            draft.selectedModifierGroups = [
              ...draft.selectedModifierGroups,
              { ...modifierGroup, selectedModifiers: selectedModifiers },
            ];
          }
        }
      });
    } else {
      setItemCart((cart) => {
        if (cart) {
          cart.selectedModifierGroups = [{ ...modifierGroup, selectedModifiers: selectedModifiers }];
        }
      });
    }
    updatePrice();
  };

  const isAddButtonDisabled = !isOrderAvailable || !isCartItemValid(itemCart, item);

  return (
    // Css to handle scroll issues on iOS safari and Firefox: https://github.com/chakra-ui/chakra-ui/issues/6131
    <DrawerContent overflow="auto" height="100% !important">
      <DrawerCloseButton backgroundColor="white" borderRadius="50%" />
      <ItemDrawerHeader item={item} />
      <DrawerBody p={0} overflow="none" flex={1}>
        <Stack gap={4}>
          {item.modifierGroups.map((modifierGroup) => {
            return (
              <ModifierGroupInput
                onUpdateSelectedModifierGroup={onUpdateSelectedModifierGroup}
                key={modifierGroup.modifierGroupUuid}
                currencyCode={currencyCode}
                modifierGroup={modifierGroup}
              />
            );
          })}
        </Stack>
      </DrawerBody>
      <DrawerFooter>
        <Box w="100%" display="flex" flexDir="column" gap={4}>
          <NumberInput
            defaultValue={1}
            fontSize="xl"
            label={<Trans>Quantity</Trans>}
            min={1}
            onChange={onUpdateQuantity}
            size="md"
          />
          <Button isDisabled={isAddButtonDisabled} onClick={() => itemCart && onAddToCart(itemCart)} size="lg">
            <Text>
              <Trans>Add to cart</Trans> &bull; {formatPrice(itemCart.price ?? 0, currencyCode)}
            </Text>
          </Button>
          {!isOrderAvailable && (
            <Text textAlign="center">
              <Trans>This restaurant is currently closed.</Trans>
            </Text>
          )}
        </Box>
      </DrawerFooter>
    </DrawerContent>
  );
};

export const ItemDrawer = ({ currencyCode, item, onClose, isOpen, isOrderAvailable, onAddToCart }: ItemDrawerProps) => {
  return (
    <Drawer
      size={{ base: 'full', lg: 'md' }}
      isFullHeight
      placement="right"
      onClose={onClose}
      isOpen={isOpen}
      blockScrollOnMount
    >
      <DrawerOverlay width="full" height="full" />
      {item && (
        <ItemDrawerContent
          currencyCode={currencyCode}
          isOrderAvailable={isOrderAvailable}
          item={item}
          onAddToCart={onAddToCart}
        />
      )}
    </Drawer>
  );
};
