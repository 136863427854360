import { Box, Flex, List, ListItem, Radio, RadioGroup, SlideFade, Stack, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import { Modifier, ModifierGroup } from '@/api/types';
import { hasMenuItemCalories } from '@/helpers/menu.helpers';
import useFormatPrice from '@/hooks/useFormatPrice';
import type { ModifierCart } from '@/types';

import { ModifierGroupInput } from './ModifierGroupInput';

interface Props {
  currencyCode: string;
  modifiers: Modifier[];
  selectedModifier: ModifierCart;
  isDisabled: boolean;
  onChange: (modifierUuid: string) => void;
  onNestedChange: (modifierUuid: string, modifierGroup: ModifierGroup, selectedModifiers: ModifierCart[]) => void;
}

export const ModifiersRadioGroup = ({
  currencyCode,
  modifiers,
  selectedModifier,
  isDisabled,
  onChange,
  onNestedChange,
}: Props) => {
  const { formatPrice } = useFormatPrice();

  return (
    selectedModifier && (
      <div>
        <RadioGroup defaultValue={selectedModifier.modifierUuid} onChange={onChange}>
          <Stack gap={2}>
            {modifiers.map((modifier) => {
              const { label, available, modifierUuid, nutritionalInfo, sellingPrice, modifierGroups } = modifier;
              const formattedPrice = formatPrice(sellingPrice, currencyCode);

              return (
                <Box key={modifierUuid}>
                  <Flex fontWeight="400" fontSize="sm" align="center" justify="space-between" gap={2}>
                    <Radio isDisabled={!available} value={modifierUuid} size="lg">
                      <Flex justifyContent="space-between" gap={1} alignItems="baseline">
                        <Text fontWeight="400" fontSize="md" flexWrap="nowrap">
                          {label}
                        </Text>
                        {hasMenuItemCalories(modifier) && (
                          <Text fontSize="sm" color="gray.500">
                            {nutritionalInfo.energyKcal}
                            <Trans>kcal</Trans>
                          </Text>
                        )}
                      </Flex>
                    </Radio>
                    {sellingPrice > 0 && (
                      <Text color={!isDisabled && available ? '#02091D' : '#74767B'} whiteSpace="nowrap">
                        + {formattedPrice}
                      </Text>
                    )}
                  </Flex>
                  <SlideFade
                    offsetY="-20px"
                    in={!isDisabled && available && selectedModifier.modifierUuid === modifier.modifierUuid}
                  >
                    <List>
                      {!isDisabled &&
                        available &&
                        selectedModifier.modifierUuid === modifierUuid &&
                        modifierGroups.map((modifierGroup: ModifierGroup, index: number) => {
                          return (
                            <ListItem paddingLeft={4} mt={4} key={`${modifierGroup.modifierGroupUuid}-${index}`}>
                              <ModifierGroupInput
                                onUpdateSelectedModifierGroup={(modifierGroup, selectedModifier) =>
                                  onNestedChange(modifier.modifierUuid, modifierGroup, selectedModifier)
                                }
                                currencyCode={currencyCode}
                                modifierGroup={modifierGroup}
                              />
                            </ListItem>
                          );
                        })}
                    </List>
                  </SlideFade>
                </Box>
              );
            })}
          </Stack>
        </RadioGroup>
      </div>
    )
  );
};
