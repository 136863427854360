import { Tag, TagLabel } from '@chakra-ui/react';

interface RestaurantTagProps {
  tag: string;
}

export const RestaurantTag = ({ tag }: RestaurantTagProps) => {
  return (
    <Tag borderRadius="full" backgroundColor="white" border="3px solid black" paddingY="px" opacity="85%">
      <TagLabel fontWeight={700} fontSize={{ base: 'md', '2xl': 'xl' }}>
        {tag}
      </TagLabel>
    </Tag>
  );
};
