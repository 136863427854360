import { CardBody, Stack } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

export const RestaurantCardBody = ({ children, compact = false }: PropsWithChildren<{ compact?: boolean }>) => {
  if (compact) {
    return (
      <CardBody align="stretch" as={Stack} flex={1} gap={2} justify="space-between" paddingBlock={3} paddingX={4}>
        {children}
      </CardBody>
    );
  }

  return (
    <CardBody
      alignItems="baseline"
      display="flex"
      flex={1}
      flexDirection="row"
      flexWrap="wrap"
      gap={2}
      justifyContent="stretch"
      paddingBlock={3}
      paddingX={4}
    >
      {children}
    </CardBody>
  );
};
