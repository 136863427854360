import { DeleteIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, List, ListItem, Text, UnorderedList } from '@chakra-ui/react';

import useFormatPrice from '@/hooks/useFormatPrice';
import type { ItemCart, ModifierGroupCart } from '@/types';

interface Props {
  itemCart: ItemCart;
  currencyCode: string;
  handleRemoveItemCart: (itemCart: ItemCart) => void;
}

interface CartItemModifierListProp {
  selectedModifierGroups?: ModifierGroupCart[];
  unorderedList?: boolean;
}

const CartItemModifierList = ({ selectedModifierGroups, unorderedList }: CartItemModifierListProp) => {
  if (!selectedModifierGroups) return null;
  const CustomList = unorderedList ? UnorderedList : List;
  return (
    <>
      {selectedModifierGroups.map((modifierGroup) => (
        <CustomList key={modifierGroup.modifierGroupUuid}>
          {modifierGroup.selectedModifiers?.map((modifier) => (
            <ListItem key={modifier.modifierUuid}>
              {modifier.quantity} {modifier.label}
              {modifier.selectedModifierGroups && (
                <CartItemModifierList selectedModifierGroups={modifier.selectedModifierGroups} unorderedList={true} />
              )}
            </ListItem>
          ))}
        </CustomList>
      ))}
    </>
  );
};

const CartItemCard = ({ itemCart, currencyCode, handleRemoveItemCart }: Props) => {
  const { formatPrice } = useFormatPrice();

  return (
    <Box textAlign="initial" paddingY={4} display="flex" gap={8} justifyContent="space-between" w="100%">
      <Box display="flex" gap={4}>
        <Box>
          <Box fontWeight="400" fontSize="md" width="3ch" textAlign="center" padding="1" bg="gray.100">
            {itemCart.quantity}
          </Box>
        </Box>
        <Box>
          <Text marginBottom="6px" fontWeight="500" fontSize="md">
            {itemCart.label}
          </Text>
          <Box fontSize="sm" color="gray.500" fontWeight="400">
            <CartItemModifierList selectedModifierGroups={itemCart.selectedModifierGroups} />
          </Box>
        </Box>
      </Box>
      <Flex flexDir="column">
        <Text fontWeight="400" fontSize="md">
          {itemCart.price
            ? formatPrice(itemCart.price, currencyCode)
            : formatPrice(itemCart.sellingPrice * itemCart.quantity, currencyCode)}
        </Text>
        <Box margin="auto">
          <Button onClick={() => handleRemoveItemCart(itemCart)} size="md" variant="ghost">
            <DeleteIcon color="red.500" />
          </Button>
        </Box>
      </Flex>
    </Box>
  );
};

export default CartItemCard;
