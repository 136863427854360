import { Box, Stack, Text, useBoolean, useRadioGroup } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import dayjs from 'dayjs';

import { Restaurant } from '@/api/gateway-click-collect/restaurants';
import { getPreOrderTimes, isRestaurantOpen } from '@/helpers/openingHours.helpers';
import { PickupTimeSelector } from '@/routes/MenuPage/CartDrawer/PickupForm/PickupTimeSelector';
import PickupTypeCard from '@/routes/MenuPage/CartDrawer/PickupForm/PickupTypeCard';
import { PickupOption, PickupTypeEnum } from '@/routes/MenuPage/CartDrawer/types';

export interface PickupFormProps extends Pick<Restaurant, 'status' | 'openingHours' | 'timezone'> {
  onChange: (pickupOption: PickupOption | null) => void;
  pickupOption: PickupOption | null;
}

export const PickupForm = ({ onChange, pickupOption, openingHours, status, timezone }: PickupFormProps) => {
  const isOpen = isRestaurantOpen(status);
  const preOrderTimes = getPreOrderTimes({ openingHours, timezone, status });
  const canPreOrder = !!preOrderTimes.length;

  const [showTimeSelector, toggleTimeSelector] = useBoolean(pickupOption?.type === PickupTypeEnum.LATER);

  const { getRadioProps, getRootProps } = useRadioGroup({
    onChange: (type: PickupTypeEnum) => {
      if (type === PickupTypeEnum.LATER) {
        toggleTimeSelector.on();
      } else {
        toggleTimeSelector.off();
      }
      onChange({ type });
    },
    value: pickupOption?.type ?? '',
  });

  const handleTimeSelect = (pickupTime: string) => {
    onChange({ type: PickupTypeEnum.LATER, pickupTime });
    toggleTimeSelector.off();
  };

  return (
    <Stack gap={4}>
      <Text fontWeight="500" fontSize="md">
        <Trans>Pickup time</Trans>
      </Text>
      <Stack {...getRootProps()} gap={2}>
        {isOpen && (
          <PickupTypeCard {...getRadioProps({ value: PickupTypeEnum.ASAP })}>
            <Trans>ASAP (10min)</Trans>
          </PickupTypeCard>
        )}
        {canPreOrder && (
          <Box
            onClick={() => {
              toggleTimeSelector.on();
            }}
          >
            <PickupTypeCard {...getRadioProps({ value: PickupTypeEnum.LATER })}>
              {pickupOption?.pickupTime ? (
                <Trans>Today at {dayjs(pickupOption?.pickupTime, 'HH:mm:ss').format('LT')}</Trans>
              ) : (
                <Trans>Later</Trans>
              )}
            </PickupTypeCard>
          </Box>
        )}
      </Stack>
      {showTimeSelector && <PickupTimeSelector onSelect={handleTimeSelect} preOrderTimes={preOrderTimes} />}
    </Stack>
  );
};
