import { Box, BoxProps, forwardRef } from '@chakra-ui/react';

const Card = forwardRef<BoxProps, 'div'>((props, ref) => {
  return (
    <Box
      p={6}
      dropShadow="0px 0px 2px 0px #37131714"
      borderRadius="16px"
      border="1px solid #EAECF0"
      backgroundColor="#FFFFFF"
      {...props}
      ref={ref}
    >
      {props.children}
    </Box>
  );
});

export default Card;
