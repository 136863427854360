/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import { launchIntercom } from '@/helpers/intercom.helpers';

const IntercomLauncher = () => {
  return (
    <Text textAlign="center">
      <Trans>Need help?</Trans>{' '}
      <Button variant="link" onClick={launchIntercom}>
        <Trans>Contact support</Trans>
      </Button>
    </Text>
  );
};

export default IntercomLauncher;
