import { Box, Button, Heading, Icon, Stack } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { useLocalStorage } from '@uidotdev/usehooks';
import { PropsWithChildren } from 'react';
import { HiHome } from 'react-icons/hi';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { useValidateOrderQuery } from '@/api/gateway-click-collect/orders';
import { ErrorScreen } from '@/components/ErrorScreen';
import { useCart } from '@/contexts/cart';
import { useMenuDataContext } from '@/contexts/MenuDataProvider';
import { formatCartForValidation, getCartPrice } from '@/helpers/cart.helpers';
import dataLayer from '@/helpers/dataLayer.helpers';
import { CartItemCard } from '@/routes/CartPage/CartItemCard';

import { getOutOfStocks } from './CartPage.helpers';
import { CartPageFooter } from './CartPageFooter';

const Layout = ({ children }: PropsWithChildren) => {
  return (
    <Stack height="100%" justify="space-between" gap={6}>
      <Box
        backgroundColor="white"
        borderBottom="solid 1px #D9D9D9"
        boxShadow="0px 4px 16px 0px #0000001A"
        paddingY={10}
      >
        <Heading as="h2" textAlign="center" textTransform="uppercase">
          <Trans>Your cart</Trans>
        </Heading>
      </Box>
      {children}
    </Stack>
  );
};

export const CartPage = () => {
  const { restaurantPlatformId } = useParams() as { restaurantPlatformId: string };
  const navigate = useNavigate();
  const [terminalUuid] = useLocalStorage('TERMINAL_UUID', '');
  const {
    restaurant: { currencyCode, languageCode },
  } = useMenuDataContext();

  const { cart, setCart, resetCart } = useCart();
  const isCartEmpty = !cart.items.length;

  const {
    data: validPrice,
    isLoading,
    error,
    isError: isCartInvalid,
  } = useValidateOrderQuery({
    options: {
      enabled: !!cart.items.length,
      retry: false,
      select: (validOrder) => validOrder.totalPrice,
    },
    requestParams: {
      cart: formatCartForValidation(cart, terminalUuid),
    },
  });

  const price = validPrice ?? getCartPrice(cart.items);
  const outOfStocks = getOutOfStocks(error);
  const hasOutOfStocks = !!outOfStocks;

  const deleteCartItem = (cartItemIndex: number) => {
    dataLayer.logItemRemoved(cart.kitchenLabel, cart.conceptLabel, cart.items[cartItemIndex], 'onsite');
    const items = cart.items.filter((_, index) => index !== cartItemIndex);
    setCart({ ...cart, items });
  };

  const handlePaymentClick = () => {
    dataLayer.clickPay({
      brandLabel: cart.conceptLabel,
      currencyCode,
      kitchenLabel: cart.kitchenLabel,
      orderChannel: 'onsite',
      orderType: 'asap_order',
      paymentValue: price,
    });

    navigate('/onSite/customerName');
  };

  if (isCartEmpty) {
    return <Navigate to={`/onSite/menu/${restaurantPlatformId}`} />;
  }

  const handleErrorToHomeClick = () => {
    resetCart();
    navigate(`/onSite/menu/${restaurantPlatformId}`);
  };

  if (isCartInvalid && !hasOutOfStocks) {
    return (
      <Layout>
        <ErrorScreen
          button={
            <Button
              colorScheme="black"
              fontWeight={700}
              leftIcon={<Icon as={HiHome} marginBottom={0.5} />}
              size="lg"
              textTransform="uppercase"
              onClick={handleErrorToHomeClick}
              variant="solid"
            >
              <Trans>Home</Trans>
            </Button>
          }
          text={
            <Stack textAlign="center">
              <Heading as="p" size="lg" fontWeight={700}>
                <Trans>Sorry, we’ve encountered an error.</Trans>
              </Heading>
              <Heading as="p" size="lg" fontWeight={700}>
                <Trans>Go back to home page and start again. </Trans>
              </Heading>
            </Stack>
          }
          sx={{ flex: 1, marginY: 'auto' }}
        />
      </Layout>
    );
  }

  return (
    <Layout>
      <Stack marginY="auto" paddingX={6} gap={6} overflow="auto">
        {cart.items.map((cartItem, index) => {
          return (
            <CartItemCard
              cartItem={cartItem}
              currencyCode={currencyCode}
              key={index}
              onDelete={() => deleteCartItem(index)}
              outOfStocks={outOfStocks}
            />
          );
        })}
      </Stack>
      <CartPageFooter
        currencyCode={currencyCode}
        languageCode={languageCode}
        hasOutOfStocks={hasOutOfStocks}
        isError={isCartInvalid}
        isLoading={isLoading}
        onPaymentClick={handlePaymentClick}
        price={price}
        restaurantPlatformId={restaurantPlatformId}
      />
    </Layout>
  );
};
