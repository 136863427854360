import { Box, Button, ButtonProps, forwardRef, useBreakpointValue } from '@chakra-ui/react';

const CtaButton = forwardRef<ButtonProps, 'button'>((props, ref) => {
  return (
    <Box
      position="fixed"
      bottom={0}
      p={6}
      w={useBreakpointValue({ base: '100%', md: '640px' })}
      left={0}
      right={0}
      mx="auto"
      zIndex={1}
    >
      <Button borderRadius="md" size="lg" w="100%" {...props} ref={ref}>
        {props.children}
      </Button>
    </Box>
  );
});

export default CtaButton;
