import { useContext } from 'react';

import { ModifierQuantityContext } from './ModifierGroupQuantityProvider';

export const useModifierGroupQuantity = () => {
  const context = useContext(ModifierQuantityContext);

  if (context === null) {
    throw new Error('useModifierGroupQuantity must be used within a ModifierGroupQuantityProvider');
  }

  return context;
};
