import {
  Button,
  chakra,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import React, { useEffect, useRef, useState } from 'react';

import announceAnimation from '@/assets/anim/lottie_announcement.json';
import { LottieAnimation } from '@/components/LottieAnimation';

const StyledInput = chakra(Input, {
  baseStyle: {
    background: 'white',
    maxWidth: '300px',
    padding: '16px',
    border: '1px solid gray.300',
  },
});

interface CustomerNameFormProps {
  onSubmit: (value: string) => void;
}

export const CustomerNameForm = ({ onSubmit }: CustomerNameFormProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [customerName, setCustomerName] = useState('');

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, []);

  const isError = !customerName;

  const handleSubmit = () => {
    if (isError) return;

    onSubmit(customerName);
  };

  return (
    <>
      <LottieAnimation animation={announceAnimation} sx={{ width: '250px' }} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <FormControl isRequired isInvalid={isError} width="fit-content">
          <Stack>
            <FormLabel>
              <Heading as="span" fontWeight={700} fontSize={32}>
                <Trans>Your name?</Trans>
              </Heading>
            </FormLabel>

            <Flex flexWrap="wrap" gap={2} justifyContent="center">
              <Stack>
                <StyledInput
                  ref={inputRef}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCustomerName(e.target.value)}
                  isRequired
                />
                <FormHelperText>
                  <Trans>Just so we can shout your name when the order is ready</Trans>
                </FormHelperText>
              </Stack>
              <Button type="submit" disabled={isError} sx={{ height: 46 }}>
                <Text fontSize={24}>
                  <Trans>Submit</Trans>
                </Text>
              </Button>
            </Flex>
          </Stack>
        </FormControl>
      </form>
    </>
  );
};
