import { useKeycloak } from '@react-keycloak/web';
import { Outlet } from 'react-router-dom';

import { useOnSiteInfo } from '@/hooks/useOnSiteInfo';

export const ProtectedRoute = () => {
  const { isUserAuthenticated } = useOnSiteInfo();
  const { keycloak } = useKeycloak();

  if (!isUserAuthenticated) {
    keycloak.login();
  }

  return <Outlet />;
};
