import { FormControl, FormHelperText } from '@chakra-ui/form-control';
import { Box, Flex, Stack } from '@chakra-ui/layout';
import { Button, chakra, Input, SystemStyleObject, Text } from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';
import camelize from 'camelize-ts';
import { useState } from 'react';

import { CouponError } from '@/api/types';
import useFormatPrice from '@/hooks/useFormatPrice';

import { useCouponErrorMessage } from './useCouponErrorMessage';

const StyledInput = chakra(Input, {
  baseStyle: {
    background: 'white',
    padding: '16px',
    border: '3px solid gray.300',
    '&:disabled': {
      background: '#F0F1F4',
      color: 'gray.500',
    },
  },
});

export interface CouponFormProps {
  onSubmit: ({ couponCode }: { couponCode: string }) => void;
  onRemove: () => void;
  isLoading: boolean;
  isDisabled: boolean;
  couponError?: CouponError;
  reductionAmount?: number;
  currencyCode: string;
  isKiosk?: boolean;
  sx?: SystemStyleObject;
}

export const CouponForm = ({
  currencyCode,
  onSubmit,
  onRemove,
  couponError,
  isDisabled,
  isLoading,
  reductionAmount,
  isKiosk,
  sx,
}: CouponFormProps) => {
  const [couponCode, setCouponCode] = useState('');

  const { formatPrice } = useFormatPrice();

  const isCouponCodeEmpty = !couponCode.length;

  const handleSubmit = () => {
    if (isCouponCodeEmpty) return;

    reductionAmount ? onRemove() : onSubmit({ couponCode: couponCode.trim().toUpperCase() });
  };

  const errorMessage = useCouponErrorMessage({
    couponError: camelize(couponError),
    currencyCode,
  });

  return (
    <Box sx={sx}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <FormControl isInvalid={!!couponError}>
          <Flex gap={2} my={2}>
            <Stack gap={0} flexGrow={3}>
              <StyledInput
                onChange={(e) => setCouponCode(e.target.value)}
                placeholder={t`Discount code`}
                disabled={isLoading || !!reductionAmount}
                errorBorderColor="red.error.600"
                sx={{
                  ...(isKiosk && {
                    fontSize: '3xl',
                    height: '82px',
                    width: '100%',
                  }),
                  padding: '23px',
                }}
              />
              {!!errorMessage && (
                <FormHelperText
                  color="red.error.600"
                  sx={{
                    fontSize: 16,
                    ...(isKiosk && {
                      fontSize: '3xl',
                    }),
                  }}
                >
                  {errorMessage}
                </FormHelperText>
              )}
            </Stack>
            <Button
              isLoading={isLoading}
              isDisabled={isDisabled}
              variant={reductionAmount ? 'ghost' : 'solid'}
              type="submit"
              sx={{
                flexGrow: 1,
                height: '46px',
                ...(isKiosk && {
                  fontSize: '3xl',
                  height: '82px',
                  maxWidth: '300px',
                  width: '100%',
                  padding: '23px',
                }),
              }}
            >
              <Text>{reductionAmount ? <Trans>Remove code</Trans> : <Trans>Apply</Trans>}</Text>
            </Button>
          </Flex>
        </FormControl>
        {!!reductionAmount && (
          <Flex gap={4} py={2} justifyContent="space-between">
            <Text
              color="green.success.600"
              fontWeight={500}
              sx={{
                fontSize: 16,
                ...(isKiosk && {
                  fontSize: 'lg',
                }),
              }}
            >
              <Trans>Promo {couponCode} applied</Trans>
            </Text>
            <Text
              color="green.success.600"
              fontWeight={500}
              sx={{
                fontSize: 16,
                whiteSpace: 'nowrap',
                ...(isKiosk && { fontSize: 'lg' }),
              }}
            >
              - {formatPrice(reductionAmount, currencyCode)}
            </Text>
          </Flex>
        )}
      </form>
    </Box>
  );
};
