import { UseMutationOptions } from '@tanstack/react-query';

import { useTerminalSessionMutation } from '@/hooks/useTerminalSessionMutation';

import { prepareOrderOnSite } from './api';
import { PrepareOrderOnSite, PrepareOrderOnSiteRequest } from './types';

export const usePrepareOrderOnSiteMutation = (
  options: UseMutationOptions<PrepareOrderOnSite, Error, PrepareOrderOnSiteRequest>
) => {
  return useTerminalSessionMutation(
    (prepareOrderOnSiteParam: PrepareOrderOnSiteRequest) => (authenticationPassPhrase) =>
      prepareOrderOnSite({ prepareOrderOnSiteParam, authenticationPassPhrase }),
    options
  );
};
