import { Flex, Image, Text } from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';
import dayjs from 'dayjs';
import { useMemo } from 'react';

import bagsAnimation from '@/assets/anim/lottie_bags.json';
import burgerAnimation from '@/assets/anim/lottie_burger.json';
import orderDeliveredImgSrc from '@/assets/img/order_delivered.png';
import orderRejectedImgSrc from '@/assets/img/order_rejected.png';
import { LottieAnimation } from '@/components/LottieAnimation';
import { OrderStatus } from '@/types';

type Props = {
  number: string;
  status?: OrderStatus;
  readyAt: string;
};

const OrderStatusCard = ({ number, status, readyAt }: Props) => {
  const orderStatusMapping = useMemo(
    () => ({
      [OrderStatus.ACCEPTED]: {
        graphicContent: <LottieAnimation animation={burgerAnimation} sx={{ height: '110' }} />,
        text: (
          <Trans>
            Thank you! Your order{' '}
            <Text as="span" fontWeight={700}>
              #{number}
            </Text>{' '}
            is being prepared
          </Trans>
        ),
        textColor: 'black',
      },
      [OrderStatus.READY]: {
        graphicContent: <LottieAnimation animation={bagsAnimation} sx={{ height: '110', width: '95px' }} />,
        text: (
          <Trans>
            Lets go! Your order <Text fontWeight={700}>#{number}</Text> is ready!
          </Trans>
        ),
        textColor: 'green.success.main',
      },
      [OrderStatus.DELIVERED]: {
        graphicContent: <Image src={orderDeliveredImgSrc} />,
        text: t`Thanks ! Enjoy your meal ;)`,
        textColor: 'green.success.main',
      },
      [OrderStatus.REJECTED]: {
        graphicContent: <Image src={orderRejectedImgSrc} />,
        text: t`We encountered a problem`,
        textColor: undefined,
      },
    }),
    [number]
  );

  const orderStatus = useMemo(() => (status ? orderStatusMapping[status] : undefined), [status, orderStatusMapping]);
  const isReadySoon = dayjs(readyAt).diff(dayjs(), 'minutes') <= 10;
  const isStatusVisible = orderStatus && isReadySoon;

  return (
    <Flex flexDirection="column" alignItems="center" gap={4}>
      {isStatusVisible && (
        <>
          {orderStatus.graphicContent}
          <Text fontSize={18} color={orderStatus.textColor} textAlign="center">
            {orderStatus.text}
          </Text>
        </>
      )}
    </Flex>
  );
};

export default OrderStatusCard;
