import { Link, Text } from '@chakra-ui/react';
import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro';

export const TermsAgreementCheckboxLabel = () => {
  if (i18n.locale === 'fr') {
    return (
      <span>
        J&apos;ai lu et j&apos;accepte les{' '}
        <Link
          href="https://storage.googleapis.com/backbone-customer-orders-concepts_video_assets/legal/cgv.pdf"
          isExternal
        >
          conditions générales d&apos;utilisation
        </Link>{' '}
        et la{' '}
        <Link
          href="https://storage.googleapis.com/backbone-customer-orders-concepts_video_assets/legal/politique_de_confidentialite.pdf"
          isExternal
        >
          politique de confidentialité
        </Link>
        .{' '}
        <Text as="span" color="red.required">
          {' '}
          *
        </Text>
      </span>
    );
  }

  return (
    <span>
      <Trans>
        I accept the{' '}
        <Link
          href="https://storage.googleapis.com/backbone-customer-orders-concepts_video_assets/legal/terms%26policy.pdf"
          isExternal
        >
          terms and conditions
        </Link>
      </Trans>
      <Text as="span" color="red.required">
        {' '}
        *
      </Text>
    </span>
  );
};
