import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Stack,
  useBoolean,
  useDisclosure,
} from '@chakra-ui/react';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { setContext } from '@sentry/react';
import { Elements } from '@stripe/react-stripe-js';
import { Stripe, StripeElementsOptionsClientSecret } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { useQuery } from '@tanstack/react-query';
import { useLocalStorage } from '@uidotdev/usehooks';
import dayjs from 'dayjs';
import { omit } from 'lodash-es';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { prepareOrder } from '@/api/api';
import ApiError from '@/api/ApiError';
import { useRestaurantQuery } from '@/api/gateway-click-collect';
import {
  GetPaymentProviderConfigurationCountryCodeEnum,
  GetPaymentProviderConfigurationCurrencyCodeEnum,
  usePaymentProviderConfigurationQuery,
} from '@/api/gateway-click-collect/configurations';
import { PrepareOrderResponse } from '@/api/types';
import checkoutImgSrc from '@/assets/img/checkout.png';
import Card from '@/components/Card';
import CheckoutForm from '@/components/CheckoutForm';
import OrderInfosCard from '@/components/Order/OrderInfosCard';
import PageLoader from '@/components/PageLoader';
import PaymentMethodModal from '@/components/PaymentMethodModal';
import { useCart, useCartRequest } from '@/contexts/cart';
import { useOrder } from '@/contexts/order';
import dataLayer from '@/helpers/dataLayer.helpers';
import usePageViewTracker from '@/hooks/usePageViewTracker';
import { SupportedLocales } from '@/i18n';
import { AppLayout } from '@/layouts/AppLayout';

let stripePromise: Promise<Stripe | null> | null;

const stripeOptions: StripeElementsOptionsClientSecret = {
  appearance: {
    theme: 'stripe',
    variables: { fontFamily: 'DM Sans Variable, sans-serif' },
    rules: {
      '.Label': {
        fontFamily: 'DM Sans Variable, sans-serif',
        fontSize: '16px',
        lineHeight: '24px',
        color: 'rgb(26, 32, 44)',
      },
      '.Input:hover': {
        borderColor: '#000',
      },
      '.Input:focus': {
        borderColor: '#000000',
        boxShadow: `0 0 0 1px #000000`,
      },
    },
  },
};

export const CheckoutPage = () => {
  const {
    isOpen: isPaymentMethodModalOpen,
    onOpen: onPaymentMethodModalOpen,
    onClose: onPaymentMethodModalClose,
  } = useDisclosure();

  const [, setPaymentUuid] = useLocalStorage<null | string>('PAYMENT_UUID', null);
  const [isTooLateForPayment, setIsTooLateForPayment] = useState<boolean>(false);
  const [piSecret, setPiSecret] = useLocalStorage<string | null>('PI_SECRET', null);
  const [isOrderInit, setIsOrderInit] = useBoolean(false);

  const { order, setOrder } = useOrder();
  const {
    cart: { restaurantPlatformId, restaurantAddress, restaurantName, accessInstructions, kitchenLabel, conceptLabel },
  } = useCart();

  const cartRequest = useCartRequest();

  const {
    data: restaurant,
    isLoading: isRestaurantLoading,
    isError: isRestaurantOnError,
  } = useRestaurantQuery({
    requestParams: { restaurantPlatformId: cartRequest.restaurant_platform_id },
    options: {
      cacheTime: Infinity,
    },
  });

  const hasStripeLocaleCodes = !!(restaurant?.languageCode && restaurant?.currencyCode);

  const {
    isLoading: isPaymentConfigurationLoading,
    data: paymentConfiguration,
    isError: isPaymentConfigurationOnError,
  } = usePaymentProviderConfigurationQuery({
    requestParams: {
      countryCode: restaurant?.languageCode as GetPaymentProviderConfigurationCountryCodeEnum,
      currencyCode: restaurant?.currencyCode as GetPaymentProviderConfigurationCurrencyCodeEnum,
    },
    options: {
      enabled: !!restaurant,
    },
  });

  const { isError: isPrepareOrderOnError, isLoading } = useQuery<PrepareOrderResponse, ApiError>(
    ['prepareOrder', cartRequest],
    () => prepareOrder(cartRequest, order?.uuid),
    {
      enabled: !!paymentConfiguration && !!cartRequest.restaurant_platform_id,
      onSuccess: ({ payment_uuid, pi_secret, order, stripe_client_id }) => {
        if (payment_uuid) {
          dataLayer.logCheckoutStarted(kitchenLabel, conceptLabel, order, pi_secret);
          setPaymentUuid(payment_uuid);
          setPiSecret(pi_secret);
          setOrder(order);
          setContext('order', { ...omit(order, ['items']) });
          stripePromise = createStripePromise(stripe_client_id);
          setIsOrderInit.on();
        }
      },
      cacheTime: 0,
    }
  );

  useEffect(() => {
    if (!cartRequest || !cartRequest.pickup_time) return;

    const updatePaymentAvailability = setInterval(() => {
      setIsTooLateForPayment(dayjs(order?.pickup_time).diff(dayjs(), 'minute') <= 9);
    }, 2000);

    return () => clearInterval(updatePaymentAvailability);
  }, [cartRequest, order]);

  const createStripePromise = (stripeAccount?: string) => {
    if (!paymentConfiguration?.publishableKey) return null;

    return loadStripe(paymentConfiguration.publishableKey, {
      locale: i18n.locale as SupportedLocales,
      ...(stripeAccount && {
        stripeAccount,
      }),
    });
  };

  const handlePay = () => {
    if (order && piSecret) {
      dataLayer.clickPay({
        brandLabel: conceptLabel,
        currencyCode: order.currency,
        kitchenLabel,
        orderChannel: 'online',
        orderNumber: order.number,
        orderType: order.pickup_time ? 'preorder' : 'asap_order',
        paymentValue: order.total_price,
      });
    }
  };

  usePageViewTracker(kitchenLabel, conceptLabel);

  if (isLoading || (isPaymentConfigurationLoading && !hasStripeLocaleCodes) || isRestaurantLoading) {
    return <PageLoader />;
  }

  const isError = isPrepareOrderOnError || isPaymentConfigurationOnError || isRestaurantOnError;

  return (
    <AppLayout title={t`Payment`} hasArrowBack url={`/menu/${restaurantPlatformId}`}>
      <Box p={6} pb={0}>
        <OrderInfosCard
          kitchenAddress={restaurantAddress}
          kitchenName={restaurantName}
          kitchenAccessInstructions={accessInstructions}
          pickupTime={order?.pickup_time}
        />
      </Box>
      <Box p={6}>
        <Card>
          <Image src={checkoutImgSrc} margin="auto" width="144px" />
          <Heading as="h5" size="sm" textAlign="center">
            <Trans>Payment info</Trans>
          </Heading>
          <Box mt={6}>
            {isError && (
              <Stack gap={2}>
                <Alert status="error" mt={4} fontSize="xs">
                  <AlertIcon />
                  <Trans>Sorry, we’re having trouble loading payment information. Please start your order again.</Trans>
                </Alert>
                <Button as={Link} to={restaurant ? `/${restaurant?.locationUuid}` : '/'}>
                  <Trans>Go to Home</Trans>
                </Button>
              </Stack>
            )}
            {isOrderInit && order && piSecret && (
              <Elements options={{ ...stripeOptions, clientSecret: piSecret }} stripe={stripePromise}>
                <CheckoutForm
                  price={order.total_price}
                  currency={order.currency}
                  isPreOrder={!!order.pickup_time}
                  isTooLateForPayment={isTooLateForPayment}
                  orderUuid={order.uuid}
                  canAskForReceipt={!order.coupon_info}
                  onPay={handlePay}
                  paymentMethodSelector={
                    <>
                      <PaymentMethodModal
                        isOpen={isPaymentMethodModalOpen}
                        onClose={onPaymentMethodModalClose}
                        onSelected={(paymentMethod, other) =>
                          dataLayer.logPaymentMethodSelected(kitchenLabel, conceptLabel, paymentMethod, other)
                        }
                      />
                      <Flex justifyContent="center" mt={4}>
                        <Button
                          variant="link"
                          margin="auto"
                          onClick={() => {
                            dataLayer.logPaymentMethodSelectorOpened(kitchenLabel, conceptLabel);
                            onPaymentMethodModalOpen();
                          }}
                        >
                          <Trans>Use another payment method</Trans>
                        </Button>
                      </Flex>
                    </>
                  }
                />
              </Elements>
            )}
          </Box>
        </Card>
      </Box>
    </AppLayout>
  );
};
