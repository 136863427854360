import * as gatewayClickCollect from '@tasterkitchens/client-ts-gateway-click-collect-v1';

import ApiError from '@/api/ApiError';

export type GatewayClickCollect = {
  configurationsApi: gatewayClickCollect.ConfigurationApi;
  locationsApi: gatewayClickCollect.LocationsApi;
  ordersApi: gatewayClickCollect.OrdersApi;
  restaurantsApi: gatewayClickCollect.RestaurantsApi;
};

//FIXME: https://github.com/OpenAPITools/openapi-generator/issues/7720
export class NoContentMiddleware implements gatewayClickCollect.Middleware {
  public post?(context: gatewayClickCollect.ResponseContext): Promise<Response | void> {
    if (context.response.status === 204) {
      throw new ApiError({
        body: context.response.body,
        detail: '',
        reason: '',
        status: context.response.status,
        title: '',
        type: context.response.type,
      });
    }

    return Promise.resolve(context.response);
  }
}

export const initializeGatewayClickCollect = (getAccessToken: () => string) => {
  const apiConfiguration = new gatewayClickCollect.Configuration({
    accessToken: getAccessToken,
    basePath: import.meta.env.VITE_GATEWAY_CLICK_COLLECT_API,
    middleware: [new NoContentMiddleware()],
  });

  const configurationsApi = new gatewayClickCollect.ConfigurationApi(apiConfiguration);
  const locationsApi = new gatewayClickCollect.LocationsApi(apiConfiguration);
  const ordersApi = new gatewayClickCollect.OrdersApi(apiConfiguration);
  const restaurantsApi = new gatewayClickCollect.RestaurantsApi(apiConfiguration);

  return {
    configurationsApi,
    locationsApi,
    ordersApi,
    restaurantsApi,
  };
};
