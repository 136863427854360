import { Button, Icon, Stack, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { HiHome } from 'react-icons/hi';
import { Link } from 'react-router-dom';

export interface KioskOutOfStockContentProps {
  redirectUrl: string;
}

export const KioskOutOfStockContent = ({ redirectUrl }: KioskOutOfStockContentProps) => {
  return (
    <Stack flex={1} justifyContent="center" alignItems="center">
      <Text fontSize="2xl" fontWeight={700}>
        <Trans>Sorry, all items are out of stock.</Trans>
      </Text>
      <Text fontSize="2xl" fontWeight={700}>
        <Trans>Please choose another category.</Trans>
      </Text>
      <Button
        as={Link}
        colorScheme="black"
        fontWeight={700}
        leftIcon={<Icon as={HiHome} marginBottom={0.5} />}
        marginTop={4}
        size="lg"
        textTransform="uppercase"
        to={redirectUrl}
        variant="solid"
      >
        <Trans>Home</Trans>
      </Button>
    </Stack>
  );
};
