import { useLocalStorage } from '@uidotdev/usehooks';
import React, { createContext, ReactNode, useCallback, useContext, useMemo } from 'react';

import { formatCart } from '@/helpers/cart.helpers';
import type { Cart } from '@/types';

type CartContextValue = {
  cart: Cart;
  setCart: React.Dispatch<React.SetStateAction<Cart>>;
  resetCart: () => void;
};

const CartContext = createContext<CartContextValue | undefined>(undefined);

const useCart = () => {
  const context = useContext(CartContext);

  if (!context) throw new Error('Make sure you are using useCart within a CartProvider');

  return context;
};

const useCartRequest = () => {
  const { cart } = useCart();
  return useMemo(() => formatCart(cart), [cart]);
};

type CartProviderProps = { children: ReactNode };

const initialCartItem = {
  restaurantPlatformId: '',
  items: [],
  restaurantAddress: '',
  restaurantName: '',
  accessInstructions: '',
  kitchenLabel: '',
  conceptLabel: '',
};

const CartProvider = ({ children }: CartProviderProps) => {
  const [cart, setCart] = useLocalStorage<Cart>('TASTER_CART', initialCartItem);

  const resetCart = useCallback(() => setCart(initialCartItem), [setCart]);

  const value = useMemo(() => ({ cart, setCart, resetCart }), [cart, setCart, resetCart]);

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export { CartProvider, useCart, useCartRequest };
