import { Stack } from '@chakra-ui/react';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useNavigate, useParams } from 'react-router-dom';

import { Category } from '@/api/gateway-click-collect/restaurants/types';
import { KioskMenuCard } from '@/components/Kiosk/KioskMenuCard';
import { useCart } from '@/contexts/cart';
import { useCategoriesDataContext } from '@/contexts/CategoriesDataProvider';
import dataLayer from '@/helpers/dataLayer.helpers';
import { MenuItemGridLayout } from '@/layouts/MenuItemGridLayout';
import { MenuCategoriesPageFooter } from '@/routes/PayOnSite/MenuCategoriesPage/MenuCategoriesPageFooter';

import { MenuCategoriesPageHeader } from './MenuCategoriesPageHeader';

export const MenuCategoriesPage = () => {
  const { restaurantPlatformId } = useParams() as { restaurantPlatformId: string };
  const navigate = useNavigate();

  const { cart } = useCart();
  const itemsQuantity = cart.items.reduce((quantityAcc, item) => quantityAcc + item.quantity, 0);
  const hasItems = !!itemsQuantity;
  const { categories, conceptInformation } = useCategoriesDataContext();

  const [kitchenLabel] = useLocalStorage('KITCHEN_LABEL', '');
  const [locationUuid] = useLocalStorage('LOCATION_UUID', '');

  const navigateToCategoryMenuItems = (category: Category) => {
    dataLayer.logOrderingKioskCategoryClick({
      brandLabel: conceptInformation.conceptName,
      categoryName: category.label,
      kitchenLabel,
    });

    window.scrollTo({ left: 0, top: 0, behavior: 'auto' });
    navigate(`/onSite/menu/${restaurantPlatformId}/categories/${category.uuid}`);
  };

  return (
    <Stack justifyContent="center" alignItems="center" pb={12} minHeight={0} gap={3}>
      <MenuCategoriesPageHeader conceptInformation={conceptInformation} locationUuid={locationUuid} />
      <Stack px={4} gap={3} justifyContent="center">
        {categories && (
          <MenuItemGridLayout>
            {categories.map((category) => {
              const { uuid, label, picture } = category;

              return (
                <KioskMenuCard
                  label={label}
                  key={uuid}
                  pictureUrl={picture}
                  onClick={() => navigateToCategoryMenuItems(category)}
                />
              );
            })}
          </MenuItemGridLayout>
        )}
      </Stack>
      {hasItems && (
        <MenuCategoriesPageFooter
          itemsQuantity={itemsQuantity}
          onCartClick={() => {
            dataLayer.clickCart({ brandLabel: conceptInformation.conceptName, kitchenLabel, orderChannel: 'onsite' });
          }}
        />
      )}
    </Stack>
  );
};
