import { t } from '@lingui/macro';
import dayjs, { Dayjs } from 'dayjs';

import { OnlineStatus, OpeningHours, RestaurantShift } from '@/api/gateway-click-collect/restaurants/types';

type FormattedShiftList = { startTime: Dayjs; endTime: Dayjs }[];

const getFormattedShiftList = (shiftList: RestaurantShift[]) =>
  shiftList
    .map(({ endTime, startTime }) => {
      if (!startTime || !endTime) return;

      const [startTimeHour, startTimeMinute] = startTime.split(':');
      const [endTimeHour, endTimeMinute] = endTime.split(':');

      const endTimeDay = endTimeHour === '00' ? dayjs().add(1, 'day') : dayjs();

      return {
        startTime: dayjs().hour(Number(startTimeHour)).minute(Number(startTimeMinute)),
        endTime: endTimeDay.hour(Number(endTimeHour)).minute(Number(endTimeMinute)),
      };
    })
    .filter((shift): shift is { startTime: Dayjs; endTime: Dayjs } => !!shift && !!shift.startTime && !!shift.endTime);

const getClosesAt = (isOpenNow: boolean, todayShiftsList: FormattedShiftList) => {
  if (!isOpenNow) return;

  const currentShift = todayShiftsList.find(({ startTime, endTime }) =>
    dayjs().isBetween(dayjs(startTime), dayjs(endTime))
  );

  if (!currentShift) return;

  return dayjs(currentShift.endTime);
};

const getRemainingTodayOpeningTimes = (todayShiftsList: FormattedShiftList) =>
  todayShiftsList.map(({ startTime }) => startTime).filter((openingTime) => openingTime.isAfter(dayjs()));

const getParsedOpensAt = (todayShiftsList: FormattedShiftList, closesAt?: Dayjs) => {
  if (closesAt) return;

  const remainingTodayOpeningTimes = getRemainingTodayOpeningTimes(todayShiftsList);

  if (!remainingTodayOpeningTimes.length) return;

  return (
    remainingTodayOpeningTimes.reduce((openingTimeA, openingTimeB) =>
      dayjs().diff(openingTimeA) < dayjs().diff(openingTimeB) ? openingTimeA : openingTimeB
    ),
    remainingTodayOpeningTimes[0]
  );
};

const getTodayShift = (todayShiftsList: RestaurantShift[]) =>
  todayShiftsList.map(({ endTime, startTime }) => ({
    shiftStart: startTime,
    shiftEnd: endTime,
  }));

const getOpeningLabel = (isOpenNow: boolean, closesAt?: Dayjs, parsedOpensAt?: Dayjs | null) => {
  if (isOpenNow && closesAt) {
    return t`Open until ${closesAt.format('LT')}`;
  } else if (parsedOpensAt) {
    return t`Opens at ${parsedOpensAt.format('LT')}`;
  }
};

const getIsOpenNow = ({
  shiftList,
  status,
}: {
  shiftList: ({ startTime: Dayjs; endTime: Dayjs } | undefined)[];
  status: OnlineStatus;
}) =>
  status !== 'close' &&
  !!shiftList.length &&
  shiftList.some((shift) => !!shift && dayjs().isBetween(shift.startTime, shift.endTime));

export const formatOpeningHours = ({
  restaurantOpeningHours,
  status,
}: {
  restaurantOpeningHours: OpeningHours;
  status: OnlineStatus;
}) => {
  const weekdayIndex = dayjs().isoWeekday() - 1;
  const todayShiftsList = restaurantOpeningHours.weekdays[weekdayIndex]?.shifts ?? [];

  const formattedTodayShiftList = getFormattedShiftList(todayShiftsList);

  const isOpenNow = getIsOpenNow({ shiftList: formattedTodayShiftList, status });

  const closesAt = getClosesAt(isOpenNow, formattedTodayShiftList);

  const parsedOpensAt = getParsedOpensAt(formattedTodayShiftList, closesAt);

  return {
    closesAt,
    isOpenNow,
    isOpenToday: !!getRemainingTodayOpeningTimes(formattedTodayShiftList).length,
    opensAt: parsedOpensAt,
    shifts: getTodayShift(todayShiftsList),
    openingLabel: getOpeningLabel(isOpenNow, closesAt, parsedOpensAt),
  };
};
