import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { sendCustomerEmail } from '@/api/api';

import ApiError from './ApiError';
import { SendCustomerEmailRequest } from './types';

export type UseSendCustomerEmailMutationOptions = UseMutationOptions<void, ApiError, SendCustomerEmailRequest>;

export const useSendCustomerEmailMutation = (options?: UseSendCustomerEmailMutationOptions) => {
  return useMutation({
    ...options,
    mutationFn: (mutationParams: SendCustomerEmailRequest) => sendCustomerEmail({ ...mutationParams }),
  });
};
