import { CartItemModifier, CartItemModifierGroup } from '@/api/gateway-click-collect/orders/types';
import { ModifierGroup } from '@/api/gateway-click-collect/restaurants/types';
import { ModifierGroupQuantityProvider } from '@/contexts/ModifierGroupQuantityContext';
import { MenuItemGridLayout } from '@/layouts/MenuItemGridLayout';

import { AddModifierGroupLayout } from './AddModifierGroupLayout';
import { ModifierCard } from './ModifierCard/ModifierCard';

export interface AddModifierGroupProps {
  modifierGroup: ModifierGroup;
  pictures: Record<string, string | undefined>;
  currencyCode: string;
  restaurantPlatformId: string;
  onSelect: (selectedModifierGroup: CartItemModifierGroup) => void;
  onBackButtonClick: () => void;
  onSkipModifier: () => void;
}

export const AddModifierGroup = ({
  modifierGroup,
  pictures,
  currencyCode,
  restaurantPlatformId,
  onSelect,
  onBackButtonClick,
  onSkipModifier,
}: AddModifierGroupProps) => {
  const handleModifierWithoutSubModifiersClick = (selectedModifier: CartItemModifier) => {
    onSelect({
      modifierGroupUuid: modifierGroup.modifierGroupUuid,
      modifiers: [selectedModifier],
    });
  };

  return (
    <ModifierGroupQuantityProvider modifierGroup={modifierGroup}>
      <AddModifierGroupLayout
        modifierGroup={modifierGroup}
        restaurantPlatformId={restaurantPlatformId}
        onBackButtonClick={onBackButtonClick}
        onConfirmClick={(modifiers = []) => {
          onSelect({ ...modifierGroup, modifiers });
        }}
        onSkip={onSkipModifier}
      >
        <MenuItemGridLayout sx={{ pb: 175 }}>
          {modifierGroup.modifiers.map((modifier) => {
            return (
              <ModifierCard
                currencyCode={currencyCode}
                modifier={modifier}
                key={modifier.menuItemUuid}
                pictureUrl={pictures[modifier.menuItemUuid]}
                pictures={pictures}
                onClick={handleModifierWithoutSubModifiersClick}
              />
            );
          })}
        </MenuItemGridLayout>
      </AddModifierGroupLayout>
    </ModifierGroupQuantityProvider>
  );
};
